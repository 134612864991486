<template>
  <div class="page-content">
    <ContentWrapper>
      <HText :variant="$vuetify.breakpoint.width <= 768 ? 'h2' : 'h1'" class="text__title">Пользовательское соглашение
      </HText>
      <p style="    font-size: 18px;
    line-height: 26px;
    margin-bottom: 50px;
">
        Настоящее пользовательское соглашение (далее по тексту Соглашение) регулирует порядок работы информационной
        платформы https://{{ domain }} (далее по тексту Сайт), находящейся в собственности Индивидуального предпринимателя «Хромов Александр Константинович» БИК 044525593, ИНН 771705344623, адрес 129515 г.Москва ул. Ак Королева д.5 кв.45, email: info@mama7d.ru (далее по тексту
        Оператор), определяет условия использования пользователями контента, материалов и сервисов сайта. Настоящее
        соглашение является публичной офертой в соответствии с законодательством Российской Федерации. Получая доступ к
        контенту, материалам, сервисам или предоставляя данные через формы на сайте, пользователь считается
        присоединившимся к настоящему соглашению. </p>
      <ol>
        <li>
          Условия использования сайта
          <p>
            1.1. Пользователь сайта принимает условия данного соглашения с момента непосредственного использования
            информационной платформы, как перед прохождением процедуры регистрации, так и после, с целью получения
            доступа к отдельным функциям сервиса. Пользователь считается принявшим условия Соглашения в полном объеме,
            без всяких оговорок и исключений.
          </p>
          <p>
            1.2.Процедура регистрации.
          </p>
          <p>1.2.1.Пользователь, достигший возраста 14 лет, вправе самостоятельно производить регистрацию учетной записи
            в пределах правоспособности, установленной действующим законодательством Российской Федерации. Не
            допускается использование сервиса сайта лицам младше 14 лет.</p>
          <p>1.2.2.Для регистрации Пользователь обязуется предоставить достоверную и полную информацию о себе по
            вопросам, предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии. Если
            Пользователь предоставляет неверную информацию или у Оператора есть основания полагать, что предоставленная
            Пользователем информация неполная или недостоверна, Оператор имеет право по своему усмотрению заблокировать
            либо удалить учетную запись Пользователя и отказать Пользователю в использовании своего сервиса (либо их
            отдельных функций).</p>
          <p>1.2.3. Пользователь осознает и соглашается с тем, что Оператор вправе проводить опубликование и дальнейшее
            распространение любых данных Пользователя в соответствии с Политикой конфиденциальности Индивидуального предпринимателя «Хромов Александр Константинович», Версия 1.0 от 01.04.2021г.</p>
          <p>1.2.4. При регистрации Пользователь самостоятельно выбирает себе логин (уникальное символьное имя учетной
            записи Пользователя) и пароль для доступа к учетной записи. Оператор вправе запретить использование
            определенных логинов, а также устанавливать требования к логину и паролю (длина, допустимые символы и т.д.)
            для повышения уровня безопасности хранения персональных данных.</p>
          <p>1.2.5. Пользователю может быть предоставлена возможность прохождения упрощенной процедуры регистрации
            учетной записи путем указания номера мобильного телефона, фамилии и имени Пользователя. Последующая
            авторизация на сайте осуществляется Пользователем путем указания введенного при регистрации номера
            мобильного телефона и направленного на него кода-подтверждения.
            Использование отдельных функций сервиса с применением учетной записи, созданной в упрощенном порядке, может
            быть недоступно.
          </p>
          <p>1.3. Подтверждение учетной записи </p>
          <p>1.3.1. Оператор оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных,
            указанных при регистрации в учетной записи Пользователя на сайте Оператора (в том числе банковские данные) и
            содержащих информацию о Пользователе, а также иную информацию, связанную с использованием предоставляемого
            сервиса, доступную ему после авторизации с использованием логина и пароля.</p>
          <p>1.3.2.Для целей проверки заявленных Пользователем данных Оператор вправе воспользоваться предоставленными
            копиями документов, прикрепленных к анкете, а так же запросить дополнительно информацию у Пользователя (в
            частности - документы, удостоверяющие личность), непредставление которых, по усмотрению Оператора, может
            быть приравнено к предоставлению недостоверной информации и повлечь последствия, предусмотренные п. 1.2.2
            Соглашения. В случае если данные Пользователя, указанные в предоставленных им документах, не соответствуют
            данным, указанным при регистрации, а также в случае, когда данные, указанные при регистрации, не позволяют
            идентифицировать Пользователя, Оператор вправе отказать Пользователю в доступе к учетной записи и
            использовании сервиса Оператора.</p>
          <p>1.4.Порядок обработки и защиты персональных данных Пользователя</p>
          <p>1.4.1.Персональные данные Пользователя, определенные Политикой конфиденциальности и содержащиеся в учетной
            записи Пользователя, хранятся, обрабатываются и распространяются Оператором в соответствии с условиями
            Политики конфиденциальности Индивидуального предпринимателя «Хромов Александр Константинович», Версия 1.0 от
            01.04.2021г. </p>
          <p>1.4.2.Сайт https://{{ domain }} и программное обеспечение Оператора размещены в облаке на серверах
            принадлежащих компании ООО «Регистратор доменных имён РЕГ.РУ» ИНН 7733568767, ОГРН 1067746613494,
            зарегистрированная по адресу: 125252, г.Москва, пр.Березовой Рощи, д.12, эт.2,к.4 . Все данные защищаются и
            обрабатываются в соответствии с Политикой обработки персональных данных ООО «Регистратор доменных имен
            РЕГ.РУ» . </p>
          <p>1.5. Требования безопасности использования учетной записи</p>
          <p>1.5.1.Пользователь самостоятельно несет ответственность за безопасность (в том числе устойчивость к
            угадыванию) выбранных им средств для доступа к учетной записи, а также самостоятельно обеспечивает их
            конфиденциальность. Пользователь самостоятельно несет ответственность за все действия (а также их
            последствия) в рамках или с использованием сервиса Оператора под учетной записью Пользователя, включая
            случаи добровольной передачи Пользователем данных для доступа к учетной записи Пользователя третьим лицам на
            любых условиях (в том числе по договорам или соглашениям). При этом все действия в рамках или с
            использованием сервиса Оператора под учетной записью Пользователя считаются произведенными самим
            Пользователем, за исключением случаев, когда Пользователь, в порядке, предусмотренном п. 1.5.2 Соглашения,
            уведомил Оператора о несанкционированном доступе к сервису Оператора с использованием учетной записи
            Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к
            учетной записи (номера мобильного телефона, пароля или средств двухфакторной аутентификации).</p>
          <p>1.5.2. Пользователь обязан немедленно уведомить Оператора о любом случае несанкционированного (не
            разрешенного Пользователем) доступа к сервису с использованием учетной записи Пользователя и/или о любом
            нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к учетной записи. В целях
            безопасности Пользователь обязан самостоятельно осуществлять безопасное завершение работы под своей учетной
            записью по окончании каждой сессии работы с сервисом Оператора. Оператор не отвечает за возможную потерю или
            порчу данных, а также другие последствия любого характера, которые могут произойти из-за нарушения
            Пользователем положений этой части Соглашения.</p>
          <p>1.5.3. Оператор вправе заблокировать или удалить учетную запись Пользователя, а также запретить доступ с
            использованием какой-либо учетной записи к определенным функциям сервиса, и удалить любой контент без
            объяснения причин, в том числе в случае нарушения Пользователем условий Соглашения или норм установленных
            законодательством Российской Федерации.</p>
        </li>
        <li>
          Обязательства сторон
          <p>2.1. Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие
            российское законодательство или нормы международного права, в том числе в сфере авторского права,
            интеллектуальной собственности, а также любых действий, которые приводят или могут привести к нарушению
            нормальной работы сайта и сервисов сайта.</p>
          <p>2.2. Использование материалов сайта без согласия правообладателя не допускается. Для правомерного
            использования материалов сайта необходимо согласие Оператора сайта или правообладателя материалов.</p>
          <p>2.3. При использовании материалов сайта, включая охраняемые авторские произведения, ссылка на сайт
            обязательна.</p>
          <p>2.4. При наличии технической возможности Пользователь может оставлять комментарии и иные записи на сайте.
            Пользователь соглашается, что комментарии или иные записи не нарушают законодательства России, не являются
            экстремистскими, не нарушают общепринятых норм морали и нравственности.</p>
          <p>2.5. Оператор не несет ответственности за посещение и использование Пользователем внешних ресурсов, ссылки
            на которые могут содержаться на сайте.</p>
          <p>2.6. Пользователь согласен с тем, что Оператор не несет ответственности и не имеет прямых или косвенных
            обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками,
            связанными с любым содержанием сайта, товарами или услугами, доступными на сайте или полученными через
            внешние сайты или ресурсы, либо иные контакты Пользователя, в которые он вступил, используя размещенную на
            сайте информацию или ссылки на внешние ресурсы.</p>
          <p>2.7. Пользователь принимает положение о том, что все материалы и сервисы сайта или любая их часть могут
            сопровождаться рекламой. Пользователь согласен с тем, что Оператор не несет какой-либо ответственности и не
            имеет каких-либо обязательств в связи с такой рекламой.</p>
          <p>2.8. Пользователь, используя ресурсы сайта, подтверждает, что ознакомлен со всеми пунктами настоящего
            Соглашения и безусловно принимает их.</p>

        </li>
        <li>
          Изменения соглашения
          <p>3.1. Оператор вправе в любое время в одностороннем порядке изменять контент, содержимое, любые материалы и
            данные сайта, функции сервиса, а также изменять условия настоящего Соглашения и порядок предоставления
            доступа к сайту. Такие изменения вступают в силу с момента размещения новой версии Соглашения на сайте или
            обновления сайта. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к
            сайту, прекратить использование контента, материалов и сервисов сайта.</p>
        </li>
        <li>
          Политика безопасности и порядок возврата
          <p>4.1. При оплате заказа банковской картой, обработка платежа (включая ввод номера карты) происходит на
            защищенной странице процессинговой системы, которая прошла международную сертификацию. Конфиденциальные
            данные (реквизиты карты, регистрационные данные и др.) не поступают в интернет-магазин, их обработка
            полностью защищена и никто, в том числе наш интернет-магазин, не может получить персональные и банковские
            данные клиента. При работе с карточными данными применяется стандарт защиты информации, разработанный
            международными платёжными системами Visa и MasterCard — Payment Card Industry Data Security Standard (PCI
            DSS), что обеспечивает безопасную обработку реквизитов Банковской карты Держателя. Применяемая технология
            передачи данных гарантирует безопасность по сделкам с Банковскими картами путем использования протоколов
            Secure Sockets Layer (SSL), Verified by Visa, Secure Code, и закрытых банковских сетей, имеющих высшую
            степень защиты</p>
          <p>4.2. Возврат денежных средств производится исключительно на ту же банковскую карту, с которой была
            произведена оплата.</p>
        </li>
        <li>
          Заключительные положения
          <p>5.1. В отношении услуг, оказываемых в рамках настоящего Соглашения на безвозмездной основе, нормы о защите
            прав потребителей, предусмотренные законодательством Российской Федерации, не могут быть применимыми к
            отношениям между Пользователем и Оператором.</p>
          <p>5.2.Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации.
            Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством
            Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением,
            разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам
            российского права.</p>
        </li>
      </ol>
    </ContentWrapper>
  </div>
</template>

<script>
export default {
  name: "Agreement",
  data() {
      return {
        domain: this.$server.domain
      }
  },
  mounted() {
    document.title = 'Пользовательское соглашение'
  }
}
</script>

<style scoped>
.text__title {
  margin: 20px 0;
  text-align: center;
}
</style>
