<template>
  <router-link class="clipcard--wrapper" :to="`/videos/${video.id}`">
    <div class="clipcard--top" :style="`background: url('${video.preview}'); background-size: cover`">
<!--      <img :src="video.preview" alt='...'/>-->
      <div class="tags">
        <div class="tag"
             v-for="(tag, i) in video.tags"
             :key="i"
        ># {{ tag.name }}
        </div>
      </div>
    </div>
    <div style="background: #6F8DA8;height: 4px;min-width: 10px" :style="`width: ${parseInt(video.seconds/video.duration*100)}%`"></div>
    <div class="clipcard--description">
      <div class="clipcard--description-header">
        <HText variant="h3">{{ video.title }}</HText>
        <div v-if="video.price > 0" class="clipcard--price">
          <CText v-if="video.hasAccess*1 === 0" :text="`${$root.printCost(video.price)}`"></CText>
          <div v-else class="d-flex">
            <v-icon color="green" size="12" class="mr-1">mdi-check</v-icon>
            <span style="font-size: 12px">Оплачено</span>
          </div>
        </div>
        <div v-else-if="!(video.course*1)" class="clipcard--price">
          <CText text="Бесплатно"></CText>
        </div>
      </div>

      <div class="clipcard--description-meta">
<!--        <CText :text="$moment.utc(video.seconds * 1000).format('HH:mm:ss')"></CText>/-->
<!--        <CText :text="$moment.utc(video.duration * 1000).format('HH:mm:ss')"></CText>-->
        <!--                <span style="margin: 0px 3px">•</span>-->
        <!--                <CText :text="`${video.views} просмотра`"></CText>-->
      </div>
    </div>
  </router-link>
</template>

<script>
import HText from './HText'
import CText from './CText'

export default {
  components: {
    HText,
    CText
  },
  props: ['video']
}
</script>

<style>
.clipcard--wrapper {
  width: 328px;
  height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: #FFFFFF;
  overflow: hidden;
  margin-right: 8px;
}

.clipcard--top {
  height: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clipcard--top img {
  width: 100%;
}

.clipcard--description {
  height: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.clipcard--description-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.clipcard--description-meta {
  display: flex;
  width: 100%
}

.clipcard--price {
  height: 26px;
  min-width: 86px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: inline;
  border-radius: 4px;
  padding: 2px 8px;
  text-align: center;
}

.clipcard--wrapper .tags {
  position: absolute;
  z-index: 10;
  left: 10px;
  bottom: 10px;
}

.clipcard--wrapper .tag {
  height: 26px;
  display: inline;
  border-radius: 4px;
  padding: 2px 10px;
  background: white;

}

@media screen and (max-width: 768px) {
  .clipcard--wrapper {
    width: 291px;
    height: 266px;
  }
}

@media screen and (max-width: 600px) {
  .clipcard--wrapper {
    width: 100%;
    height: 266px;
    margin-bottom: 10px;
  }
}
</style>
