<template>
  <div class="page-content pb-0">
    <ContentWrapper background="white" class="pt-10">
      <h1 size="6" style="font-size: 28pt"><b>Онлайн курс подготовки к родам от Дарьи Хромовой</b></h1>
      <p>Онлайн-курс в прямом эфире дает возможность  общения с конкретной аудиторией, отвечать её запросу, растворять индивидуальные страхи и строить СВОИ роды из надежных «кирпичиков» знаний, полученных от мамы 7-х детей и акушерки с 14-летним стажем!</p>
      <p class="font-weight-bold my-2">Курс состоит из 4 лекций по 2 часа.</p><p>
        Курс проводится в течение четырёх выходных дней, каждая лекция длится два часа с получасовым перерывом примерно посередине. Запись курса не производится, дальнейшего доступа к материалам обучения нет, в случае пропуска части занятий пройти их заново можно только со следующим потоком. Если у вас нет гарантированной возможности выделить соответствующее время, рекомендую воспользоваться записями курсов, позволяющий изучать материал и повторять пройденное в любое удобное время в течение 6 месяцев с момента приобретения.</p>


      <p class="mt-4" v-for="(lecture, index) in lectures" :key="index"><b>{{index+1}} лекция</b> – <span v-html="lecture"></span></p>

      <p class="py-4">Обладая опытом ведения этого курса более 15 лет, уверенна, что Вы проведете время занятий с огромной пользой на благое  рождение своих деток, с закладкой их физического и психологического здоровья на ВСЮ жизнь!!!</p>
    </ContentWrapper>
  </div>
</template>

<script>
export default {
  name: "OnlineCourse",
  components: {},
  data() {
    return {
      lectures: [
          'Знакомство и опрос участников курса об их ожиданиях и страхах предстоящих родов, чего хотелось бы избежать и к чему быть готовыми. Что такое  в Вашем понимании «хорошие роды»?<br>Я расскажу Вам о волшебном гормоне любви – главном действующем «лице» любых родов! Что на него действует положительно, а что снижает его содержание в трепетном организме матери. Предвестники родов: физиологические и психологические.',
          'Переходим непосредственно к родам: как чётко понять, что роды начались? Различные фазы родов и методы обезболивания схваток  на каждом этапе – позы, дыхание, поведение, трансовое состояние.',
          'Потуги:  ощущения, правильное поведение, дыхание. Рождение в различных позах, как родить малыша в воде, всегда ли можно? Как избежать разрывов промежности? «Золотой час» после родов. Собираем полную картину (нанизываем бусины) хороших родов и потенциируем эту картину на себя!',
          'Если что-то пошло не так… Разбираем всевозможные вмешательства в родовой процесс: родовозбуждение,  синтетический окситоцин, эпидуральная анастезия, амниотомия, разрез промежности(эпизиотомия), операция кесарево сечение. Когда и зачем действительно нужно, а  когда необходимо строго писать отказ от вмешательства. Формирмирование и подбор «команды» для благополучных родов – всё необходимое. Список необходимых вещей в роддом.',
      ]
    }
  },
  methods: {
  },

}
</script>