import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';
import swiper from "@/plugins/swiper";
import GlobalMixin from "@/GlobalMixin";
import moment from "moment";

import {Server} from "@/server";
Vue.use(moment);
Vue.prototype.$moment = moment;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$server = new Server(Vue.prototype.$eventBus, store);

import ContentWrapper from "@/components/reusable/ContentWrapper";
Vue.component('ContentWrapper', ContentWrapper)
import Back from "@/components/reusable/Back";
Vue.component('Back', Back)
import HText from "@/components/reusable/HText";
Vue.component('HText', HText)
import CText from "@/components/reusable/CText";
Vue.component('CText', CText)
import CustomButton from "@/components/reusable/CustomButton";
Vue.component('CustomButton', CustomButton)
import Prompt from "@/modals/Prompt";
Vue.component('Prompt', Prompt)

moment.locale('ru')
moment.relativeTimeThreshold('ss', 0);
moment.relativeTimeThreshold('s', 60);

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

Vue.config.productionTip = false
Vue.mixin(GlobalMixin);
Vue.mixin({
  beforeRouteUpdate(to, from, next) {
    next();
    store.state.prevPage = from.path === '/' ? '' : from.path;
    if (from.path !== to.path) this.$eventBus.$emit('pageChanged');
  }
})
Vue.use(swiper)
new Vue({
  router,
  store,
  vuetify,
  methods: {
    printCost(sum, withPlus = false) {
      sum = parseInt(sum);
      let minus = sum < 0;
      sum = Math.abs(sum);
      if (!sum) return 0 + ' ' + this.$store.state.rub;
      sum += '';
      let l = sum.length;
      let part1 = sum.substr(0, l % 3);
      let part2 = sum.substr(l % 3);
      let parts = [];
      if (part1) parts.push(part1);
      part2 = part2.match(/.{1,3}/g);
      if (part2) parts.push(...part2);
      return (minus ? '-' : (withPlus ? '+' : '')) + parts.join(' ') + ' ' +  this.$store.state.rub;
    },
  },
  render: h => h(App)
}).$mount('#app')
