<template>
  <div class="course-program">
    <CText variant="caps" class="course-program__title" :text="'Программа курса'"></CText>
    <ol v-if="courseLoaded || videoLoaded" class="course-program-container">
      <router-link v-for="(video, index) in course.videos" :key="index" :to="'/videos/'+video.id">
        <li class="course course--available"
            :class="{'course--viewed': video.seen, 'course--current': current && video.id==current}"
        >
          {{ video.title }}
        </li>
        <div v-if="video.seconds*1" class="course--video__duration" :style="`width: calc(${parseInt(video.seconds/video.duration*100)}% - 4px)`"></div>
      </router-link>
    </ol>
    <ol v-else class="course-program-container">
      <v-skeleton-loader class="course" type="button" width="325" height="57" v-for="i in 10" :key="i"/>
    </ol>
  </div>
</template>

<script>
export default {
  props: ['course', 'current', 'courseLoaded', 'videoLoaded'],
}
</script>

<style scoped lang="scss">
.course-program {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 30px;
    font-size: 11px;
    line-height: 140%;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    text-align: center;
  }

  &-container {
    display: flex;
    align-self: flex-start;
    flex-wrap: wrap;
    margin-bottom: 30px;
    list-style-position: inside;
    padding: 0;

    a {
      margin-right: 8px;
      border: none;
      flex-grow: 1;
      min-width: 244px;
      width: 244px;
    }

    .course {
      margin-bottom: 8px;
      padding: 14px 20px 25px;
      border-radius: 10px;

      &:last-child {
        margin-right: 0;
      }

      &--available {
        background: #ffffff;
        cursor: pointer;
        height: calc(100% - 10px);
      }

      &--viewed {
        background: #E0D4C5;
        border: none;
      }

      &--current {
        outline: 2px solid #222222;
        border: none;
      }

      &--video__duration {
        height: 4px;
        background: #6F8DA8;
        border-radius: 0 0 0 10px;
        margin-top: -12px;
        margin-bottom: 12px;
        margin-left: 2px;
        min-width: 10px;
      }

      &--unavailable {
        background-color: transparent;
        border: 1px solid rgba(8, 5, 12, 0.3);
      }
    }
  }
}
</style>
