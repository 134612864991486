<template>
  <v-form ref="auth_form" class="auth-form" onsubmit="return false;">
    <span class="change-email" v-if="step === 'code'"
          @click="step = 'email'">Изменить e-mail</span>
    <div class="auth-form-wrapper">
      <v-text-field
          autofocus
          v-if="step === 'email'"
          autocomplete="off"
          class="input email-input"
          label="E-mail"
          outlined
          v-model="user.email"
          hide-details="auto"
          validate-on-blur
          :error-messages="errorMsg_email"
          @keydown.enter="getCode"
      />
      <v-text-field
          type="tel"
          inputmode="tel"
          v-if="step === 'code'"
          autocomplete="off"
          placeholder="_ _ _ _"
          class="input code-input"
          label="Проверочный код"
          outlined
          v-model="user.code"
          ref="code_input"
          :error="error_code"
          :error-messages="errorMsg_code"
          @keydown.enter="login"
      />
      <CustomButton class="btn-auth" variant="black" text="Получить код"
                    :loading="get_code_loading"
                    @click.native="getCode"
                    v-if="step === 'email'"
      />
      <CustomButton class="btn-auth" variant="black" text="Войти"
                    :loading="login_loading"
                    @click.native="login"
                    v-if="step === 'code'"
      />
    </div>
  </v-form>
</template>

<script>
export default {
  name: "LoginFields",
  data() {
    return {
      params: {},
      user: {},
      step: 'email',
      get_code_loading: false,
      login_loading: false,
      error_code: false,
      errorMsg_code: [],
      errorMsg_email: [],
    }
  },
  emits: ['on-logged-in'],
  methods: {
    onOpenModal() {
      this.user = {};
      this.step = 'email';
      this.params = {};
    },
    getCode() {
      this.errorMsg_email = [];
      if (this.$refs.auth_form.validate()) {
        this.get_code_loading = true
        this.$server.request('auth/login', {email: this.user.email}, (data) => {
          this.user.secret_key = data.response
          this.step = 'code'
          this.get_code_loading = false
          setTimeout(() => {
            this.$refs.code_input.focus()
          })
        }, (data) => {
          this.errorMsg_email = [data.error]
          this.get_code_loading = false
        })
      }
    },
    login() {
      this.errorMsg_code = [];
      this.login_loading = true;

      this.$server.request('auth/checkCode', {secret_key: this.user.secret_key, code: this.user.code}, (data) => {
        this.login_loading = false;
        this.$server.setToken(data.token)
        this.$store.commit('set', ['user', data.response])
        if (this.params.type === 'from_buy') {
          this.$eventBus.$emit('open_buy_modal', this.params.params)
        }
        if (this.params.type === 'from_video')  this.$eventBus.$emit('get_video')

        this.$emit('on-logged-in');

      }, (data) => {
        this.login_loading = false
        this.error_code = true;
        this.errorMsg_code = [data.error]
      })
    }
  },
  watch: {
    'user.code': function (newVal) {
      if (newVal) newVal.length === 4 ? this.login() : "";
    }
  }
}
</script>

<style lang="scss">
.auth-form {
  .change-email {
    display: block;
    opacity: 0.5;
    border-bottom: 1px solid #08050C;
    font-size: 16px;
    line-height: 110%;
    cursor: pointer;
    margin-bottom: 20px;
    width: fit-content;
  }
  .auth-form-wrapper {
    display: flex;
    height: fit-content;

    .v-input.input {
      margin-right: 8px;
      width: 285px;
      min-width: 285px;

      .v-input__slot {
        margin-bottom: 0;
        background: #ffffff;
        height: 100%;
      }

      fieldset {
        border: none;
      }

      input::placeholder {
        color: #B5B0AC;
      }

      .v-text-field__details {
        margin-top: 8px;
        padding: 0;
      }
    }

    .btn-auth {
      width: 285px;
      min-width: 285px;
      height: 56px;
    }
  }
}

@media screen and (max-width: 768px) {
  .auth-form-wrapper {
    flex-direction: column;

    .input {
      margin-bottom: 8px;
      margin-right: 0;
    }
  }
}


@media screen and (max-width: 600px) {
  .auth-form-wrapper {
    .input, .btn-auth {
      width: 100%;
    }

    .v-btn {
      height: 45px;
    }
  }
}
</style>