<template>
  <div class="page-content">
    <ContentWrapper background="#eaf4fc">
      <div class="videos--header">
        <div class="page-title">
          <HText variant="h1 page-title__main">Библиотека лекций</HText>
          <span class="page-title__subtitle">{{ videos.length ? videos.length : '' }}</span>
        </div>
        <div class="page-header-row">
          <div class="page-header-row__left">
            <!--            <div class="page-title__subtitle">1 видео = 1 полезный совет</div>-->
          </div>
          <!--          <div class="page-header-row__right">-->
          <!--            <div class="searchbar">-->
          <!--              <img src="/img/search.svg" class="searchbar__icon" alt="search icon">-->
          <!--              <input type="text" placeholder="Поиск" class="searchbar__input">-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <TagList
            v-if="tags.length > 1"
            :tags="tags" class="page-header--tag-list" @change="onChangeTags"/>
        <div v-else class="tags-skeleton-container">
          <v-skeleton-loader class="skeleton-tag" type="button" height="22" width="100" v-for="i in 5" :key="i"/>
        </div>
      </div>
    </ContentWrapper>
    <ContentWrapper class="video-sections-container">
      <VideosSection
          v-for="(tags, name) in sections"
          :key="name"
          :items="filteredVideos.filter(video=>video.tags.some(tag=>tags.includes(+tag.id)))"
          :title="name"
          :enabled-desktop="true"
          :show-arrow="true"/>
      <VideosSection
          :items="filteredVideos.filter(video=>!video.tags.length)"
          title="Остальные"
          :enabled-desktop="true"
          :show-arrow="true"/>
    </ContentWrapper>
  </div>
</template>

<script>
import TagList from "@/components/reusable/TagList";
import VideosSection from "@/components/videos-page/VideosSection";

export default {
  name: "Videos",
  components: {VideosSection, TagList},
  data() {
    return {
      videos: [],
      tags: [{id: '0', name: 'Бесплатно'}],
      videoBySection: [{name: 'Остальное', videos: []}],
      sections: {},
      filteredVideos: [],
      selectedFree: false,
    }
  },
  methods: {
    onChangeTags(selectedTags) {
      if (selectedTags && !Array.isArray(selectedTags)) selectedTags = [selectedTags];
      if (selectedTags && selectedTags.length) {
        let preFilteredVideos = []
        if (selectedTags.includes('0')) {
          this.selectedFree = true
          let indexOfFree = selectedTags.indexOf('0');
          preFilteredVideos = this.videos.filter(video => video.price * 1 === 0)
          selectedTags.splice(indexOfFree, 1)
          if (selectedTags.length) {
            preFilteredVideos = preFilteredVideos.filter(video => video.tags.some(tag => selectedTags.includes(tag.id)))
          }
          selectedTags.splice(indexOfFree, 0, '0')
        } else {
          this.selectedFree = false
          preFilteredVideos = this.videos.filter(video => video.tags.some(tag => selectedTags.includes(tag.id)))
        }
        this.filteredVideos = [...preFilteredVideos]
      } else this.filteredVideos = [...this.videos]
    },
    getVideos() {
      this.$server.request('video/get', {}, (data) => {
        this.videos = data.response
        this.filteredVideos = data.response
        this.tags = this.tags.concat(data.tags);
        this.sections = data.sections;
        this.onChangeTags(this.$route.query.tags)
      })
    },
    selectFree() {
      this.selectedFree = !this.selectedFree
      if (this.selectedFree) this.filteredVideos = this.filteredVideos.filter(video => video.price * 1 === 0);
    },
    created() {

    }
  },
  mounted() {
    this.getVideos()
    document.title = 'Mama7d - Библиотека лекций'
  },
}
</script>

<style lang="scss">
.videos--header {
  padding-top: 60px;
  padding-bottom: 60px;
  .tag-list__item-free {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 2px 10px;
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    white-space: nowrap;
    cursor: pointer;
    width: fit-content;

    &--selected {
      background: #08050C;
      color: #F2F2F2;
    }
  }

  .searchbar__input {
    width: 47px;
  }

  .page-header-row {
    align-items: flex-end;
  }
}

.video-sections-container {
  padding-top: 60px;
}
</style>
