<template>
  <div>
    <PreviewSection/>
    <AboutSection/>
    <CardSection/>
    <ConsultSection/>
    <!--        <ClubSection />-->
    <ReviewsSection class="reviews-outer"/>
  </div>
</template>

<script>
import PreviewSection from '../components/mainpage/PreviewSection'
import AboutSection from '../components/mainpage/AboutSection'
import CardSection from '../components/mainpage/CardSection'
import ConsultSection from '../components/mainpage/ConsultSection'
// import ClubSection from '../components/mainpage/ClubSection'
import ReviewsSection from '../components/mainpage/ReviewsSection'

export default {
  components: {
    PreviewSection,
    AboutSection,
    CardSection,
    ConsultSection,
    // ClubSection,
    ReviewsSection,
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
    document.title = 'Mama7d - Всё от зачатия до университета!'
  },
  created() {

  }
}
</script>
<style scoped>

</style>
