<template>
  <ContentWrapper background="var(--bg-color-blue)">
    <div class="fourth-section-container">
      <div class="description">
        <HText class="description--header">Персональная консультация</HText>
        <HText variant="h3" class="description--text">
          Некоторые вопросы лучше обсудить лично. Вы можете записаться на персональную консультацию у Дарьи.
        </HText>
      </div>
      <div class="form">
        <CustomButton text="Записаться" variant="black" class="form-btn" @click.native="openConsultModal"/>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import Input from '../reusable/Input'

export default {
  components: {
    Input,
  },
  data() {
    return {
    }
  },
  methods: {
    openConsultModal() {
      this.$eventBus.$emit('open-consult-modal');
    },
  },
};
</script>

<style lang="scss">
.fourth-section-container {
  padding-top: 100px;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .description {
    width: 390px;
    min-width: 390px;
    display: flex;
    flex-direction: column;

    &--header {
      margin-bottom: 60px;
    }
  }

  .form {
    width: 496px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .v-btn {
      min-width: 0;
      width: 150px;
    }
  }
}

@media screen and (max-width: 768px) {
  .fourth-section-container {
    flex-direction: column;

    .description {
      width: 100%;
      min-width: 100%;
      margin-bottom: 40px;

      &--header {
        margin-bottom: 50px;
      }
    }

    .form {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .fourth-section-container {
    .description {
      margin-bottom: 30px;

      &--header {
        margin-bottom: 40px;
      }
    }
  }
}
</style>
