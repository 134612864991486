export default {
  methods: {
    setOutsideSlides(swiper) {
      if (!swiper) return;

      const slides = swiper.slides
      slides.forEach((slide) => {
        if(slide.classList.contains('swiper-slide-active')) {
          slide.classList.remove('swiper-slide--outside');
          return;
        }
        if (slide.getBoundingClientRect().left + slide.offsetWidth > this.$el.offsetWidth) {
          slide.classList.add('swiper-slide--outside');
        } else {
          slide.classList.remove('swiper-slide--outside');
        }
      })
    },
    declOfNum(number, titles) {
      if (typeof titles === 'string') {
        titles = titles.replace(', ', ',')
          .split(',');
      }

      const cases = [2, 0, 1, 1, 1, 2];

      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },
  },
  computed: {
    getDisplayedStyles() {
      const breakpointNames = {
        xs: 'mobile',
        sm: 'tablet',
      }
      const currentBreakpoint = this.$vuetify.breakpoint.name;
      return breakpointNames[currentBreakpoint] || 'desktop';
    },
  },
}
