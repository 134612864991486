<template>
  <Modal classes="modal-wrapper menu-wrapper" :showed-close="false"
         ref="modal" @on-outside-click="close" @on-icon-close-click="close"
  >
    <div class="header--actions">
      <router-link class="header--link" @click.native="close" to="/videos">
        <HText variant="h2">Видео-лекции</HText>
      </router-link>
      <router-link class="header--link" @click.native="close" to="/courses">
        <HText variant="h2">Курсы</HText>
      </router-link>
      <a href="https://t.me/mkh_07" target="_blank" class="header--link">
        <HText variant="h2">Поддержка</HText>
      </a>
<!--      <router-link class="header&#45;&#45;link" @click.native="close" to="/courses/18">-->
<!--        <HText variant="h2">Онлайн курс</HText>-->
<!--      </router-link>-->
<!--      <a class="header&#45;&#45;link" @click="close; goTo('clubs')">-->
<!--        <HText variant="h2">Клубы</HText>-->
<!--      </a>-->
<!--      <a class="header&#45;&#45;link" @click="close; goTo('blog')">-->
<!--        <HText variant="h2">Блог</HText>-->
<!--      </a>-->
      <CustomButton @click.native="close; openConsultModal(); " text="Консультация" variant="filled" style="width: 200px; height: 45px; margin-bottom: 10px;"/>
      <CustomButton v-if="$store.state.user.id" @click.native="close" to="/account/continue" text="Мой профиль" variant="black" style="padding: 3px 12px; margin-bottom: 10px"/>
      <v-btn
          depressed
          v-if="$store.state.user.id"
          @click="close; logout()"
          class="btn-menu-exit"
      >Выйти <v-icon>mdi-logout</v-icon>
      </v-btn>
      <CustomButton v-else @click.native="close; openAuthModal()"  text="Войти" variant="black" style="padding: 3px 12px"/>

    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/reusable/Modal";

export default {
  name: "Menu",
  components: {
    Modal
  },
  data() {
    return {
    }
  },
  methods: {
    openConsultModal() {
      this.$eventBus.$emit('open-consult-modal');
    },
    openAuthModal() {
      this.$eventBus.$emit('open_auth_modal')
    },
    logout() {
      this.$server.logout();
      this.$store.commit('set', ['user', {}])
    },
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  },
  mounted() {
    // this.$router.push({query: {auth: 'auth'}}).catch(()=>{})
    if (this.$route.query.modal==='consult') this.openConsultModal();
  },
}
</script>

<style scoped lang="scss">
.menu-wrapper {
  .header--actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .header--link {
      text-decoration: none;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 50px;
      }
    }
    .v-btn {
      height: 45px;
      width: 100%;
    }
    .btn-menu-exit {
      border-radius: 6px;
      padding: 10px;
      text-align: center;
      text-transform: capitalize;
      border: 1px solid rgba(34, 34, 34, 0.2);
      background: none !important;
      transition: 0.2s;
      color: #222222;
    }
  }
}
</style>
