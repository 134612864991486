<template>
  <div class="page-content">
    <ContentWrapper background="var(--main-bg-color)">
      <HText :variant="$vuetify.breakpoint.width <= 768 ? 'h2' : 'h1'" class="text__title">Политика в отношении обработки персональных данных</HText>
      <ol>
        <li>
          Общие положения
          <p>
            1.1. Настоящая Политика конфиденциальности Индивидуальный предприниматель «Хромов Александр Константинович» в
            отношении
            обработки персональных данных (далее по тексту - Политика) разработана во исполнение требований:
            <br>- Федерального закона Российской Федерации от 27.07.2006 N 152-ФЗ «О персональных данных» (далее по
            тексту -
            Закон о персональных данных);
            <br>- Федерального закона от 27.06.2006 № 149-ФЗ «Об информации, информационных технологиях и о защите
            информации»;
            <br>-Постановления Правительства Российской Федерации от 15.09.2008 №687 «Об утверждении Положения об
            особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»;
            <br>-Постановление Правительства Российской Федерации от 01.11.2012 №1119 «Об утверждении требований к
            защите
            персональных данных при их обработке в информационных системах персональных данных».
            <br>Целью настоящей Политики является формирование и проведение единой политики в области обеспечения
            безопасности персональных данных.
            <br>1.2. Действие Политики распространяется на все процессы Индивидуального предпринимателя «Хромов Александр Константинович» (далее по тексту - Оператор), связанные с обработкой персональных данных.
            <br>Внутренние документы Оператора, затрагивающие вопросы, рассматриваемые в настоящей Политике, должны
            разрабатываться с учетом положений настоящей Политики и не противоречить им.
            <br>1.3. Во исполнение требований ч. 2 ст. 18.1 Закона о персональных данных настоящая Политика публикуется
            в
            свободном доступе в информационно-телекоммуникационной сети Интернет на сайте Оператора
            https://{{ domain }}/privacy. На сайте Оператора располагается программное обеспечение представляющее собой
            информационную систему персональных данных (далее по тексту – Информационная система персональных данных).
            <br>1.4. Основные понятия, используемые в Политике:
            <br>персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому
            физическому лицу (субъекту персональных данных);
            <br>персональные данные, разрешенные субъектом персональных данных для распространения - персональные
            данные,
            доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на
            обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке,
            предусмотренном настоящим Федеральным законом;
            <br>оператор персональных данных (оператор) - государственный орган, муниципальный орган, юридическое или
            физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных,
            подлежащих обработке, действия (операции), совершаемые с персональными данными;
            <br>обработка персональных данных - любое действие (операция) или совокупность действий (операций) с
            персональными данными, совершаемых с использованием средств автоматизации или без их использования.
            Обработка
            персональных данных включает в себя в том числе: сбор; запись; систематизацию; накопление; хранение;
            уточнение
            (обновление, изменение); извлечение; использование; передачу (распространение, предоставление, доступ);
            обезличивание; блокирование; удаление; уничтожение;
            <br>автоматизированная обработка персональных данных - обработка персональных данных с помощью средств
            вычислительной техники;
            <br>распространение персональных данных - действия, направленные на раскрытие персональных данных
            неопределенному кругу лиц;
            <br>предоставление персональных данных - действия, направленные на раскрытие персональных данных
            определенному
            лицу или определенному кругу лиц;
            <br>блокирование персональных данных - временное прекращение обработки персональных данных (за исключением
            случаев, если обработка необходима для уточнения персональных данных);
            <br>уничтожение персональных данных - действия, в результате которых становится невозможным восстановить
            содержание персональных данных в информационной системе персональных данных и (или) в результате которых
            уничтожаются материальные носители персональных данных;
            <br>обезличивание персональных данных - действия, в результате которых становится невозможным без
            использования
            дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных
            данных;
            <br>информационная система персональных данных - совокупность содержащихся в базах данных персональных
            данных и
            обеспечивающих их обработку информационных технологий и технических средств;
            <br>трансграничная передача персональных данных - передача персональных данных на территорию иностранного
            государства органу власти иностранного государства, иностранному физическому лицу или иностранному
            юридическому
            лицу.

          </p>
        </li>
        <li>
          Цели сбора персональных данных
          <p>
            2.1. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных
            целей.
            Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.
            <br>2.2. Обработке подлежат только персональные данные, которые отвечают целям их обработки.
            <br>2.3. Обработка Оператором персональных данных осуществляется в следующих целях:
            <br>• обеспечение соблюдения Конституции Российской Федерации, федеральных законов и иных нормативных
            правовых
            актов Российской Федерации;
            <br>• осуществление своей деятельности в соответствии с уставом ИП «Хромов Александр Константинович», в том числе на
            информационной платформе {{ domain }} ;
            <br>• в рамках договорных обязательств с контрагентами, как юридическими так и физическими лицами имеющие,
            или
            нет, статусы индивидуального предпринимателя, самозанятого гражданина Российской Федерации;
            <br>• продвижение на рынке товаров, работ, услуг Оператора путем осуществления контактов с потенциальным
            потребителем с помощью средств связи, для повышения и контроля качества обслуживания, а также в
            статистических
            целях
            <br>• ведение кадрового делопроизводства;
            <br>• содействие работникам в трудоустройстве, получении образования и продвижении по службе, обеспечение
            личной
            безопасности работников, контроль количества и качества выполняемой работы, обеспечение сохранности
            имущества;
            <br>• привлечение и отбор кандидатов на работу у Оператора;
            <br>• организация постановки на индивидуальный (персонифицированный) учет работников в системе обязательного
            пенсионного страхования;
            <br>• заполнение и передача в органы исполнительной власти и иные уполномоченные организации требуемых форм
            отчетности;
            <br>• осуществление гражданско-правовых отношений;
            <br>• ведение бухгалтерского учета;
            <br>• осуществление пропускного режима.
          </p>
        </li>
        <li>
          Субъекты персональных данных
          <p>
            3.1. Субъектами персональных данных для Оператора являются:
            <br>-соискатели вакантных должностей у Оператора и включенные в кадровый резерв Оператора, а так же члены их
            семей;
            <br>-действующие и уволенные работники Оператора, а также члены их семей;
            <br>-контрагенты, заключившие, расторгшие или планирующие заключить с Оператором гражданско-правовой
            договор;
            <br>-представители (работники) клиентов и контрагентов Оператора, в том числе представители потенциальных
            клиентов и контрагентов;
            <br>- клиенты - физические лица Оператора, в том числе потенциальные;
            <br>-иные физические лица, выразившие согласие на обработку Оператором их персональных данных или физические
            лица, обработка персональных данных которых необходима Оператору для достижения целей, предусмотренных
            законодательством РФ для осуществления и выполнения возложенных на Оператора функций, полномочий и
            обязанностей.
            <br>3.2. Персональные данные могут быть получены Оператором от лица, не являющегося субъектом персональных
            данных, при условии предоставления оператору подтверждения наличия оснований необходимых для исполнения
            договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект
            персональных
            данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому
            субъект персональных данных будет являться выгодоприобретателем или поручителем;
          </p>
        </li>
        <li>
          Состав персональных данных
          <p>
            4.1. В процессе своей деятельности Оператор осуществляет обработку следующих персональных данных:
            <br>-фамилия, имя, отчество (в том числе и прежнее);
            <br>-пол;
            <br>-дата и место рождения;
            <br>-паспортные данные, данные других документов, удостоверяющих личность (серия, номер, дата выдачи, код
            подразделения, наименование органа, выдавшего документ);
            <br>-гражданство;
            <br>-статус резидента;
            <br>-семейное положение;
            <br>-адрес места жительства (адрес регистрации, адрес проживания, почтовый адрес);
            <br>-электронный адрес (E-MAIL);
            <br>-номера контактных телефонов;
            <br>-место работы и должность;
            <br>-информация о семейном положении;
            <br>- образование и профессия;
            <br>-сведения о профессиональной переподготовке, повышении квалификации;
            <br>-сведения о воинском учете;
            <br>-данные, содержащиеся в трудовой книжке;
            <br>-контактная информация о текущем и предыдущих местах работы;
            <br>-сведения о привлечении к уголовной/административной ответственности;
            <br>-информация о близких родственниках (супругов, детей: фамилия, имя и отчество, дата рождения, номера и
            даты
            выдачи свидетельства о рождении, браке и расторжении брака);
            <br>-идентификационный номер налогоплательщика;
            <br>-страховой номер индивидуального лицевого счета;
            <br>-номера договоров;
            <br>-информация о счетах (банковские реквизиты);
            <br>-номера кредитных карт;
            <br>-иная информация необходимая Оператору для осуществления услуг, согласно заключенному договору
            (соглашению).
            <br>4.2. Оператор осуществляет обработку специальных персональных данных в части: состояния здоровья
            сотрудников,
            в кадровых системах Оператора:
            <br>-сведения документов, подтверждающих право на льготы, в том числе сведения о инвалидности;
            <br>-сведения о временной нетрудоспособности (листки нетрудоспособности).
            <br>4.3. Оператор осуществляет обработку сведений, которые характеризуют физиологические и особенности
            человека
            (фотографии), на основании которых можно установить его личность и осуществить поиск уже существующих резюме
            и
            анкет в системе интернет.
          </p>
        </li>
        <li>
          Согласие на обработку персональных данных
          <p>
            5.1. Получение и обработка персональных данных осуществляется Оператором с письменного согласия субъекта
            персональных данных.
            <br>5.2. Согласие в письменной форме субъекта персональных данных на обработку его персональных данных
            должно
            включать в себя, в частности:
            <br>-фамилию, имя, отчество, адрес субъекта персональных данных, номер основного документа, удостоверяющего
            его
            личность, сведения о дате выдачи указанного документа и выдавшем его органе;
            <br>-фамилию, имя, отчество, адрес представителя субъекта персональных данных, номер основного документа,
            удостоверяющего его личность, сведения о дате выдачи указанного документа и выдавшем его органе, реквизиты
            доверенности или иного документа, подтверждающего полномочия этого представителя (при получении согласия от
            представителя субъекта персональных данных);
            <br>-наименование или фамилию, имя, отчество и адрес оператора, получающего согласие субъекта персональных
            данных;
            <br>-цель обработки персональных данных;
            <br>-перечень персональных данных, на обработку которых дается согласие субъекта персональных данных;
            <br>-наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по
            поручению оператора, если обработка будет поручена такому лицу;
            <br>-перечень действий с персональными данными, на совершение которых дается согласие, общее описание
            используемых оператором способов обработки персональных данных;
            <br>-срок, в течение которого действует согласие субъекта персональных данных, а также способ его отзыва,
            если
            иное не установлено федеральным законом;
            <br>-подпись субъекта персональных данных.
            <br>5.3. Факт подтверждения согласия путем установки электронной подписи (установка
            флагов/переключателей/нажатия
            кнопок, ввод кодов, паролей на экранных веб-форматах), подтверждающих согласие субъекта с условиями
            обработки
            персональных данных при заполнении регистрационных форм, анкет на официальном сайте Оператора в целях
            получения
            услуг, предоставляемых Оператором, в том числе в целях оплаты услуг, организации собеседований с
            кандидатами,
            включении их в кадровый резерв заказчиков, расценивается Оператором как согласие на обработку персональных
            данных субъектом, даваемое субъектом в письменном вида и является равнозначным.
            <br>5.4. Согласие на обработку персональных данных может быть отозвано субъектом персональных данных путем
            направления Оператору письменного заявления в свободной форме. В этом случае Оператор обязуется прекратить
            обработку, а также уничтожить все имеющиеся в его распоряжении персональные данные без согласия субъекта
            персональных данных (или при отзыве субъектом персональных данных указанного согласия) при наличии
            оснований,
            указанных в ФЗ «О персональных данных».
          </p>
        </li>
        <li>
          Согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения
          <p>
            6.1. Согласие оформляется отдельно от иных согласий субъекта персональных данных на обработку его
            персональных
            данных, предоставляется с использованием информационной системы Оператора, осуществляющего обработку
            персональных данных.
            <br>6.2. Согласие должно включать в себя следующую информацию:
            <br>-фамилия, имя, отчество (при наличии) субъекта персональных данных;
            <br>-контактная информация (номер телефона, адрес электронной почты или почтовый адрес субъекта персональных
            данных);
            <br>-наименование или фамилия, имя, отчество (при наличии)
            <br>и адрес оператора, получающего согласие субъекта персональных данных;
            <br>- цель (цели) обработки персональных данных;
            <br>-категории и перечень персональных данных, на обработку которых дается Согласие субъекта персональных
            данных. Указание специальных категорий персональных данных и биометрических персональных данных допускается
            в
            случае предварительного получения оператором, осуществляющим обработку персональных данных, согласия на
            обработку персональных данных в соответствии с требованиями статей 9, 10, 11 Федерального закона от 27 июля
            2006
            г. № 152-ФЗ «О персональных данных»;
            <br>-категории и перечень персональных данных, для обработки которых субъект персональных данных
            устанавливает
            условия и запреты, а также перечень устанавливаемых условий и запретов. Указанное поле заполняется по
            желанию
            субъекта персональных данных без ограничений со стороны оператора, осуществляющего обработку персональных
            данных;
            <br>- срок, в течение которого действует Согласие;
            <br>-сведения об информационных ресурсах оператора, посредством которых будет осуществляться предоставление
            доступа неограниченному кругу лиц и иные действия с персональными данными субъекта персональных данных.
            <br>6.3. Дополнительно в Согласии могут быть указаны условия, при которых полученные персональные данные
            могут
            передаваться оператором, осуществляющим обработку персональных данных, только по его внутренней сети,
            обеспечивающей доступ к информации лишь для строго определенных сотрудников, либо с использованием
            информационно-телекоммуникационных сетей, либо без передачи полученных персональных данных.
          </p>
        </li>
        <li>
          Поручение на обработку персональных данных
          <p>
            7.1. Оператор вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных
            если
            иное не предусмотрено федеральным законом, на основании заключаемого с этой стороной договора (поручения на
            обработку персональных данных). Лицо, осуществляющее обработку персональных данных по поручению Оператора,
            обязано соблюдать принципы и правила обработки персональных данных по поручению Оператора, обязано соблюдать
            принципы и правила обработки персональных данных, предусмотренные ФЗ «О персональных данных».
            <br>7.2. В поручении Оператора должны быть определены перечень действия (операций) с персональными данными,
            которые будут совершаться лицом, осуществляющим обработку персональных данных, и цели обработки, должна быть
            установлена обязанность такого лица соблюдать конфиденциальность персональных данных и обеспечивать
            безопасность
            персональных данных при их обработке, а также должны быть указаны требования к защите обрабатываемых
            персональных данных в соответствии с ФЗ «О персональных данных».
          </p>
        </li>
        <li>
          Обязанности ответственных лиц Оператора при обработке персональных данных

          <p>
            8.1. В целях обеспечения выполнения Оператором обязанностей, предусмотренных законодательством Российской
            Федерации о персональных данных, в ИП «Хромов Александр Константинович» назначаются:
            <br>-должностное лицо, ответственное за организацию обработки персональных данных;
            <br>-должностное лицо, обслуживающее информационную систему персональных данных.
            <br>8.2. Должностное лицо, ответственное за обеспечение безопасности персональных данных Оператором не
            назначается. Сайт https://{{ domain }} и программное обеспечение Оператора размещены в облаке на серверах
            принадлежащих компании ООО «Регистратор доменных имён РЕГ.РУ» ИНН 7733568767, ОГРН 1067746613494,
            зарегистрированная по адресу: 125252, г.Москва, пр.Березовой Рощи, д.12, эт.2,к.4 . Все данные защищаются и
            обрабатываются в соответствии с Политикой обработки персональных данных ООО «Регистратор доменных имен
            РЕГ.РУ» .
            <br>8.3. Лицо, ответственное за организацию обработки персональных данных, осуществляет общую организацию
            обработки персональных данных, в том числе:
            <br>-уведомление уполномоченного органа по защите прав субъектов персональных данных об обработки
            персональных
            данных а так же распространении персональных данных в соответствии с требованиями ФЗ «О персональных
            данных»;
            <br>-организацию приема и обработки обращений и запросов субъектов персональных данных (или их
            представителей),
            а также контролирующих органов и контроля за приемом и обработкой таких обращений и запросов;
            <br>-организацию процессов повышения уровня осведомленности работников Оператора в вопросах обработки
            персональных данных.
            <br>8.4. Должностные лица, обслуживающие информационную систему персональных данных, включают работников
            Оператора (администратора системного и прикладного программного обеспечения, сетевого администратора),
            которые
            обеспечивают функционирование информационной системы персональных данных, а также управлением сетевым
            оборудованием, участвующим в передаче персональных данных.
          </p>
        </li>
        <li>
          Способы обработки персональных данных
          <p>
            9.1. Обработка персональных данных Оператором осуществляется путем сбора, записи, систематизации,
            накопления,
            хранения, уточнения (обновления, изменение), извлечения, использования, передачи (распространение,
            предоставление, доступ), обезличивания, блокирования, удаления, уничтожения.
            <br>9.2. Оператор осуществляет обработку персональных данных следующими способами:
            <br>-автоматическая обработка (производится при помощи средств вычислительной техники);
            <br>-неавтоматическая обработка (производится без участия средств вычислительной техники);
            <br>-смешанная обработка (производится как при помощи средств вычислительной техники, так и без них).
            <br>9.3. Автоматическая обработка персональных данных осуществляется согласно требованиям и положениям
            настоящей
            Политики в части обработки персональных данных в информационной системе персональных данных Оператора.
            <br>9.4. Обработка персональных данных без использования средств автоматизации осуществления согласно
            Правилам
            обработки персональных данных без использования средств автоматизации ИП «Хромов Александр Константинович».
            <br>9.5. Уточнение персональных данных, обрабатываемых Оператором осуществляется по запросам субъектов
            персональных данных, их законных представителей или в случае обращения уполномоченного органа по защите прав
            субъектов персональных данных.
            <br>9.6. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей
            обработки
            или в случае прекращения необходимости в достижении этих целей, если иное не предусмотрено федеральным
            законом.
            <br>9.7. Основанием для уничтожения персональных данных, обрабатываемых Оператором, является:
            <br>-достижение цели обработки персональных данных;
            <br>-прекращение необходимости в достижении цели обработки персональных данных;
            <br>-отзыв субъектом персональных данных согласия на обработку своих персональных данных, за исключением
            случаев, когда обработка указанных персональных данных является обязательной в соответствии с
            законодательством
            Российской Федерации или договором, либо обработка может осуществляться без согласия субъекта персональных
            данных;
            <br>-выявление неправомерных действий с персональными данными и невозможности устранения допущенных
            нарушений в
            срок, не превышающих трех рабочих дней с даты такого выявления;
            <br>-истечение срока хранения персональных данных, установленного законодательством Российской Федерации,
            нормативными документами Оператора и согласием собственника персональных данных;
            <br>-предписание уполномоченного органа по защите прав субъектов персональных данных (Роскомнадзор) или
            иного
            уполномоченного лица.
            <br>9.8. Обезличивание персональных данных осуществляется следующими методами:
            <br>-введение идентификаторов, путем замены части сведений на идентификаторы, с созданием таблицы
            (справочника)
            соответствия идентификаторов исходным данным;
            <br>-изменения состава или семантики данных путем их замены результатами статистической обработки,
            преобразования, обобщения или удаления части сведений;
            <br>-декомпозиции, путем разделения множества (массива) данных на несколько подмножеств;
            <br>-перемешивания, путем перестановки отдельных значений или групп значений атрибутов данных в массиве
            данных.
          </p>
        </li>
        <li>
          Период обработки персональных данных
          <p>
            10.1. Обработка персональных данных начинается с момента поступления к Оператору и прекращается:
            <br>-в случае выявления неправомерной обработки персональных данных Оператором или лицом действующим по его
            поручению, Оператор в срок, не превышающих десяти рабочих дней с даты такого выявления, обязано прекратить
            неправомерную обработку персональных данных или обеспечить прекращение неправомерной обработки персональных
            данных лицом, действующим по его поручению. В случае невозможности устранения допущенных нарушений Оператор
            в
            срок, не превышающий десять рабочих дней с даты выявления неправомерности действий с персональных данных,
            уничтожает персональные данные или обеспечивает их уничтожение. Об устранении допущенных нарушений или об
            уничтожении персональных данных Оператор уведомляет субъекта персональных данных или его представителя, а в
            случае, если обращение или запрос были направлены Роскомнадзором – также этот орган;
            <br>-в случае достижения цели обработки персональных данных Оператор незамедлительно прекращает обработку
            персональных данных и уничтожает соответствующие персональные данные в срок, не превышающий тридцати рабочих
            дней с даты достижения цели обработки персональных данных;
            <br>-в случае отзыва субъектом персональных данных согласия на обработку своих персональных данных Оператор
            прекращает обработку персональных данных и уничтожает (за исключением персональных данных, которые хранятся
            в
            соответствии с действующим законодательством) персональные данные в срок, не превышающий тридцати рабочих
            дней с
            даты поступления указанного отзыва об уничтожении персональный данных Оператор уведомляет субъекта
            персональный
            данных.
            <br>10.2. Уничтожение персональных данных производится в случаях и в сроки, указанные выше, за исключением
            персональных данных бухгалтерского и кадрового учета, которые хранятся в соответствии с действующим
            законодательством Российской Федерации.
            <br>10.3. В случае отсутствия возможности уничтожения персональных данных в течение срока, указанного в
            настоящей
            Политики, Оператор осуществляет блокирование таких персональных данных или обеспечивает их блокирование
            (если
            обработка персональных данных осуществляется другим лицом, действующим по поручению Оператора) обеспечивает
            уничтожение персональных данных в срок не более чем шесть месяцев, если иной срок не установлен федеральными
            законами.
          </p>
        </li>
        <li>
          Права субъектов персональных данных
          <p>
            11.1. Субъект персональных данных имеет право на получение информации, касающейся обработки Оператором его
            персональных данных.
            <br>11.2. Субъект персональных данных вправе требовать от Оператора уточнения его персональных данных, их
            блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными,
            незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать
            предусмотренные законом меры по защите своих прав.
            <br>11.3. Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в
            соответствии с федеральными законами Российской Федерации, в том числе если доступ субъекта персональных
            данных
            к его персональным данным нарушает права и законные интересы третьих лиц.
            <br>11.4. Оператор принимает и обрабатывает запросы субъектом персональных данных и предоставляет сведения,
            подтверждающие факты обработки персональных данных, правовые основания, цели, сроки и способы обработки
            персональных данных, виды обрабатываемых персональных данных, свое наименование и местонахождение.
          </p>
        </li>
        <li>
          Основные меры по обеспечению безопасности персональных данных
          <p>
            12.1. Оператор принимает все необходимые меры по обеспечению выполнения взятых на себя обязанностей,
            предусмотренных ФЗ «О персональных данных».
            <br>12.2. Работники Оператора, виновные в нарушении норм, регулирующих обработку персональных данных,
            установленных Оператором, могут быть привлечены к дисциплинарной, материальной, административной и уголовной
            ответственности, в соответствии с законодательством Российской Федерации.
          </p>
        </li>
        <li>
          Заключительные положения
          <p>
            13.1. В случае изменений законодательных или иных нормативных актов Российской Федерации, а также Устава
            Оператора, настоящий документ, а также изменения к нему принимаются в части не противоречащей вновь принятым
            нормативным актам, а также Уставу Оператора.
          </p>
        </li>
      </ol>
    </ContentWrapper>
  </div>
</template>

<script>
export default {
  name: "Privacy",
  data() {
    return {
      domain: this.$server.domain
    }
  },
  mounted() {
    document.title = 'Политика в отношении обработки персональных данных'
  }
}
</script>

<style scoped>
.text__title {
  margin: 20px 0;
  text-align: center;
}
</style>
