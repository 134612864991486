<template>
  <v-dialog @click:outside="onOutsideClick" content-class="dialog-wrapper" v-model="opened" :retain-focus="false"
            :width="width" persistent>
    <div class="dialog-header">
      <div class="dialog-header__title">Мама7Д</div>
      <v-btn icon>
        <v-icon @click="onIconCloseClick" class="btn-close-modal--mobile" size="30">mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="dialog-content" :class="classes">
      <v-icon v-if="showedClose" class="btn-close-modal mt-8 mr-8" @click.prevent="onIconCloseClick" size="24">
        mdi-close
      </v-icon>
      <slot></slot>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  props: ['width', 'classes', 'showedClose'],
  emits: ['on-icon-close-click', 'on-outside-click'],
  data() {
    return {
      opened: false,
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    onIconCloseClick() {
      this.$emit('on-icon-close-click');
    },
    onOutsideClick() {
      this.$emit('on-outside-click');
    },
  }
}
</script>

<style lang="scss">
.v-application .dialog-wrapper {
  border-radius: 20px;
  margin: 0 auto;
  background: #F4EDE5;

  .dialog-header {
    display: none;
  }

  .dialog-content {
    position: relative;
    background: #F4EDE5;
    border-radius: 20px;

    .btn-close-modal {
      position: absolute !important;
      top: 0;
      right: 0;
      z-index: 1;
    }

    &.modal-wrapper {
      padding: 50px 85px 60px;

      .modal__title {
        margin-bottom: 20px;
      }

      .modal-form-wrapper {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .v-application .dialog-wrapper {
    width: 100% !important;
    height: 100%;
    max-height: 100% !important;
    border-radius: 0;

    .dialog-header {
      display: flex;
      padding: 0 30px;
      justify-content: space-between;
      align-items: center;
      height: 75px;
      background: #ffffff;

      &__title {
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
      }
    }

    .dialog-content {
      border-radius: 0;
      height: calc(100% - 75px);

      &.modal-wrapper {
        padding: 50px 30px 60px;
      }

      .btn-close-modal {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .v-application .dialog-wrapper {
    .dialog-header {
      height: 50px;
      padding: 0 20px;

      &__title {
        font-size: 18px;
        line-height: 120%;
      }
    }

    .dialog-content {
      &.modal-wrapper {
        padding: 60px 20px 60px;
        text-align: center;
      }
    }
  }
}
</style>
