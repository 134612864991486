<template>
  <v-app>
    <div>
      <Header />
    </div>

    <div class="page--wrapper" v-bind:class="getDisplayedStyles">
      <router-view />
    </div>

    <div>
      <Footer variant="blue" />
    </div>
    <CreateReview ref="create_review_modal"/>
    <Auth ref="auth_modal"/>
    <Prompt ref="prompt_modal"/>
    <ConsultationModal ref="consult_modal"/>
    <Buy ref="buy_modal"/>
  </v-app>
</template>

<script>
import Footer from "../components/reusable/Footer";
import Header from "../components/reusable/Header";
import CreateReview from "@/modals/CreateReview";
import Auth from "@/modals/Auth";
import Buy from "@/modals/Buy";
import ConsultationModal from "@/views/consultation/ConsultationModal";
export default {
  components: {
    Buy,
    Footer,
    Header,
    CreateReview,
    Auth,
    ConsultationModal,
  },
  methods: {
    menuItems() {
      return this.menu;
    },
  },
  created() {
    this.$eventBus.$on('open_create_review_modal', ()=>{
      this.$refs.create_review_modal.open();
    })
    this.$eventBus.$on('open_auth_modal', (data)=>{
      this.$refs.auth_modal.open(data)
    });
    this.$eventBus.$on('open_prompt_modal', (params)=>{
      this.$refs.prompt_modal.open(params)
    })
    this.$eventBus.$on('open_buy_modal', (payture_link)=>{
      this.$refs.buy_modal.open(payture_link)
    })
    this.$eventBus.$on('open-consult-modal', (params)=>{
      this.$refs.consult_modal.open(params);
    })
  }
};
</script>

<style scoped>
.page--wrapper {
  margin: 0 auto;
  background: #f4ede5;
}

</style>
