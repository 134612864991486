<template>
  <div class="page-content">
    <ContentWrapper>
      <div class="reviews-section">
        <Back text="Назад" to="/"/>
        <HText variant="h1" class="reviews-section__title">Отзывы</HText>

<!--        <CText v-if="reviews.length > 0" class="reviews-section__label" :text="'Все отзывы настоящие – их написано уже ' + reviews.length"></CText>-->
<!--        <CustomButton variant="black" text="Оставить отзыв" class="leave-review-btn" @click.native="$eventBus.$emit('open_create_review_modal')"/>-->
<!--        <div class="sort-toggle">-->
<!--          <div-->
<!--              class="sort-toggle__item"-->
<!--              :class="{'sort-toggle__item&#45;&#45;active': key === currentSort}"-->
<!--              v-for="(sort, key) in sortMethods"-->
<!--              :key="key"-->
<!--              @click="currentSort = key"-->
<!--          >-->
<!--            {{ sort.text }}-->
<!--          </div>-->
<!--        </div>-->
        <ReviewsSection :video-reviews="$store.state.allReviews" class='reviews-body' :reviews="reviews"/>
      </div>
    </ContentWrapper>
  </div>
</template>

<script>
import ReviewsSection from "@/components/reusable/ReviewsSection";

export default {
  name: "ReviewsPage",
  components: {
    ReviewsSection
  },
  data() {
    return {
      reviews: [],
      sortMethods: {
        popular: {text: 'Популярные'},
        new: {text: 'Новые'},
      },
      currentSort: 'popular',
    }
  },
  methods: {
    getReviews() {
      this.$server.request('feedback/get', {}, (data)=>{
        this.reviews = data.response
      })
    }
  },
  mounted() {
    this.getReviews()
    document.title = 'Mama7d - Отзывы'
  }
}
</script>

<style scoped lang="scss">
.page-content .reviews-section {
  padding: 145px 0 120px;

  .leave-review-btn {
    position: absolute;
    top: 50px;
    right: 0;
  }

  &__title {
    margin-bottom: 20px;
    text-align: left;
  }

  &__label {
    margin-bottom: 55px;
  }

  .sort-toggle {
    display: flex;
    align-self: flex-start;
    justify-content: flex-start;
    margin-bottom: 26px;

    &__item {
      font-size: 14px;
      line-height: 140%;
      color: rgba(0, 0, 0, .5);
      border: 1px solid;
      border-right-style: none;
      padding: 3px 15px;
      cursor: pointer;
      white-space: nowrap;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
        border-right-style: solid;
      }

      &--active {
        color: rgba(0, 0, 0, 1);
        border-right-style: solid;

        & + .sort-toggle__item {
          border-left-style: none;
        }
      }
    }
  }

  .reviews-body {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .page-content {
    .reviews-section {
      padding-top: 60px;
      .leave-review-btn {
        right: 30px;
        top: 60px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .page-content {
    .reviews-section {
      .leave-review-btn {
        position: initial;
        width: 100%;
        margin-bottom: 50px;
      }
      &__title {
        text-align: center;
      }
      &__label {
        text-align: center;
        margin-bottom: 40px;
      }
      .sort-toggle__item {
        flex-grow: 1;
      }
    }
  }
}
</style>
