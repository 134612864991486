export class Server {
  constructor(eventBus, store) {
    if (location.host.includes('localhost')) {
      this.domain = 'https://7d.khromov.su';
    } else {
      this.domain = `https://${location.host}`;
    }
    this.url = `${this.domain}/api/`;
    this.token = this.getToken();
    this.$eventBus = eventBus
    this.$store = store
  }

  getToken() {
    return localStorage.api_token;
  }

  setToken(token) {
    localStorage.api_token = token;
    this.token = token;
  }

  logout() {
    localStorage.clear()
    this.request('auth/logout', {})
    window.open('/', '_self')
  }

  request(method, data = {}, onSuccess, onError, openAuthModal = true) {
    let formData = new FormData()
    // data['sleep'] = 4;
    // data['errorCode'] = 1;
    if (this.token) data['token'] = this.token
    for (let i in data) {
      formData.append(i, data[i]);
    }

    const checkForError = (response) => {
      if (!response.ok) throw Error(response.statusText);
      return response.json();
    };

    return fetch(`${this.url + method}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    })
      .then(checkForError)
      .then((response) => {

        if (!response.success) {
          this.$eventBus.$emit('open_prompt_modal', {type: 'error', title: response.error});
          if (typeof onError === 'function') onError(response);

          if (response.error === 'user is not logged in' && openAuthModal)
            this.$eventBus.$emit('open_auth_modal');
          return;
        }

        if (response.success && this.$store.state.user && !this.$store.state.user.id && response.user.id) {
          this.$store.commit('set', ['user', response.user]);
        }
        if (typeof onSuccess === 'function') onSuccess(response);
        return response;
      })
      .catch((error) => {
        if (typeof onError === 'function') onError(error);
        this.$eventBus.$emit('open_prompt_modal', {type: 'error', title: error});
        return error;
      })
  }
}
