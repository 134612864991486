<template>
  <div class="page-content courses-content">
    <ContentWrapper background="#eaf4fc">
      <div class="page-header">
        <div class="page-title">
          <HText variant="h1 page-title__main">Курсы</HText>
        </div>
        <div class="page-header-row">
          <div class="page-title__subtitle">
            <img src="/img/check.png" alt="check icon">
            Обучающие серии из 10-50 видео
          </div>
<!--          <div class="page-title__subtitle">-->
<!--            <img src="/img/check.png" alt="check icon">-->
<!--            Задания и проверочные-->
<!--          </div>-->
<!--          <div class="page-title__subtitle">-->
<!--            <img src="/img/check.png" alt="check icon">-->
<!--            Чат для всех учащихся-->
<!--          </div>-->
        </div>
<!--        <TagList :tags="tags" class="page-header&#45;&#45;tag-list" @change="onChangeTabs"/>-->
      </div>
    </ContentWrapper>
    <ContentWrapper style="padding-top: 60px">
      <CoursesSection
          :mobile-show="mobileShow"
          :courses="!mobileShow ? courses.slice(0, 2) : courses"/>
    </ContentWrapper>
<!--    <CustomButton-->
<!--        v-if="!mobileShow"-->
<!--        @click.native="mobileShow = true"-->
<!--        :text="`Все ${courses.length} ${declOfNum(courses.length, ['курс', 'курса', 'курсов'])}`"-->
<!--        variant="black"-->
<!--    />-->
  </div>
</template>

<script>
// import TagList from "@/components/reusable/TagList";
import CoursesSection from "@/components/reusable/CoursesSection";
export default {
  name: "Courses",
  components: {CoursesSection},
  data() {
    return {
      tags: ['Бесплатные', '#Беременность', '#Беременность', '#Беременность', '#Беременность', '# 1-3 года', '# 1-4 года', '# 1-5 года', '# 1-6 года', '# 1-7 года', '# 1-8 года', '# 1-9 года', '# 1-10 года', '# 1-11 года', '# 1-12 года', '# 1-13 года', '# 1-14 года', '# 1-15 года', '# 1-16 года', '# 1-17 года', '# 1-18 года', '# 1-19 года'],
      courses: [],
      swiper: null,
      mobileShow: true,
    }
  },
  methods: {
    onChangeTabs(selectedTags) {
      return selectedTags
    },
    getCourses() {
      this.$server.request('course/get', {}, (data)=>{
        this.courses = data.response
      })
    }
  },
  mounted() {
    this.getCourses()
    document.title = 'Mama7d - Курсы'
  }
}
</script>

<style lang="scss">
.page-header {
  padding-top: 85px;
  padding-bottom: 80px;
  &-row {
    max-width: 680px;
  }
}

.courses-content {
  .custom-btn {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .page-header {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .page-header-row {
    flex-direction: column;
    .page-title__subtitle {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .courses-block {
    &__item {
      width: 100% !important;
    }
    &__items {
      flex-direction: column;
      align-items: center;
      & > .courses-block__item {
        display: none;
        &:nth-child(1), &:nth-child(2) {
          display: block;
        }
      }
      &--mobile-shown-true {
        & > .courses-block__item {
          display: block !important;
        }
      }
    }
    &_item {
      margin-right: 0;
    }
  }
  .page-content.courses-content {
    .custom-btn {
      margin: 0 20px;
      width: calc(100% - 40px);
      display: block;
    }
  }
}
</style>
