import Vue from 'vue'
import Router from 'vue-router'

import MainLayout from "@/layouts/MainLayout";

import Main from './views/Main'
import Videos from "@/views/Videos";
import Courses from "@/views/Courses";
import Blogs from "@/views/Blogs";
import Clubs from "@/views/Clubs";
import Video from "@/views/sub-pages/Video";
import Course from "@/views/sub-pages/Course";
import OnlineCourse from "@/views/sub-pages/OnlineCourse";
import Club from "@/views/sub-pages/Club";
import Blog from "@/views/sub-pages/Blog";
import ReviewsPage from "@/views/ReviewsPage";
import AccountContainer from "@/views/account/AccountContainer";
import Privacy from "@/views/terms/Privacy";
import Agreement from "@/views/terms/Agreement";
import Article1 from "./views/sub-pages/Article1";
import CRMClients from "./views/crm/Clients";
import NotFound from "@/views/NotFound";
import Checkout from "@/views/checkout/Checkout";
import OrderCreate from "@/views/crm/OrderCreate";

Vue.use(Router)

export default new Router({
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  },
  routes: [
    {
      path: '/',
      name: '',
      component: MainLayout,
      children: [
        {
          path: '/',
          name: 'main',
          component: Main,
        },
        {
          path: 'crm/clients',
          component: CRMClients,
        },
        {
          path: 'crm/order_create',
          component: OrderCreate,
        },
        {
          path: 'videos',
          name: 'Videos',
          component: Videos,
        },
        {
          path: 'videos/:id',
          name: 'video',
          component: Video,
        },
        {
          path: '/courses',
          name: 'Courses',
          component: Courses,
        },
        {
          path: '/courses/:id',
          name: 'Course',
          component: Course,
        },
        {
          path: '/clubs',
          name: 'Clubs',
          component: Clubs,
        },
        {
          path: '/online-course',
          name: 'OnlineCourse',
          component: OnlineCourse,
        },
        {
          path: '/childbirth_in_emergency',
          component: Article1,
        },
        {
          path: '/clubs/:id',
          name: 'Club',
          component: Club,
        },
        {
          path: '/blog',
          name: 'Blog',
          component: Blogs,
        },
        {
          path: '/blog/:id',
          name: 'Blog_1',
          component: Blog,
        },
        {
          path: '/reviews',
          name: 'Reviews',
          component: ReviewsPage,
        },
        {
          path: '/account/:tab?',
          name: 'Account',
          component: AccountContainer,
        },
        {
          path: '/privacy',
          name: 'Privacy',
          component: Privacy,
        },
        {
          path: '/agreement',
          name: 'Agreement',
          component: Agreement,
        },
        {
          path: '/checkout/:id?',
          name: 'Checkout',
          component: Checkout,
        },
        {
          path: '*',
          name: 'NotFound',
          component: NotFound
        }
      ]
    },
  ]
})
