<template>
  <ContentWrapper background="var(--main-bg-color)">
    <div class="third-section-container">
      <div class="category category-videos">
        <div class="category--title">
          <HText>Библиотека лекций</HText>
          <span class="category--title-index">{{ videosCount ? videosCount : '' }}</span>
        </div>
<!--        <CText class="category&#45;&#45;subtitle" :text="'1 видео = 1 полезный совет'"></CText>-->
        <VideosSection :items="videos" :enabled-desktop="false" :show-arrow="false"/>
        <CustomButton v-if="videos.length" style="align-self: center;" :text="`Все ${videosCount} видеолекций`" variant="black"
                      to="/videos"
        />
        <v-skeleton-loader v-else type="button" style="align-self: center;" width="150"/>
      </div>

      <div class="category category-courses">
        <div class="category--title">
          <HText>Курсы</HText>
          <span class="category--title-index">{{ coursesCount ? coursesCount : '' }}</span>
        </div>

        <div class="category--subtitle ">
          <div class="category--subtitle-item">
            <img :src="'/img/check.png'" width="14" height="10"/>
            <CText :text="'Обучающие серии из 10-50 видео'"></CText>
          </div>
<!--          <div class="category&#45;&#45;subtitle-item">-->
<!--            <img :src="'/img/check.png'" width="14" height="10"/>-->
<!--            <CText :text="'Задания и проверочные'"></CText>-->
<!--          </div>-->
<!--          <div class="category&#45;&#45;subtitle-item">-->
<!--            <img src="/img/check.png" width="14" height="10"/>-->
<!--            <CText :text="'Чат для всех учащихся'"></CText>-->
<!--          </div>-->
        </div>

        <div class="category--container">
          <CoursesSection :courses="courses"/>
        </div>
        <CustomButton v-if="courses.length" style="align-self: center;" :text="`Все ${coursesCount} курсов`" variant="black" to="/courses"/>
        <v-skeleton-loader v-else type="button" style="align-self: center;" width="150"/>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import VideosSection from "@/components/videos-page/VideosSection";
import CoursesSection from "@/components/reusable/CoursesSection";
export default {
  components: {
    VideosSection,
    CoursesSection
  },
  data() {
    return {
      videos: [],
      videosCount: 0,
      courses: [],
      coursesCount: 0,
    }
  },
  methods: {
    getVideos() {
      this.$server.request('video/get', {count: 6}, (data)=>{
        this.videos = data.response
        this.videosCount = data.count
      })
    },
    getCourses() {
      this.$server.request('course/get', {count: 4}, (data)=>{
        this.courses = data.response
        this.coursesCount = data.count
      })
    },
  },
  mounted() {
    this.getVideos()
    this.getCourses()
  }
};
</script>

<style lang="scss">
.third-section-container {
  padding-top: 165px;
  padding-bottom: 145px;
  display: flex;
  flex-direction: column;

  .category {
    display: flex;
    flex-direction: column;
    &--title {
      display: flex;
      margin-bottom: 10px;
      &-index {
        margin-top: -5px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-left: 10px;
      }
    }
    &--subtitle {
      display: flex;
      margin-bottom: 40px;
    }
    &--container {
      display: flex;
      margin-bottom: 40px;
    }
  }
  .category-videos {
    margin-bottom: 80px;
  }
  .category-courses {
    .category--subtitle-item {
      display: flex;
      margin-right: 40px;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }
    }
    .category--subtitle-item img {
      margin-right: 10px;
    }
  }
}
@media (max-width: 768px) {
  .third-section-container {
    padding: 60px 0 !important;

    .category-videos {
      margin-bottom: 60px;
    }
  }
}

@media (max-width: 600px) {
  .third-section-container {
    .category--container {
      flex-direction: column;
    }
    .category-videos {
      margin-bottom: 70px;
    }
    .category--subtitle {
      flex-direction: column;
      &-item {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
