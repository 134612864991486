<template>
  <div class="consultation-first-step">
    <div class="consultation-first-step-inner">
      <v-form class="form-textfield" ref="form_first_step">
        <v-text-field
            @input="nameError = ''; "
            class="item input" label="Как вас зовут" placeholder="Имя" outlined
                      :error-messages="nameError"
                      v-model="consultation.name"/>
        <v-text-field class="item input phone-input" label="Телефон" placeholder="+7 (9__) ___ __ __" outlined
                      @input="phoneError = ''"
                      :error-messages="phoneError"
                      v-mask="'+7(XXX) XXX-XX-XX'"
                      v-model="consultation.phone"/>
      </v-form>
    </div>
    <CustomButton text="Далее" variant="black" class="form-btn" @click.native="goToSecondStep"/>
  </div>
</template>

<script>
export default {
  name: "FirstStepConsult",
  props: ['consultation'],
  data() {
    return {
      nameError: '',
      phoneError: '',
    }
  },
  emits: ['nextStep'],
  methods: {
    goToSecondStep() {
      if (!this.consultation.name) this.nameError = 'Необходимо заполнить поле';
      if (!this.consultation.phone) this.phoneError = 'Необходимо заполнить поле';
      if (this.consultation.name && this.consultation.phone) {
        this.$server.request('user/update', this.consultation, ()=>{
          this.$emit('nextStep');
        });
      }
    },
    onModalClose() {
      this.nameError = '';
      this.phoneError = '';
    }
  }
}
</script>

<style scoped>

</style>