<template>
  <ContentWrapper>
    <div class="account-videos-wrapper" v-if="itemsLoaded">
      <div class="account-videos-header">
      </div>
      <div class="account-videos-content">
        <ClipCard v-for="(video, i) in continueItems"
                  :key="i"
                  :video="video"/>
      </div>
    </div>
    <div v-else class="account-videos-content">
        <v-skeleton-loader style="box-shadow: none !important; border-radius: 20px" type="card" elevation="2" class="clipcard--wrapper" v-for="i in 2" :key="i"/>
    </div>
    <div
        v-if="!continueItems.length && itemsLoaded"
        class="account-courses-wrapper">
      <CText text="Здесь появяться видео, которые вы начнете смотреть"></CText>
    </div>
  </ContentWrapper>
</template>

<script>
import ClipCard from "@/components/reusable/ClipCard";
export default {
  name: "Continue",
  components: {
    ClipCard
  },
  data() {
    return {
      continueItems: [],
      itemsLoaded: false,
    }
  },
  methods: {
    getContinue() {
      this.$server.request('user/continueToWatch', {}, (data)=>{
        this.continueItems = data.response
        this.itemsLoaded = true
      })
    }
  },
  mounted() {
    this.getContinue()
  }
}
</script>

<style scoped>

</style>
