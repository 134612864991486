<template>
  <div class="header--wrapper" v-bind:class="getDisplayedStyles">
    <div class="header--container">
      <router-link to="/" class="header--link">
            <span class="header--logo">
                  Мама7Д
            </span>
      </router-link>
      <div class="header--actions">
        <router-link :to="{name: 'Videos'}" class="header--link">Видео-лекции</router-link>
        <router-link :to="{name: 'Courses'}" class="header--link">Курсы</router-link>
        <a href="https://t.me/mkh_07" target="_blank" class="header--link">Поддержка</a>

        <!--        <router-link to="/courses/18" class="header&#45;&#45;link">Онлайн курс</router-link>-->
        <router-link v-if="$store.state.user.clientsPageAllowed" to="/crm/clients" class="header--link">Клиенты</router-link>
        <router-link v-if="$store.state.user.clientsPageAllowed" to="/crm/order_create" class="header--link">Создание заказа</router-link>
<!--        <router-link :to="{name: 'Blog'}" class="header&#45;&#45;link">Блог</router-link>-->
        <CustomButton @click.native="openConsultModal" text="Консультация" variant="filled" style="padding: 3px 12px; margin-right: 14px"/>
        <CustomButton v-if="$store.state.user.id" to="/account/continue" text="Мой профиль" variant="black" style="padding: 3px 12px"/>
        <CustomButton v-else text="Войти" @click.native="openAuthModal" variant="black" style="padding: 3px 12px"/>
      </div>
      <div class="menu">
        <v-btn icon @click="openMenu">
          <v-icon size="30">mdi-menu</v-icon>
        </v-btn>
      </div>
    </div>
    <Menu ref="menuModal"/>
  </div>
</template>

<script>
import ConsultationModal from "@/views/consultation/ConsultationModal";
import Menu from "@/modals/Menu";
export default {
  components: {
    Menu
  },
  methods: {
    openConsultModal() {
      this.$eventBus.$emit('open-consult-modal');
    },
    openAuthModal() {
      this.$eventBus.$emit('open_auth_modal')
    },
    openMenu() {
      this.$refs.menuModal.open();
    },
  }
}
</script>

<style scoped lang="scss">
.header--wrapper {
  width: 100%;
  height: 86px;
}

.header--container {
  margin: 0 auto;
  max-width: 1000px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header--actions {
  display: flex;
  align-items: center;
}

.menu {
  display: none;
}

.header--link {
  text-decoration: none;
  color: #222222;
  margin-right: 22px;

  &.router-link-active {
    font-weight: 600;
  }
}

.header--logo {
  font-size: 22px;
  font-weight: 600;
}
@media screen and (max-width: 1024px) {
  .header--container {
    max-width: 768px;
  }
}
@media screen and (max-width: 768px) {
  .header--wrapper {
    height: 75px;
  }
  .header--container {
    height: 75px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .header--actions {
    display: none;
  }
  .menu {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .header--wrapper {
    height: 60px;
  }
  .header--container {
    height: 60px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
</style>
