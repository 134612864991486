<template>
  <div class="page-content">
    <ContentWrapper class="blog-header-wrapper" :background="$vuetify.breakpoint.width <=768 ? 'var(--bg-color-blue)' : ''">
      <div class="blog-header">
        <div class="blog-header-inner">
          <Back text="Назад" to="Blogs/"/>
          <HText variant="h1" class="blog-header__title">{{ blog.title }}</HText>
          <p class="blog-header__date">{{ blog.publicDate }}</p>
          <HText variant="h2" class="blog-header__text">{{ blog.text }}</HText>
        </div>
      </div>
    </ContentWrapper>
    <ContentWrapper>
      <div class="blog-article">
        <div class="blog-article-text">
          <div class="text">
            <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, alias aperiam architecto at debitis
              dicta doloribus dolorum eligendi enim fugit illum ipsa natus nulla quod, repudiandae suscipit tenetur
              voluptas voluptatem!
            </div>
            <div>Cumque delectus, illum molestias quaerat qui quisquam tempore? Accusantium aliquam aliquid atque
              corporis
              dolorem doloribus error illo ipsa iusto laborum libero molestias, nam numquam odio optio, perferendis
              perspiciatis porro quod.
            </div>
            <div>Dolor dolorem ea ipsa itaque possimus suscipit vero. Dicta earum error exercitationem fuga nesciunt
              nihil
              saepe vero. Corporis, enim iste, odit porro quidem rerum sapiente sunt tempora temporibus vero vitae.
            </div>
            <div>Consectetur cumque cupiditate delectus eius quaerat rerum. Asperiores dolorem hic recusandae
              repudiandae?
              Ab cupiditate, dolores illo iusto magnam nobis obcaecati porro quis sunt? Adipisci consectetur dolorem
              placeat porro reprehenderit ullam.
            </div>
            <div>Architecto atque cupiditate delectus eligendi et ipsa nobis nostrum praesentium, quaerat saepe sed
              voluptatibus voluptatum. Architecto beatae consequatur cumque dolorem in itaque molestiae sed sit unde
              voluptatem? Nostrum, quaerat, voluptatum.
            </div>
            <div>A ab accusamus corporis enim laborum, necessitatibus nisi reprehenderit velit. Aspernatur ea eaque
              labore
              omnis quae sunt? Asperiores ducimus officiis omnis! Id illo maxime nostrum qui tempora! Aliquid, ea unde.
            </div>
            <div>A architecto aut beatae dignissimos dolor dolore eligendi esse excepturi fuga, impedit iste molestiae
              nemo numquam officia possimus, provident quod rem saepe sapiente sequi sint sit ut vel veniam voluptatem.
            </div>
            <div>Accusamus, accusantium ad aliquam amet aperiam consequatur dolorem ducimus eius eligendi eos ex
              exercitationem fugiat harum hic iusto optio placeat possimus quod recusandae repellat repudiandae saepe
              sed
              similique sit soluta.
            </div>
            <div>Delectus est ipsa odit officia saepe. Accusamus animi aspernatur consectetur cumque debitis dolores et
              explicabo hic ipsa iste nostrum perferendis qui quia, repellat repellendus reprehenderit sit, vitae
              voluptate. Molestiae, voluptate!
            </div>
            <div>Autem distinctio earum eos fugit in, iusto nam, natus numquam repudiandae sapiente tenetur veritatis,
              voluptate voluptatibus. Ab architecto, assumenda dolor ducimus eum harum in iste sequi sunt tempore.
              Delectus, in.
            </div>
          </div>
          <div class="text-btns">
            <v-btn>Мне понравилось
              <v-icon class="ml-2">mdi-heart-outline</v-icon>
            </v-btn>
            <div class="blog-article-activity tablet-activity">
              <div class="blog-article-stats">
                <div class="likes">
                  <v-icon>mdi-heart-outline</v-icon>
                  <span>{{ blog.likes }}</span>
                </div>
                <div class="views">
                  <v-icon>mdi-eye-outline</v-icon>
                  <span>{{ blog.views }}</span>
                </div>
              </div>
              <div class="blog-article-share">
                <CText variant="caps" class="blog-article-share__title" :text="'Поделиться'"></CText>
                <div class="buttons">
                  <v-btn><img src="../../../public/img/icon-telegram.svg" alt=""></v-btn>
                  <v-btn><img src="../../../public/img/icon-vk.svg" alt=""></v-btn>
                  <v-btn><img src="../../../public/img/icon-fb-messenger.svg" alt=""></v-btn>
                  <v-btn><img src="../../../public/img/icon-whatsapp.svg" alt=""></v-btn>
                </div>
              </div>
            </div>
          </div>
          <CommentSection/>
        </div>

        <div class="blog-article-activity desktop-activity">
          <div class="blog-article-stats">
            <div class="likes">
              <v-icon>mdi-heart-outline</v-icon>
              <span>{{ blog.likes }}</span>
            </div>
            <div class="views">
              <v-icon>mdi-eye-outline</v-icon>
              <span>{{ blog.views }}</span>
            </div>
          </div>
          <div class="blog-article-share">
            <CText variant="caps" class="blog-article-share__title" :text="'Поделиться'"></CText>
            <v-btn><img src="../../../public/img/icon-telegram.svg" alt=""></v-btn>
            <v-btn><img src="../../../public/img/icon-vk.svg" alt=""></v-btn>
            <v-btn><img src="../../../public/img/icon-fb-messenger.svg" alt=""></v-btn>
            <v-btn><img src="../../../public/img/icon-whatsapp.svg" alt=""></v-btn>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </div>
</template>

<script>
import ContentWrapper from "@/components/reusable/ContentWrapper";
import HText from "@/components/reusable/HText";
import Back from "@/components/reusable/Back";
import CText from "@/components/reusable/CText";
import CommentSection from "@/components/reusable/CommentSection";

export default {
  name: "Blog",
  components: {
    ContentWrapper,
    HText,
    Back,
    CText,
    CommentSection
  },
  data() {
    return {
      blog: {
        title: 'История моей второй беременности',
        publicDate: '17 ноября 2018',
        text: 'Вы верите в чудеса? Сегодня хочу написать, каким чудесным образом нашему старшему сыну удалось появиться в нашей Жизни и «пробить» путь всем остальным. Да-да, не первому ребенку, а второму!',
        likes: 0,
        views: 0,
      }
    }
  }
}
</script>

<style lang="scss">
.page-content {
  .blog-header-wrapper .content-wrapper {
    max-width: 100%;

  }
  .blog-header-inner {
    margin: 0 auto;
    max-width: 1000px;
    padding: 170px 0 105px;
    background-size: cover;
  }
  .blog-header {
    background: url('../../../public/img/background_blog.png') no-repeat;
    background-size: cover;
    &__title {
      margin-bottom: 35px;
      max-width: 50%;
    }

    &__date {
      margin-bottom: 40px !important;
    }

    &__text {
      color: #6F8DA8;
      max-width: 50%;
    }
  }

  .blog-article {
    padding: 80px 35px 120px 0;
    display: flex;

    &-text {
      display: flex;
      flex-direction: column;
      min-width: 665px;
      width: 665px;
      margin-right: 220px;

      .text {
        align-self: flex-start;
        margin-bottom: 40px;

        div {
          font-size: 14px;
          line-height: 20px;
        }

        &-btns {
          display: flex;
          justify-content: flex-end;

          .v-btn {
            border: 1px solid rgba(34, 34, 34, 0.2);
            background: none !important;
            box-shadow: none;
          }
        }
      }
    }
    .tablet-activity {
      display: none;
    }

  }
  .desktop-activity, .tablet-activity {
    .blog-article-stats {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      .likes, .views {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .blog-article-share {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__title {
        margin-bottom: 20px;
      }

      .v-btn {
        margin-bottom: 15px;
        background: #FFFFFF !important;
        border-radius: 6px;
        width: 50px;
        min-width: 50px;
        min-height: 50px;
        height: 50px;
        box-shadow: none;
        border: none;
      }

      .v-btn:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .page-content {
    .blog-header-inner {
      max-width: 768px;
    }
  }
}

@media screen and (max-width: 768px) {
  .page-content {
    .blog-header-wrapper {
      .content-wrapper {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .blog-header {
      height: 70vh;
      background: url('../../../public/img/bg-blog-mobile.png') no-repeat bottom;
      background-size: 100%;
      &__title, &__text, &__date {
        max-width: 100%;
      }
    }
    .blog-header-inner {
      padding-top: 50px;
      padding-left: 30px;
      padding-right: 30px;
    }
    .blog-article {
      flex-direction: column;
      &-activity.desktop-activity {
        display: none;
       .blog-article-stats {
          justify-content: flex-start;
        }
        .blog-article-share {
          flex-direction: row;
          justify-content: flex-end;
          flex-grow: 1;
          .v-btn {
            margin-bottom: 0;
            margin-right: 10px;
          }
        }
      }
      &-text {
        width: 100%;
        min-width: 0;
        .text-btns {
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 80px;
          .tablet-activity {
            display: flex;
            align-items: flex-end;
            .blog-article-stats {
              margin-bottom: 0;
              margin-right: 30px;
              .likes {
                margin-right: 10px;
              }
            }
            .blog-article-share {
              &__title {
                align-self: flex-end;
              }
              .buttons {
                display: flex;
              }
              .v-btn {
                margin-bottom: 0;
                margin-right: 10px;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .page-content {
    .blog-header-inner {
      display: flex;
      flex-direction: column;
      height: 80vh;
      padding-top: 60px;
    }
    .blog-header {
      &__date {
        order: 1;
      }
      &__title {
        order: 2;
      }
      &__text {
        order: 3;
      }
      &__title, &__text {
        max-width: 100%;
      }
    }
    .blog-article {
      &-text {
        .text-btns {
          flex-direction: column;
          align-items: center;
          .v-btn {
            order: 2;
            align-self: center !important;
          }
          .tablet-activity {
            order: 1;
            flex-direction: column;
            align-items: center;
            .blog-article-stats {
              margin-right: 0;
              order: 1;
              margin-bottom: 30px;
            }
            .blog-article-share {
              align-items: center;
              order: 2;
              margin-bottom: 50px;
              &__title {
                align-self: center;
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
