<template>
  <ContentWrapper background="var(--main-bg-color)">
    <div class="preview-section-container">
      <div class="text">
        <HText class="text__subtitle" variant="h3">Мама 7 детей подскажет</HText>
        <HText variant="h1" class="text__title" :class="getDisplayedStyles">Дарья Хромова: все от зачатия – до
          университета
        </HText>
        <CustomButton
            @click.native="$vuetify.goTo('#about', scrollingOptions)"
            class="about-button"
            variant="filled"
            text="Об авторе"
        />
      </div>
      <div class="photos">
        <img src="/img/main-photo.png" alt=""/>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    scrollingOptions() {
      return {
        duration: 1000,
        easing: 'easeInOutCubic',
        offset: 110,
      }
    }
  }
};
</script>

<style lang="scss">

.preview-section-container {
  display: flex;
  padding: 74px 0;
  position: relative;
  justify-content: space-between;
  .text {
    display: flex;
    flex-direction: column;
    width: 35%;
    &__subtitle {
      margin-bottom: 25px;
    }
    &__title {
      margin-bottom: 55px;
    }
    .about-button {
      width: 130px;
    }
  }
  .photos {
    width: 50%;
    position: absolute;
    right: 0;
    z-index: 1;

    img {
      width: 100%;
    }
  }
}



@media screen and (max-width: 768px) {
  .preview-section-container {
    padding: 60px 0;
    .text {
      width: 45%;
      .about-button {
        width: 146px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .preview-section-container {
    padding: 40px 0;
    flex-direction: column;
    align-items: center;

    .text {
      width: 100%;
      align-items: center;
      &__title {
        text-align: center;
        max-width: 235px;
      }
      .about-button {
        width: 100%;
        margin-bottom: 40px;
      }
    }
    .photos {
      position: initial;
      width: 100%;
    }
  }
}
</style>
