<template>
  <Modal width="750" classes="auth-wrapper"
         ref="modal" @on-outside-click="()=>{}" @on-icon-close-click="close"
         :showed-close="true"
  >
    <HText :variant="$vuetify.breakpoint.width <= 768 ? 'h2' : 'h1'" class="auth-modal__title">
      {{ step === 'email' ? params.title ? params.title : 'Вход в личный кабинет' : 'Введите код подтверждения' }}
    </HText>
    <div class="auth-modal-label-wrapper">
      <CText class="auth-modal__label"
             :text="step === 'email' ? 'Введите ваш e-mail' : 'Код отправлен на почту ' + user.email"
      >
      </CText>
    </div>
      <LoginFields ref="login_fields" @on-logged-in="onLoggedIn"/>
    <CText
        style="margin-top: 20px"
        :text="`Нажимая 'Войти', вы соглашаетесь с условиями <a href='/privacy' target='_blank'>оферты</a>`"></CText>
  </Modal>
</template>

<script>
import Modal from "@/components/reusable/Modal";
import LoginFields from "@/components/reusable/LoginFields";
export default {
  name: "Auth",
  components: {
    Modal,
    LoginFields
  },
  data() {
    return {
      params: {},
      user: {},
      step: 'email',
      emailRule: () => {
        return true;
        // const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        // return pattern.test(value) || 'Некорректный e-mail.'
      },
      get_code_loading: false,
      login_loading: false,
      error_code: false,
      errorMsg_code: [],
      errorMsg_email: [],
    }
  },
  methods: {
    open(params = false) {
      if (params) this.params = params;
      this.$refs.modal.open();
      setTimeout(()=>{
        this.$refs.login_fields.onOpenModal();
      })
    },
    close() {
      this.$refs.modal.close();
    },
    onLoggedIn() {
      this.close();
    }
  },
}
</script>

<style lang="scss">
.auth-wrapper {
  padding: 50px 85px 60px;

  .auth-modal__title {
    margin-bottom: 20px;
  }

  .auth-modal-label-wrapper {
    margin-bottom: 70px;
    display: flex;
    align-items: flex-end;

    .auth-modal__label {
      margin-right: 31px;
      font-size: 16px;
      line-height: 110%;
    }
  }
}

@media screen and (max-width: 768px) {
  .auth-wrapper {
    padding: 315px 0 0;
    width: 280px;
    margin: 0 auto;

    .auth-modal__title {
      margin-bottom: 15px;
    }

    .auth-modal-label-wrapper {
      margin-bottom: 60px;
      flex-direction: column;
      align-items: flex-start;
    }

    .auth-form-wrapper {
      flex-direction: column;

      .input {
        margin-bottom: 8px;
        margin-right: 0;
      }
    }

  }
}

</style>
