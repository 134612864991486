<template>
  <ContentWrapper background="white">
  <div class="fifth-section-container">
		<div class="image--wrapper club-preview">
      <v-img src="/img/phone.png" alt="phone-frame" style="overflow: hidden">
        <div class="phone-frame">
          <div class="phone-frame_description">
            <HText variant="h2" class="phone-frame__title">Клуб "Искусство жить"</HText>
            <p class="phone-frame__text"><strong><span style="color:#6F8DA8">Вы хотите стать уверенной мамой, вдохновленной женой и наслаждаться своей Жизнью? Со мной вы сможете </span>генерировать
              счастье и Жить в достатке.</strong></p>
            <CustomButton
                variant="black"
                text="Выбрать модуль"
                class="choose-module-btn"
                to="/clubs/1"
                @click.native="$eventBus.$emit('goToClubModules')"
            />
            <p class="phone-frame__text">* Присоединиться можно к любому модулю</p>
          </div>
          <div class="phone-frame_chat">
            <HText variant="h3" class="">Кто состоит в чате?</HText>
            <v-img src="/img/circle.svg" width="200">
              <div class="v-image_wrapper">
                <img src="../../assets/demo/child-psychologist.png" alt="psycho" width="60">
              </div>
            </v-img>
          </div>
        </div>
      </v-img>
		</div>
		<div class="description">
			<HText class="description__title">Клубы</HText>
			<HText variant="h3" class="description__subheader">Сообщества на разные темы, где состоят родители и профессионалы</HText>
			<div class="description--def">
				<div class="description--item">
					<div class="divider"></div>
					<CText :text="'Задайте вопрос и получите ответ от профессиональных психологов и педагогов'"></CText>
				</div>
				<div class="description--item">
					<div class="divider"></div>
					<CText :text="'Узнайте, как другие родители справляются с похожей проблемой'"></CText>
				</div>
				<div class="description--item">
					<div class="divider"></div>
					<CText :text="'Поделитесь опытом с другими родителями – возможно, вы сможете помочь'"></CText>
				</div>
			</div>
      <CustomButton class="btn-more-courses" variant="filled" text="Подробнее" style="margin-top: 60px; width: 150px;"/>
		</div>
	</div>
  </ContentWrapper>
</template>

<script>
export default {
	components: {
	},
  methods: {
  },
};
</script>

<style lang="scss">
.fifth-section-container {
	background: #fff;
	display: flex;
  padding-top: 100px;
  .club-preview {
    margin-right: 185px;
    .phone-frame {
      height: 100%;
      padding: 58px 18px 0;

      &_description {
        padding: 0 20px;
      }

      &__title {
        margin-bottom: 50px;
      }

      &__text {
        margin-bottom: 50px;
        font-size: 14px;
        line-height: 18px;
      }

      .choose-module-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }

      &_chat {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        padding: 30px 20px 0;
        width: 100%;
        height: 100%;
        background-color: #f4ede5;

        .v-image {
          position: absolute;
          overflow: hidden;
          top: 80px;

          &_wrapper {
            position: relative;

            img {
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .description {
    &__title {
      margin-bottom: 20px;
    }
    &__subheader {
      margin-bottom: 50px;
    }
    &--def {
      display: flex;
      flex-wrap: wrap;
    }
    &--item {
      width: 220px;
      margin-right: 30px;
      &:nth-child(2) {
        margin-right: 0;
      }
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
}
.divider {
	background: #222222;
	opacity: 0.6;
	width: 200px;
	height: 1.1px;
	margin-bottom: 12px;
}

@media screen and (max-width: 768px) {
  .fifth-section-container {
    padding-top: 80px;
    .club-preview {
      order: 2;
      margin-right: 0;
    }
    .description {
      order: 1;
      margin-right: 65px;
      &__subheader {
        margin-bottom: 35px;
      }
      &--item {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .btn-more-courses {
      margin-top: 50px !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .fifth-section-container {
    flex-direction: column;
    padding-top: 60px;
    .description {
      margin-right: 0;
      &--item {
        margin-right: 0;
        width: 100%;
      }
    }
    .btn-more-courses {
      margin-bottom: 60px;
      width: 100% !important;
    }
  }
}
</style>
