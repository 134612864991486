<template>
  <ContentWrapper background="var(--main-bg-color)">
    <div class="not-found-page">
      <HText variant="h1" class="mb-8">Ошибка 404</HText>
      <HText variant="h2" class="mb-4">Упс... Такой страницы не существует</HText>
      <CustomButton variant="black" text="Вернуться на главную" @click.native="$router.replace('/')"/>
    </div>
  </ContentWrapper>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped lang="scss">
.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  flex-direction: column;
}
</style>