<template>
  <div class="tag-list">
    <div
        class="tag-list__item"
        v-for="(tag) in visibleTags" :key="tag.id" @click="tagOnClick(tag.id)"
        :class="{'tag-list__item--selected' : selectedTags.includes(tag.id)}"
    >
      # {{ tag.name }}
    </div>
<!--    <div class="tag-list__item tag-list__item&#45;&#45;button-show-all" @click="shownAll = !shownAll"-->
<!--    v-if="tags.length > shownNumber">-->
<!--      <span v-if="!shownAll">Показать все  •	•	•</span>-->
<!--      <span v-else>Скрыть</span>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "TagList",
  props: {
    tags: Array,
    shownNumber: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      // shownAll: false,
      selectedTags: this.$route.query.tags ? this.$route.query.tags : [],
    };
  },
  methods: {
    tagOnClick(tag) {
      if(this.selectedTags.includes(tag)) {
        this.selectedTags = this.selectedTags.filter((_tag) => tag !== _tag);
      } else {
        this.selectedTags = [...this.selectedTags, tag]
      }
      this.$router.push({path: '/videos', query: {tags: this.selectedTags}}).catch(error => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
      this.$emit('change', this.selectedTags)
    },
  },
  mounted() {
  },
  computed: {
    visibleTags() {
      if (!this.tags) return [];
      const tags = [...new Set(this.tags)];
      return tags
      // if (this.shownAll) return tags;
      // return tags.slice(0, this.shownNumber);
    },
  },
}
</script>

<style scoped lang="scss">
.tag-list {
  display: flex;
  flex-wrap: wrap;
  & > .tag-list__item {
    margin-right: 15px;
    margin-bottom: 17px;
  }
  .tag-list__item {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 2px 10px;
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    white-space: nowrap;
    cursor: pointer;
    &--selected {
      background: #08050C;
      color: #F2F2F2;
    }
    &--button-show-all {
      border: 1px solid #BFC9CA;
      background: transparent;
      box-sizing: content-box;
      padding-bottom: 0;
    }
  }
}
</style>
