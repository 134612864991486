<template>
  <Modal ref="modal" width="665" classes="consultation-wrapper" :showed-close="true"
         @on-outside-click="close" @on-icon-close-click="close"
  >
    <HText :variant="$vuetify.breakpoint.width < 600 ? 'h3' : 'h2'" class="consultation-modal__title">Запись на консультацию</HText>
    <AuthBeforeConsult v-if="!authorized" @userAuthorized="authorized = true"/>
    <FirstStepConsult @nextStep="step = 2" v-if="authorized && step === 1" :consultation="consultation" ref="first_step"/>
    <SecondStepConsult v-if="step === 2" :consultation="consultation" ref="second_step" @value-changed="onValueChanged"/>
    <div class="footer-wrapper" v-if="step === 2">
      <div class="total">
        <CText class="total__label"
               :text="'К оплате'"
        ></CText>
        <div class="total__sum">{{ $root.printCost(calculatedProps) }}</div>
      </div>
      <div class="button-container">
        <CustomButton variant="black" text="Оплатить и забронировать" class="btn-pay"
                      :loading="book_loading"
                      @click.native="bookConsult"/>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/reusable/Modal";
import AuthBeforeConsult from '@/views/consultation/Auth';
import FirstStepConsult from "@/views/consultation/FirstStep";
import SecondStepConsult from "@/views/consultation/SecondStep";
export default {
  name: "ConsultationModal",
  components: {
    Modal,
    FirstStepConsult,
    SecondStepConsult,
    AuthBeforeConsult
  },
  data() {
    return {
      authorized: !!this.$server.getToken(),
      consultation: {
        time: '',
      },
      calculatedProps: '',
      step: 1,
      book_loading: false,
      dataError: '',
    }
  },
  methods: {
    open() {
      this.consultation = {
        name: this.$store.state.user.name ? this.$store.state.user.name : '',
        phone: this.$store.state.user.phone ? this.$store.state.user.phone : '',
      }
      this.$refs.modal.open();
    },
    close() {
      this.consultation = {
        time: '',
      };
      this.calculatedProps = {
        sum: '0'
      };
      if ( this.step === 1 && this.authorized ) this.$refs.first_step.onModalClose();
      if ( this.step === 2 ) this.$refs.second_step.onModalClose();
      this.step = 1;
      this.book_loading = false;
      this.$refs.modal.close();
    },
    bookConsult() {
  /*    let now = this.$moment.now();
      if (!this.consultation.title) this.$refs.second_step.titleError = 'Укажите тему';
      if (!this.consultation.date) this.$refs.second_step.dateError = 'Укажите дату';
      if (!this.consultation.date) this.$refs.second_step.dateError = 'Укажите дату';

      if (this.consultation.date && this.$moment(now).subtract('days', 1).isAfter(this.$moment(this.consultation.date))) this.$refs.second_step.dateError = 'Выбранная дата не может быть в прошлом';

      if (this.consultation.time && this.consultation.date === this.$moment(now).format('YYYY-MM-DD')) {
        let selectedTimeInMinutes = Number(this.consultation.time.split(':')[0]) * 60;
        let currentTimeInMinutes = Number(this.$moment(now).hour()) * 60;

        if (selectedTimeInMinutes - currentTimeInMinutes <= 120 ) {
          this.$refs.second_step.timeError = 'Нельзя выбрать это время';
          return;
        }
      }

      if (!this.consultation.title || !this.consultation.date || this.$moment(this.$moment.now()).subtract('days', 1).isAfter(this.$moment(this.consultation.date))) return;
*/
      this.book_loading = true;
      this.$server.request('consultation/create', this.consultation, (data)=>{
        this.book_loading = false;
        this.$router.push({path: '/checkout', query: {type: 'consultation', item_id: data.response.id, title: data.response.title }})
        this.close();
      }, (data)=>{
        this.dataError = data.error;
        this.book_loading = false;
      })
    },
    onValueChanged(args) {
      if (args && Object.keys(args).length) {
        this.consultation[args.prop] = args.value;
      }
      this.dataError = '';
      this.$server.request('consultation/calculate', this.consultation, (data)=>{
        this.calculatedProps = data.response;
      })
    },
  },
  watch: {
    '$store.state.user.id': function () {
      this.consultation.name = this.$store.state.user.name;
      this.consultation.phone = this.$store.state.user.phone;
      this.onValueChanged();
    }
  }
}
</script>

<style lang="scss">
.consultation-wrapper {
  padding: 50px 85px 60px;
  width: 100%;
  margin: 0 auto;
}

.consultation-modal__title {
  margin-bottom: 20px;
}

.consultation-first-step-inner {
  .form-textfield {
    display: flex;
    .v-text-field {
      min-width: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.date-and-time-wrapper {
  display: flex;
  margin-bottom: 25px;

  .calendar-wrapper {
    width: 355px;
    min-width: 355px;
    margin-right: 65px;

    .v-card {
      background: transparent;
    }

    .v-picker__body {
      width: 100% !important;
      background: transparent;
      margin: 0;

      .v-date-picker-header {
        padding: 0;
        justify-content: flex-start;
        &__value {
          overflow: hidden;
          min-width: 125px;
          white-space: nowrap;
          flex: 0;
        }
      }

      .v-date-picker-table {
        background: #ffffff;
        border-radius: 10px;
        height: fit-content;

        .v-btn--active {
          border-radius: 0;
          &:before {
            background: #08050C;
            opacity: 1;
          }
        }
      }
    }
    .subscription-wrapper {
      display: flex;
      justify-content: space-between;
      .v-text-field {
        max-width: 150px;
        min-width: 0;
      }
    }
  }

  .time-wrapper {
    display: flex;
    flex-direction: column;

    .time {
      margin-bottom: 15px;
    }
  }
}

.footer-wrapper {
  display: flex;

  .total {
    margin-right: 70px;

    &__label {
      margin-bottom: 3px;
    }

    &__sum {
      font-weight: 600;
      font-size: 38px;
      line-height: 38px;
    }
  }

  .button-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .btn-pay {
    height: 60px;
    flex-grow: 1;
    align-self: flex-end;
  }
}


.picker-item {
  padding: 4px 16px;
  background: #FFFFFF;
  border-radius: 20px;
  color: #333333;
  cursor: pointer;

  &--selected {
    background: #08050C;
    color: #ffffff;
  }

  &--disabled {
    opacity: 0.1;
    border: 1px solid #000000;
    border-radius: 20px;
    background: transparent;
    color: #333333;
  }
}
@media screen and (max-width: 768px) {
  .consultation-wrapper {
    padding: 162px 142px 0 136px;
  }
}
@media screen and (max-width: 600px) {
   .consultation-wrapper {
    width: 100%;
    padding: 50px 20px 80px !important;
    .consultation-modal__title {
      max-width: 240px;
    }
     .consultation-first-step-inner {
       .form-textfield {
         display: flex;
         flex-direction: column;
         .v-text-field {
           margin-right: 0;
           margin-bottom: 8px;
         }
       }
     }
    .date-and-time-wrapper {
      flex-direction: column;
      margin-bottom: 45px;
      .calendar-wrapper {
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;
        min-width: 0;
      }
      .time-wrapper {
        flex-direction: row;
        flex-wrap: wrap;
        .picker-item {
          margin-right: 10px;
          width: fit-content;
        }
      }
      .v-picker__body {
        width: 100%;
      }
      .v-date-picker-header {
        margin-bottom: 15px;
      }
    }
    .footer-wrapper {
      flex-direction: column;
      align-items: flex-start;
      .total {
        margin-bottom: 20px;
      }
      .btn-pay {
        align-self: flex-start;
      }
    }
  }
}
</style>
