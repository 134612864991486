<template>
  <ContentWrapper>
    <div v-if="consultations.length" class="account-consultations-wrapper">
      <div class="consultation" v-for="consultation in consultations" :key="consultation.id">
        <v-row class="consultation-inner">
          <v-col :cols="$vuetify.breakpoint.width < 768 ? 12 : 5">
            <div class="consultation-title">Тема: <strong>{{consultation.title}}</strong></div>
            <div class="consultation-date-time">{{ $moment(consultation.date).format('DD.MM.YYYY') + ' в ' + consultation.time}}</div>
          </v-col>
          <v-col>
            <div class="consultation-sum">{{ $root.printCost(consultation.price)}}</div>
          </v-col>
          <v-col>
            <div v-if="consultation.payed*1" class="consultation-is-payed">
              <v-icon color="green">mdi-check</v-icon>Оплачено
            </div>
            <CustomButton v-else @click.native="openBuyModal(consultation)" text="Оплатить" variant="black"/>
          </v-col>
        </v-row>
      </div>
    </div>
    <div
        v-else
        class="account-consultations-wrapper empty">
      <CText text="Пока нет ни одной консультации" class="mb-2"></CText>
      <CustomButton @click.native="openConsultModal" text="Записаться на консультацию" variant="black" style="padding: 3px 12px; margin-right: 14px"/>
    </div>
    <ConsultationModal ref="consultationModal"/>
  </ContentWrapper>
</template>

<script>
import ConsultationModal from "@/views/consultation/ConsultationModal";
import Buy from "@/modals/Buy";
export default {
  name: "MyConsultations",
  components: {Buy, ConsultationModal},
  data() {
    return {
      consultations: [
        {id: 1, title: 'Беременность', date: '2022-05-28', time: '12:00', sum: '1000', isPayed: 1},
        {id: 2, title: 'Беременность', date: '2022-05-28', time: '12:00', sum: '1000', isPayed: 0}
      ]
    }
  },
  methods: {
    openConsultModal() {
      if (!this.$store.state.user.id) this.openAuthModal()
      else this.$refs.consultationModal.open();
    },
    get() {
      this.$server.request('consultation/get', {}, (data)=>{
        this.consultations = data.response
      })
    },
    openBuyModal(consult) {
      this.$router.push({path: '/checkout', query: {type: 'consultation', item_id: consult.id, title: consult.title}});
    }
  },
  created() {
    this.$eventBus.$on('consultation-bought', ()=>{
      this.get();
    })
  },
  mounted() {
    this.get();
  }
}
</script>

<style scoped lang="scss">
.account-consultations-wrapper {
  .consultation {
    display: flex;
    max-width: 500px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 18px 24px;
    align-items: center;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    &-title {
      margin-bottom: 12px;
    }
    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 768px) {
  .account-consultations-wrapper {
    .consultation {
      .consultation-inner {
        flex-direction: column;
      }
    }
  }
}
</style>