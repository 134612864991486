<template>
  <div class="content-container" :class="getDisplayedStyles" :style="'background:' + background">
    <div class="content-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentWrapper",
  props: ['background']
}
</script>

<style scoped lang="scss">
.content-container {
  width: 100%;
}
.content-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px){
  .content-wrapper {
    max-width: 768px;
  }
}
@media screen and (max-width: 768px){
  .content-wrapper {
    padding: 0 30px;
  }
}
@media screen and (max-width: 390px){
  .content-wrapper {
    padding: 0 20px;
  }
}

.page-title {
  display: flex;
  margin-bottom: 17px;

  &__subtitle {
    margin-left: 10px;
    font-size: 14px;
    line-height: 20px;
  }
}

.page-header {
  .searchbar {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 10px;
    }

    &__input {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.page-header-row {
  display: flex;
  justify-content: space-between;
}

.page-header--tag-list {
  margin-top: 60px;
}

</style>
