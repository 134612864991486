<template>
  <Modal width="450" classes="modal-wrapper prompt-modal" :showed-close="true"
         ref="modal" @on-outside-click="close" @on-icon-close-click="close"
  >
    <div class="icon-wrapper">
      <HText variant="h2" class="modal__title mb-0">{{ title }}</HText>
      <v-icon size="60">{{
          type === 'success' ? 'mdi-check-circle-outline' :
              type === 'error' ? 'mdi-close-circle-outline' :
                  'mdi-alert-circle-outline'
        }}
      </v-icon>
    </div>
    <HText :variant="$vuetify.breakpoint.width <= 768 ? 'h3' : 'h2'" class="modal__title">{{ subtitle }}</HText>
    <CustomButton
        text="Закрыть"
        variant="black"
        class="buy-btn"
        @click.native="close"
    />
  </Modal>
</template>

<script>
import Modal from "@/components/reusable/Modal";

export default {
  name: "Prompt",
  components: {
    Modal,
  },
  data() {
    return {
      type: '',
      title: '',
      subtitle: '',
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    open(params) {
      this.title = params.title
      if (params.subtitle) this.subtitle = params.subtitle
      this.type = params.type
      if (this.type === 'success') setTimeout(()=>{
       this.close();
      }, 2500)
      this.$refs.modal.open();
    }
  }
}
</script>

<style lang="scss">
.icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  align-items: center;
  .modal__title {
    margin-right: 12px;
  }
}
.prompt-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .v-btn {
    min-width: 0;
    width: fit-content;
  }
}
</style>
