<template>
  <Modal width="fit-content" style="width: fit-content"
         ref="modal" @on-outside-click="close" @on-icon-close-click="close"
         classes="modal-wrapper buy-modal-wrapper"
         :showed-close="true">
    <HText :variant="$vuetify.breakpoint.width <= 768 ? 'h2' : 'h1'" class="modal__title">Оплата</HText>
    <p v-if="!paytureLink">
      Оплата по карте временно не доступна. Для оплаты и получения доступа, напишите в
      <a target="_blank" href="https://t.me/+hBebIzpEpOtjMWUy">телеграм чат</a>
    </p>
    <iframe v-else :src="paytureLink" frameborder="0" allowpaymentrequest/>
  </Modal>
</template>

<script>
import Modal from "@/components/reusable/Modal";

export default {
  name: "Buy",
  components: {
    Modal,
  },
  data() {
    return {
      paytureLink: '',
      type: '',
      order_id: '',
    }
  },
  methods: {
    paymentCheck(order_id) {
      this.$server.request('user/checkPayment', {order_id: order_id}, () => {
        this.$eventBus.$emit('buy_course', {title: 'Оплата прошла успешно', type: 'success'});
        this.$router.push(`/account/${this.type}s`);
        // ym(88857124,'reachGoal','pay');
        // ClickApi.track('pay');
      }, (data) => {
        if (data.waiting) this.paymentCheck(order_id)
      }, false)
    },
    close(frame_msg) {
      if (frame_msg === 'iframe_finished') this.paymentCheck(this.order_id);
      this.$refs.modal.close();
    },
    open(params) {
      this.paytureLink = '';
      this.type = '';
      this.order_id = '';
      this.paytureLink = params.paytureLink;
      this.type = params.type;

      this.$refs.modal.open();
    }
  },
  mounted() {
    window.addEventListener('message', (e) => {
      if (e.data === 'iframe_finished') {
        this.close('iframe_finished')
      }
    })
  }
}
</script>

<style lang="scss">
.v-application .dialog-wrapper .modal-wrapper.buy-modal-wrapper {
  background: #f0f0f0fe;
  padding: 50px 35px 60px;

  iframe {
    height: 520px;
    width: 400px;
  }
}

@media screen and (max-width: 600px) {
  .v-application .dialog-wrapper .modal-wrapper.buy-modal-wrapper {
    iframe {
      width: 100%;
    }
  }
}
</style>
