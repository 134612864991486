<template>
  <div class="tags">
    <div
        class="tag-list__item"
        v-for="tag in tags" :key="tag.id"
    >
      # {{ tag.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Tags",
  props: ['tags']
}
</script>

<style scoped lang="scss">
.tags {
  display: flex;

  .tag-list__item {
    margin-right: 15px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 2px 10px;
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    white-space: nowrap;
    &:last-child {
      margin-right: 0;
    }
  }
}
@media screen and (max-width: 600px) {
  .tags {
    flex-wrap: wrap;
    .tag-list__item {
      margin-bottom: 10px;
    }
  }
}
</style>
