<template>
  <router-link :to="to" class="article-list-item">
    <img :src="media" alt="article media" class="article-list-item__media">
    <div class="article-list-item__content">
      <div class="article-list-item__tags" v-if="tags">
        <div
            class="article-list-item__tags-item"
            v-for="tag in tags"
            :key="tag.id"
        >
          #{{ tag.name }}
        </div>
      </div>
      <div class="article-list-item__inner">
        <div class="article-list-item__title mr-2">{{ title }}</div>
        <div class="article-list-item__after" v-if="price !== '0' && (hasAccess === 0)">
          <template v-if="!(+price_full)">{{ $root.printCost(price) }}</template>
          <template v-else><NewYearIcon style="margin-left: 43px;
    margin-top: -24px;"/><span style="color: green">{{ $root.printCost(price) }}</span><span
              style="text-decoration: line-through;font-size: 12px;margin-left: 10px">{{
              $root.printCost(price_full)
            }}</span>
          </template>
        </div>
        <div v-else-if="price !== '0'&& hasAccess === 1" class="article-list-item__after">
          <v-icon color="green" size="12" class="mr-1">mdi-check</v-icon>
          <span style="font-size: 12px">Оплачено</span>
        </div>
        <div class="article-list-item__after" v-else-if="!(+course)">Бесплатно</div>
      </div>
      <div class="article-list-item__text" v-if="text" v-html="text"></div>
    </div>
  </router-link>
</template>

<script>
import NewYearIcon from "./NewYearIcon";
export default {
  name: "ArticleListItem",
  components: {NewYearIcon},
  props: {
    title: String,
    media: String,
    text: String,
    course: String,
    price: String,
    price_full: String,
    tags: Array,
    to: String,
    hasAccess: Number
  }
}
</script>

<style lang="scss">
.article-list-item, .videos-section__list .v-skeleton-loader, .courses-block__items .v-skeleton-loader {
  min-height: 300px;
  background: #FFFFFF;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  box-shadow: none !important;

  &__media, .v-skeleton-loader__image {
    width: 100%;
    height: 150px;
    flex-shrink: 0;
    object-fit: cover;
  }

  &__content, .v-skeleton-loader__card-heading {
    flex: 1;
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  &__tags {
    position: absolute;
    top: 0;
    transform: translate(0, -100%);
    display: flex;
    flex-wrap: wrap;

    & > .article-list-item__tags-item {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  &__tags-item {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 2px 10px;
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    white-space: nowrap;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5px;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  &__after {
    border: 1px solid rgba(0, 0, 0, .2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 2px 8px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 160%;
  }

  &__text {
    font-size: 14px;
    line-height: 160%;
    max-height: 84px;
    overflow: hidden;
  }

  &.swiper-slide {
    transition: .2s;

    &--outside {
      opacity: 0.2;
    }
  }
}

@media screen and (max-width: 600px) {
  .article-list-item, .videos-section__list .v-skeleton-loader {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
</style>
