<template>
    <div class="videos-section">
      <div class="videos-section__header" :style="!showArrow ? 'display: none' : ''">
        <div class="videos-section__title">{{ title }}</div>
        <div class="videos-section__arrow-right">→</div>
      </div>
      <div v-if="items.length > 0" class="videos-section__list">
        <ArticleListItem
            class="videos-section__list-item"
            v-for="(item, index) in items"
            :title="item.title"
            :course="item.course"
            :media="item.preview"
            :text="item.description"
            :price="item.price"
            :price_full="item.price_full"
            :has-access="item.hasAccess*1"
            :tags="item.tags"
            :key="index"
            :to="`/videos/${item.id}`"
        />
      </div>
      <div v-else class="videos-section__list">
        <v-skeleton-loader type="card" elevation="2" class="videos-section__list-item article-list-item" v-for="i in $vuetify.breakpoint.width < 600 ? 3 : 6" :key="i"/>
      </div>
      </div>
</template>

<script>
import ArticleListItem from "@/components/reusable/ArticleListItem";

export default {
  name: "VideosSection",
  components: {ArticleListItem},
  props: {
    title: String,
    items: Array,
    enabledDesktop: Boolean,
    showArrow: Boolean
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.videos-section-wrapper {
  padding-bottom: 0;
}

.videos-section {
  overflow: visible;
  padding-bottom: 40px;
  &__header {
    display: flex;
    margin-bottom: 35px;
  }

  &__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
  }

  &__arrow-right {
    margin-left: 10px;
    background: #FFFFFF;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3,minmax(320px,1fr));
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  &__list-item {
    height: 300px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 1024px) {
    &__list {
      grid-template-columns: repeat(3,minmax(256px,1fr));
    }
  }

  @media (max-width: 768px) {
    &__list {
      grid-template-columns: repeat(2,minmax(256px,1fr));
    }
  }
  @media (max-width: 440px) {
    &__list {
      grid-template-columns: repeat(1,minmax(256px,1fr));
      grid-column-gap: 0;
    }
  }
}
</style>
