<template>
    <div class='header' v-bind:style='styles' v-bind:class='[getDisplayedStyles, getVariant]'>
      {{this.$vnode.componentOptions.children[0].text}}
    </div>
</template>

<script>
export default {
    props: ['styles', 'variant'],
    computed: {
        getVariant: function() {
            switch(this.$props.variant) {
                case 'h1': return 'h1'
                case 'h2': return 'h2'
                case 'h3': return 'h3'
                default: return 'h1'
            }
        }
    }
}
</script>

<style scoped>
.header {
    color: #222222;
}
.header.h1 {
    font-size: 38px;
    line-height: 40px;
    font-weight: 600;
}
.header.h2 {
    font-size: 22px;
    line-height: 29px;
    font-weight: 600;
}
.header.h3 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
}

@media screen and (max-width: 375px) {
  .header.h1 {
    font-size: 26px;
    line-height: 29px;
    font-weight: 600;
  }
  .header.h2 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }
  .header.h3 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }
}


</style>
