<template>
  <div class="page-content">
    <ContentWrapper background="var(--bg-color-blue)">
      <div class="video-section">
        <Back text="Назад" :to=" video.course*1 !== 0 ? `/courses/${video.course.id}` : '/videos'"/>
        <HText v-if="videoLoaded" variant="h1" class="video-section__title">{{ video.title }}</HText>
        <v-skeleton-loader v-else type="text" height="38" width="260" style="margin-bottom: 22px"/>
        <div class="video-container">
          <div class="video-container-header">
            <Tags v-if="video.tags && videoLoaded" :tags="video.tags"/>
            <div v-else class="tags-skeleton-container mt-0">
              <v-skeleton-loader class="skeleton-tag mb-0" type="button" height="22" width="75" v-for="i in 2"
                                 :key="i"/>
            </div>
            <div v-if="videoLoaded" class="video-stat">
              <div class="video-stat__price" v-if="!(video.course.id*1)">
                <p v-if="!(+video.price_full)">{{ video.price === '0' ? 'Бесплатно' : video.price + ' руб.' }}</p>
                <p v-else>
                  <NewYearIcon style="margin-top: -30px;margin-left: 20px"/>
                  <span style="color: green">{{ $root.printCost(video.price) }}</span>
                  <span style="text-decoration: line-through;font-size: 12px;margin-left: 10px">{{
                      $root.printCost(video.price_full)
                    }}</span>
                </p>
              </div>
              <p class="video-stat__published-and-views" v-if="video.published || video.views">{{ video.published }} •
                {{ video.views }} просмотров</p>
            </div>
            <div v-else class="video-stat">
              <v-skeleton-loader type="text" height="14" :width="$vuetify.breakpoint.width < 600 ? 70 : 200"/>
            </div>
          </div>
          <div class="video-container-content">
            <div v-if="video.hasAccess === 0" class="video-container-actions">
              <CustomButton
                  v-if="video.hasAccess === 0 && video.course*1 !== 0 && video.course.price*1 > 0"
                  :text="!(+video.course.price_full) ?  `Купить курс за ${$root.printCost(video.course.price)}` :
                   `Купить курс за
                   <span style='color: white; margin-left: 5px'>${$root.printCost(video.course.price)}</span>
                   <span style='text-decoration: line-through;font-size: 12px;margin-left: 10px; color: gray'>${$root.printCost(video.course.price_full)}</span>
                   `
"
                  variant="black"
                  class="buy-btn"
                  @click.native="openBuyModal(video.course, 'course')"
              />
              <CustomButton
                  v-if="video.hasAccess === 0  && video.price*1 > 0"
                  :text="!(+video.price_full) ?  `Купить видео-лекцию за ${$root.printCost(video.price)}` :
                   `Купить видео-лекцию за
                   <span style='color: white; margin-left: 5px'>${$root.printCost(video.price)}</span>
                   <span style='text-decoration: line-through;font-size: 12px;margin-left: 10px; color: gray'>${$root.printCost(video.price_full)}</span>
                   `
"
                  variant="black"
                  class="buy-btn"
                  @click.native="openBuyModal(video, 'video')"
              />
              <CustomButton
                  v-if="video.hasAccess === 0  && video.price*1 === 0 && !$store.state.user.id && (video.course.price*1 === 0 || video.course*1 === 0)"
                  text="Войти"
                  variant="black"
                  class="buy-btn"
                  @click.native="$eventBus.$emit('open_auth_modal', {type: 'from_video'})"
              />
            </div>
            <video
                class="video"
                ref="video"
                :src="src"
                preload="metadata"
                frameborder="0"
                controls
                :poster="src ? '':video.preview ? video.preview : video.course.preview"
            ></video>
          </div>
          <CourseProgram v-if="video.course.id" :course="video.course" :current="video.id" :video-loaded="videoLoaded"/>
          <div class="video-section-steps" v-if="video.task">
            <div class="step">
              <div class="step-number">
                <CText :text="1"></CText>
              </div>
              <CText class="step-title" :text="'Посмотрите видео'"></CText>
            </div>
            <div class="step">
              <div class="step-number">
                <CText :text="2"></CText>
              </div>
              <CText class="step-title" :text="'Выполните задание, написав ответ в комментариях'"></CText>
            </div>
            <div class="step">
              <div class="step-number">
                <CText :text="3"></CText>
              </div>
              <CText class="step-title" :text="'Поставьте галочку “Выполняю задание”'"></CText>
            </div>
          </div>
          <div class="video-container-task" v-if="video.description">
            <div class="video-container-task__desc">
              <div class="video-container-task__title">Описание</div>
              <div class="text-container">
                <CText class="video-container-task__text"
                       :text="moreDesc ? video.description.slice(0, 225) : video.description"></CText>
                <div class="show-full" v-if="video.description.length > 225" @click="moreDesc = !moreDesc">
                  {{ moreDesc ? 'Показать полностью' : 'Свернуть' }}
                </div>
              </div>
            </div>
            <div class="video-container-task__task" v-if="video.task">
              <div class="video-container-task__title">Задание</div>
              <div class="text-container">
                <p class="video-container-task__text">{{ moreTask ? video.task.slice(0, 225) : video.task }}</p>
                <div class="show-full" v-if="video.task.length > 225" @click="moreTask = !moreTask">
                  {{ moreTask ? 'Показать полностью' : 'Свернуть' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
    <ContentWrapper background="var(--main-bg-color)">
      <div class="comments-section">
        <div class="also-see mb-7">
          <HText variant="h3" class="also-see__title">С этим видео смотрят</HText>
          <div v-if="alsoVideos.length > 0" class="also-see-content">
            <ArticleListItem
                class="also-see__clipcard"
                v-for="(item, i) in alsoVideos"
                :key="i"
                :title="item.title"
                :course="item.course"
                :media="item.preview"
                :text="item.description"
                :price_full="item.price_full"
                :price="item.price"
                :tags="item.tags"
                :has-access="item.hasAccess*1"
                :to="`/videos/${item.id}`"
            />
          </div>
          <div v-else-if="!videoLoaded" class="also-see-content">
            <v-skeleton-loader style="box-shadow: none !important; border-radius: 20px" type="card" elevation="2"
                               class="clipcard--wrapper" v-for="i in 3" :key="i"/>
          </div>
          <CustomButton
              text="Все видео-лекции"
              variant="black"
              class="buy-btn"
              to="/videos"
              style="color: #FFFFFF; background: #000000 !important;"
          />
        </div>
        <div v-if="!video.course.id" class="also-see also-see-courses">
          <HText variant="h3" class="also-see__title">Узнайте больше из курсов</HText>
          <div v-if="alsoCourses.length > 0" class="also-see-content">
            <ArticleListItem
                :to="`/courses/${item.id}`"
                v-for="(item, index) in alsoCourses"
                :title="item.title"
                :media="item.preview"
                :text="item.description"
                :price="item.price"
                :price_full="item.price_full"
                :tags="item.tags"
                :has-access="item.hasAccess*1"
                :key="index"
                class="also-see__clipcard"
            />
          </div>
          <div v-else-if="!videoLoaded" class="also-see-content">
            <v-skeleton-loader style="box-shadow: none !important; border-radius: 20px" type="card" elevation="2"
                               class="article-list-item" v-for="i in 2" :key="i"/>
          </div>
          <CustomButton
              text="Все курсы"
              variant="black"
              class="buy-btn"
              to="/courses"
              style="color: #FFFFFF"
          />
        </div>
      </div>
    </ContentWrapper>
    <Buy ref="buy_modal"/>
  </div>
</template>

<script>
import ContentWrapper from "@/components/reusable/ContentWrapper";
import HText from "@/components/reusable/HText";
import Back from "@/components/reusable/Back";
import Tags from "@/components/reusable/Tags";
import CourseProgram from "../../components/reusable/CourseProgram";
import Buy from "../../modals/Buy";
import ArticleListItem from "@/components/reusable/ArticleListItem";
import NewYearIcon from "../../components/reusable/NewYearIcon";

export default {
  name: "Video",
  components: {
    NewYearIcon,
    Buy,
    CourseProgram,
    ContentWrapper,
    HText,
    Back,
    Tags,
    ArticleListItem,
  },
  data() {
    return {
      video: {course: {videos: []}},
      comments: [],
      src: '',
      alsoVideos: [],
      alsoCourses: [],
      moreDesc: true,
      moreTask: true,
      interval: null,
      videoLoaded: false,
    }
  },
  methods: {
    getVideo() {
      this.$server.request(`video/get/${this.$route.params.id}`, {}, (data) => {
        this.video = data.response
        this.comments = data.comments
        this.alsoVideos = data.videos
        this.alsoCourses = data.courses
        document.title = this.video.title
        this.videoLoaded = true;
        if (this.video.hasAccess) {
          this.$server.request(`video/getLink/${this.$route.params.id}`, {}, (videoToken) => {
            this.src = this.$server.url + `video/src/${this.$route.params.id}?video_token=${videoToken.response}&token=${this.$server.getToken()}`
            let video = this.$refs.video
            video.currentTime = this.video.seconds
            if (this.$store.state.user.id) this.setVideoSec()
          })
        }
      })
    },
    setVideoSec() {
      let video = this.$refs.video
      this.interval = setInterval(() => {
        this.$server.request(`video/setSeconds/${this.$route.params.id}`, {seconds: video.currentTime.toFixed(0)}, null, null, false)
      }, 10000)
    },
    openBuyModal(item, type) {
      this.$router.push({path: '/checkout', query: {type: type, item_id: item.id, title: item.title }})
    }
  },
  mounted() {
    this.getVideo()
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.$eventBus.$off('get_video')
  },
  created() {
    this.$eventBus.$on('get_video', () => {
      this.getVideo()
    })
    this.$eventBus.$on('buy_course', (data) => {
      this.$eventBus.$emit('open_prompt_modal', data)
      if (data.type === 'success') this.getVideo()
    })
  },
  watch: {
    '$route.params.id': function () {
      this.getVideo();
    }
  }
}
</script>

<style scoped lang="scss">
.video-section {
  position: relative;
  padding: 114px 0 72px;

  &__title {
    margin-bottom: 22px;
  }

  &__text {
    max-width: 580px;
    margin-bottom: 50px;
  }

  &-steps {
    display: flex;
    margin-bottom: 65px;

    .step {
      display: flex;
      margin-right: 50px;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      &-number {
        background: #D8E1E9;
        border-radius: 50%;
        width: 34px;
        min-width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
      }

      &-title {

      }
    }
  }

  .video-container {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 37px;

      .tags-skeleton-container {
        align-self: flex-start;
      }

      .video-stat {
        p {
          text-align: right;
          margin-bottom: 0;
        }

        &__price {
          margin-bottom: 5px;
        }

        &__published-and-views {
          color: #6F8DA8;
        }
      }
    }

    &-actions {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 20px;
      z-index: 1;

      .v-btn {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 50px;

      .play-icon {
        position: absolute;
      }

      .video {
        width: 1000px;
        height: 570px;
        border-radius: 20px;
      }
    }

    &-task {
      &__desc, &__task {
        display: flex;
        margin-bottom: 35px;

      }

      .show-full {
        font-size: 14px;
        line-height: 20px;
        margin-top: 16px;
        color: #6F8DA8;
        text-decoration: underline;
      }

      &__title {
        min-width: 85px;
        letter-spacing: 0.12em !important;
        text-transform: uppercase;
        margin-right: 41px;
        font-size: 12px !important;
        line-height: 120%;
        font-weight: normal;
      }
    }
  }
}

.comments-section {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}

.also-see__title {
  margin-bottom: 30px;
}

.page-content .also-see {
  display: flex;
  flex-direction: column;

  .v-btn {
    align-self: center;
  }
}

.page-content .also-see-courses {
  .also-see-content {
    display: grid;
    grid-template-columns: repeat(2, minmax(495px, 1fr));
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }
}

.page-content .also-see-content {
  display: grid;
  grid-template-columns: repeat(3, minmax(320px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: 16px;
}

@media screen and (max-width: 1024px) {
  .video-section {
    &-steps {
      flex-wrap: wrap;

      .step {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .video-container-content {
      .video {
        width: 768px;
      }
    }
  }
  .page-content .also-see-content {
    grid-template-columns: repeat(3, minmax(256px, 1fr));
  }
  .page-content .also-see-courses {
    .also-see-content {
      display: grid;
      grid-template-columns: repeat(2, minmax(320px, 1fr));
      grid-column-gap: 8px;
      grid-row-gap: 8px;
    }
  }
}

@media screen and (max-width: 768px) {
  .video-section {
    &__title {
      margin-bottom: 30px;
    }

    &__text {
      margin-bottom: 30px !important;
    }

    .video-container-content {
      .video {
        width: 100%;
      }
    }
  }
  .comments-section {
    flex-direction: column;
  }
  .page-content .also-see-content {
    grid-template-columns: repeat(2, minmax(320px, 1fr));
  }
}

@media screen and (max-width: 600px) {
  .video-section {
    padding-top: 40px;

    &-steps {
      margin-bottom: 35px;
    }

    &__text {
      margin-bottom: 20px !important;
    }

    .video-container {
      &-header {
        flex-direction: column;

        .tags {
          align-self: flex-start;
        }
      }

      &-actions {
        flex-direction: column;

        .v-btn {
          margin-bottom: 12px;
          margin-right: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &-content {
        .video {
          height: 180px;
        }
      }

      &-task {
        &__title {
          margin-bottom: 20px;
        }

        &__desc, &__task {
          display: flex;
          flex-direction: column;
        }

        &__desc {
          margin-bottom: 30px;
        }
      }
    }
  }

  .comments-section {
    width: 100%;
  }
  .page-content .also-see-content {
    grid-template-columns: repeat(1, minmax(256px, 1fr));
    grid-column-gap: 0
  }
  .page-content .also-see-courses {
    .also-see-content {
      display: grid;
      grid-template-columns: repeat(1, minmax(280px, 1fr));
      grid-column-gap: 8px;
      grid-row-gap: 8px;
    }
  }
}
</style>
