<template>
  <v-app>
    <component :is="$route.meta.layout" v-if="$route.meta.layout">
      <router-view/>
    </component>
    <router-view v-else/>
  </v-app>
</template>

<script>
import '@/styles/main.scss';
export default {
  name: 'App',
  components: {
  },

  computed: {
  },
  methods: {
  },
  async mounted() {
    window.app = this;
  },
  created() {

  }
}
</script>
