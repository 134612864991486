<template>
  <ContentWrapper background="var(--bg-color-blue)">
    <div class="sixth-section-container">
      <HText class="page--title mb-6">Отзывы</HText>
<!--      <span class="subtitle">Все отзывы настоящие – их написано уже {{reviewsCount}}</span>-->
      <ReviewsSection v-if="inView" :reviews="reviews" :video-reviews="$store.state.allReviews" class="reviews-container"/>
      <div class="actions">
<!--        <CustomButton variant="white" text="Оставить отзыв" @click.native="createReview"/>-->
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import ReviewsSection from "@/components/reusable/ReviewsSection";

export default {
  components: {ReviewsSection,},
  data() {
    return {
      reviews: [],
      reviewsCount: null,
      inView: false
    }
  },
  methods: {
    getReviews() {
      // this.$server.request('feedback/get', {count: 5}, (data)=>{
      //   this.reviews = data.response;
      //   this.reviewsCount = data.count
      // })
    },
    createReview() {
      this.$eventBus.$emit('open_create_review_modal')
    },
    handleScroll() {
      if (this.isInViewPort(document.querySelector('.sixth-section-container .page--title'))) this.inView = true
    },
    isInViewPort(element) {
      let bounding = element.getBoundingClientRect();
      return bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    }
  },
  mounted() {
    this.getReviews()
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
};
</script>

<style scoped lang="scss">
.sixth-section-container {
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .reviews-container {
    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }
  }
}

.subtitle {
  margin-top: 12px;
  margin-bottom: 50px;
  text-align: center;
}

.page--title {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .sixth-section-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .sixth-section-container {
    padding-top: 60px;
    padding-bottom: 110px;
  }
  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .v-btn {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

</style>
