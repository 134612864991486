<template>
  <div class="reviews-wrapper">
    <div class="reviews">
      <!--      <Feedback-->
      <!--          v-for="(review, i) in reviews"-->
      <!--          :key="i"-->
      <!--          :username="review.title"-->
      <!--          :message="review.text"-->
      <!--          :date="$moment(review.date).fromNow()"-->
      <!--      />-->
      <div class="video-review" v-for="(review, i) in slicedVideoReviews" :key="i">
        <div class="video-container">
          <iframe width="100%" allowfullscreen height="315" :src="review.src" frameborder="0"></iframe>
        </div>
        <div class="video-review-quote">{{ review.quote }}</div>
      </div>
    </div>
    <CustomButton class="mb-6" v-if="slicedVideoReviews.length < videoReviews.length"  variant="black" text="Показать ещё отзывы" style="color: #FFFFFF" @click.native="showMore"/>
  </div>
</template>
<script>
// import Feedback from "@/components/reusable/Feedback";

export default {
  name: "ReviewsSection",
  components: {
    // Feedback,
  },
  props: ['reviews', 'videoReviews'],
  data() {
    return {
      increment: 6,
    }
  },
  methods: {
    showMore() {
      if (this.increment < this.videoReviews.length)  this.increment = this.increment + 6;
      this.slicedVideoReviews = this.videoReviews.slice(0, this.increment)
    }
  },
  computed: {
    slicedVideoReviews: {
      get() {
        return this.videoReviews.slice(0, this.increment)
      },
      set(increasedVideos) {
        return increasedVideos;
      }
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.reviews-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .v-btn {
    min-width: 0;
    width: 250px;
  }
  .reviews {
    display: grid;
    grid-template-columns: repeat(2, minmax(315px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    .video-review {
      margin-bottom: 32px;

      .video-container {
        margin-bottom: 12px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .reviews-wrapper {
    .reviews {
      grid-template-columns: repeat(1, minmax(280px, 1fr));
    }
  }
}
</style>
