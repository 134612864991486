<template>
  <div class="page-content">
    <ContentWrapper class="pt-15">
      <HText :variant="$vuetify.breakpoint.width <= 768 ? 'h2' : 'h1'" class="mb-10">Создать заказ</HText>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.width <= 768 ? 12 : 5">
          <v-text-field
              v-model="order.title"
              class="input mr-0 mb-4"
              label="Тема"
              outlined
              hide-details="auto"
          />
          <v-text-field
              v-model="order.price"
              class="input mr-0 mb-6"
              label="Стоимость"
              outlined
              hide-details="auto"
              v-mask="['######']"
          />
          <CustomButton @click.native="create" text="Создать" variant="black"
                        :loading="loading"/>
        </v-col>
        <v-col v-if="link">
          <div class="order-link mb-4">
            Ссылка на заказ: <a :href="link">{{ link }}</a>
          </div>
          <CustomButton @click.native="copy" :text="copied ? 'Cкопировано' : 'Скопировать'" variant="white"/>
        </v-col>
      </v-row>
    </ContentWrapper>
  </div>
</template>

<script>
export default {
  name: "OrderCreate",
  data() {
    return {
      order: {},
      link: '',
      loading: false,
      copied: false,
    }
  },
  methods: {
    create() {
      this.loading = true;
      this.$server.request('order/create', this.order, (data) => {
        this.link = `https://new.mama7d.ru/checkout/${data.response.uuid}`;
        this.loading = false;
      }, () => {
        this.loading = true;
      });
    },
    copy() {
      navigator.clipboard.writeText(this.link);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    }
  }
}
</script>

<style scoped>

</style>