<template>
  <ContentWrapper>
    <div class="account-videos-wrapper">
      <div class="account-videos-header">
        <TagList :tags="tags" class="page-header--tag-list"/>
<!--        <div class="searchbar">-->
<!--          <img src="/img/search.svg" class="searchbar__icon" alt="search icon">-->
<!--          <input type="text" placeholder="Поиск" class="searchbar__input">-->
<!--        </div>-->
      </div>
      <div class="account-videos-content" v-if="videosLoaded">
        <ClipCard v-for="(video, i) in videos"
                  :key="i"
                  :video="video"/>
      </div>
      <div v-else class="account-videos-content">
        <v-skeleton-loader style="box-shadow: none !important; border-radius: 20px" type="card" elevation="2" class="clipcard--wrapper" v-for="i in 2" :key="i"/>
      </div>
      <div v-if="videosLoaded && !videos.length" class="account-videos-content empty">
        <CText text="Пока нет ни одного купленного видео урока" class="mb-2"></CText>
        <CustomButton
            text="Купить видео-лекции"
            variant="black"
            class="buy-btn"
            @click.native="$router.push('/videos')"
        />
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import TagList from "@/components/reusable/TagList";
import ClipCard from "@/components/reusable/ClipCard";

export default {
  name: "MyVideos",
  components: {
    ClipCard,
    TagList
  },
  data() {
    return {
      videos: [],
      tags: [],
      videosLoaded: false,
    }
  },
  methods: {
    getVideos() {
      this.$server.request('user/boughtItems?type=video', {}, (data)=>{
        this.videos = data.response
        this.videosLoaded = true
      })
    },
  },
  mounted() {
    this.getVideos()
  }
}
</script>

<style lang="scss">
.account-videos-wrapper {
  padding: 0 !important;

  .tag-list {
    margin-top: 0;
  }
}

.account-videos-header {
  display: flex;
  justify-content: space-between;

  .searchbar {
    display: flex;

    img {
      margin-right: 4px;
    }

    .searchbar__input {
      width: 47px;
    }
  }
}


.account-videos-content {
  display: flex;
  flex-wrap: wrap;
  &.empty {
    flex-direction: column;
    .v-btn {
      max-width: 200px;
    }
  }

  .clipcard--wrapper {
    margin-bottom: 8px;

    &:nth-child(3) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
