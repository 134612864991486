<template>
  <ContentWrapper background="var(--bg-color-blue)">
    <div class="snd-section-wrapper" id="about">
      <div class="first-image-block image-block">
        <v-img v-for="(image, i) in images.slice(0, 3)" :key="i"
               :src="`/img/${image.img}`"
               content-class="image-content"
               :class="image.class">
          <HText variant="h3">{{ image.title }}</HText>
          <CText class="image__text" :text="image.text"></CText>
        </v-img>
      </div>
      <div class="second-image-block image-block">
        <v-img v-for="(image, i) in images.slice(3, 6)" :key="i"
               :src="`/img/${image.img}`"
               content-class="image-content"
               :class="image.class">
          <HText variant="h3">{{ image.title }}</HText>
          <CText class="image__text" :text="image.text"></CText>
        </v-img>
      </div>
      <div class="third-image-block image-block">
        <v-img v-for="(image, i) in images.slice(6, 8)" :key="i"
               :src="`/img/${image.img}`"
               content-class="image-content"
               :class="image.class">
          <HText variant="h3">{{ image.title }}</HText>
          <CText class="image__text" :text="image.text"></CText>
        </v-img>
      </div>

    </div>
  </ContentWrapper>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {img: 'heart.png', title: 'Мама', text: 'испытала радость материнства 7 раз', class: 'mama-img'},
        {img: 'teacher.png', title: 'Учитель', text: 'с 2009 года ведет курсы по подготовке к родам, по уходу за малышом', class: 'teacher-img'},
        {img: 'grandma.png', title: 'Бабушка', text: 'первая взяла внучек на руки, прогревая их рождение', class: 'grandma-img'},

        {img: 'nurce.png', title: 'Акушерка', text: '500+ раз помогала женщинам становиться мамами', class: 'nurse-img'},
        {img: 'wife.png', title: 'Жена', text: 'их с мужем прекрасному союзу уже 30 лет', class: 'wife-img'},
        {img: 'woman.png', title: 'Женщина', text: ' в счастье, в добре, в любви', class: 'woman-img'},

        {img: 'student.png', title: 'Ученица', text: '3 образования, включая медицинское, постоянные курсы повышения квалификации', class: 'student-img'},
        {img: 'owner.png', title: 'Хозяйка', text: 'успевает заниматься домом, участком, 3 собаками и развитием 7-х детей', class: 'owner-img'},
      ]
    }
  }
}
</script>

<style lang="scss">
.snd-section-wrapper {
  width: 100%;
  padding-top: 83px;
  padding-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .first-image-block {
    .v-image {
      width: 60%;
    }
  }
  .second-image-block {
    .v-image {
      width: 85%;
    }
  }
  .image-block {
    .mama-img {
      margin-top: 110px;
    }
    .teacher-img {
      margin-top: -97px;
      margin-left: 197px;
    }
    .grandma-img {
      margin-top: -130px;
    }
    .nurse-img {
      margin-left: -126px;
    }
    .wife-img {
      margin-top: -32px;
      margin-left: 10px;
    }
    .woman-img {
      max-width: 220px !important;
      margin-left: -30px
    }
    .student-img {
    }
    .owner-img {

    }
  }
  .third-image-block {
    margin-top: 109px;
    margin-left: -50px;
    .owner-img {
      width: 70%;
    }
  }
  .image-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .image__text {
      max-width: 165px;
      text-align: center;
      width: inherit;
    }
  }
}
@media screen and (max-width: 1024px) {
  .snd-section-wrapper {
    padding-top: 58px;
    .third-image-block {
      margin-top: 0;
      display: flex;
      .student-img {
        max-width: 330px;
        height: 185px;
        order: 2;
      }
      .owner-img {
        width: 40%;
        order: 1;
        margin-top: -75px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .snd-section-wrapper {
    .image-block {
      .v-image {
        width: 177px;
        min-width: 177px;
        .image__text {
          font-size: 12px;
          line-height: 18px;
        }
      }
      .mama-img {
        margin-top: 0;
      }
      .grandma-img {
        margin-top: -75px;
      }
      .nurse-img {
        margin-top: -60px;
        margin-left: 150px;
      }
      .wife-img {
        margin-top: -85px;
        margin-left: -20px;
      }
      .woman-img {
        margin-left: auto;
        margin-top: -45px;
      }
    }
    .third-image-block {
      margin-left: 15px;
    }

  }
}
</style>
