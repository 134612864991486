<template>
  <div class="date-and-time-wrapper">
    <div class="calendar-wrapper">
      <div class="consult-options">
        <v-select
            v-model="title"
            outlined
            label="Тема консультации"
            class="input"
            hide-details="auto"
            :items="consultationTypes"
            @input="valueChanged($event, 'title');"
        />
        <v-text-field
            v-if="title === 'Другое'"
            v-model="other_title"
            class="input mr-0 mt-4"
            label="Тема консультации"
            outlined
            hide-details="auto"
            @input="valueChanged($event, 'title');"
        />
        <v-checkbox
            v-model="consultation.urgent"
            class="form-checkbox"
            label="Срочная консультация"
            :true-value="1"
            :false-value="0"
            hide-details="auto"
            @change="valueChanged($event, 'urgent')"
        />
      </div>
      <v-date-picker
          v-if="!consultation.urgent"
          v-model="consultation.date"
          no-title
          locale="ru"
          first-day-of-week="1"
          :events="busyDays"
          event-color="#EF3900"
          :allowed-dates="allowDates"
          @input="valueChanged($event, 'date');"
      />
      <div class="subscription-wrapper mt-4" v-if="!consultation.urgent">
        <v-checkbox
            v-model="consultation.subscription"
            class="form-checkbox"
            label="Абонемент"
            :true-value="1"
            :false-value="0"
            hide-details="auto"
            @change="valueChanged($event, 'subscription')"
        />
        <v-text-field
            v-if="consultation.subscription"
            v-model="consultation.count"
            class="input mr-0"
            label="Кол-во консультаций"
            outlined
            hide-details="auto"
            :disabled="!consultation.subscription"
            v-mask="['##']"
            @input="valueChanged($event, 'count')"
        />
      </div>
    </div>
    <div class="time-wrapper" v-if="!consultation.urgent">
      <div class="time picker-item" v-for="(time, index) in times" :key="index"
           @click="valueChanged(time, 'time'); selectedTime = time;"
           :class="{'picker-item--selected': selectedTime === time}"
      >
        {{ time }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondStepConsult",
  props: ['consultation'],
  data() {
    return {
      title: '',
      other_title: '',
      selectedTime: '',
      times: ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'],
      consultationTypes: ['Беременность', 'Дети до 1 года', 'Дети 1-3 года', 'Дети от 3-х лет', 'Женское здоровье', 'Другое'],
      busyDays: [],
    }
  },
  emits: ['value-changed'],
  methods: {
    allowDates(date) {
      return [1, 2, 3, 4, 5].includes(this.$moment(date).day()) && this.$moment(this.$moment.now()).isBefore(this.$moment(date).add("days", 1));
    },
    valueChanged(value, prop) {
      if (value === 'Другое') return;
      this.$emit('value-changed', {value, prop})
    },
    onModalClose() {
      this.selectedTime = '';
      this.busyDays = [];
      this.title = '';
      this.other_title = '';
    },

  },
}
</script>

<style scoped>

</style>