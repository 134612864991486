<template>
  <ContentWrapper>
    <div class="account-settings-wrapper">
      <div class="personal-data account-settings-section">
        <HText variant="h2" class="account-settings__title">Личные данные</HText>
        <v-form v-if="dataLoaded" class="personal-data-form">
          <v-text-field
              autocomplete="off"
              class="input form-input"
              label="Имя"
              outlined
              v-model="user.name"
              hide-details="auto"
          />
          <v-text-field
              autocomplete="off"
              class="input form-input"
              label="Фамилия"
              outlined
              v-model="user.surname"
              hide-details="auto"
          />
          <v-text-field
              autocomplete="off"
              class="input form-input"
              label="E-mail"
              outlined
              v-model="user.email"
              disabled
              hide-details="auto"
          />
          <v-text-field
              autocomplete="off"
              class="input form-input"
              label="Мобильный телефон"
              outlined
              v-model="user.phone"
              hide-details="auto"
          />
          <v-text-field
              autocomplete="off"
              class="input form-input"
              label="Ник в Telegram"
              outlined
              v-model="user.telegram"
              hide-details="auto"
          />
          <CustomButton class="save-btn" variant="black" text="Сохранить" @click.native="updateUser" :disabled="!dataLoaded" :loading="loading"/>
        </v-form>
        <div v-else class="personal-data-form personal-data-form-skeleton">
          <v-skeleton-loader class="skeleton-full-height form-input" type="button" height="56" v-for="i in 4" :key="i"/>
        </div>
      </div>
      <div class="my-children account-settings-section">
        <HText variant="h2" class="account-settings__title">Мои дети</HText>
        <v-form>
          <div class="children-wrapper" v-if="dataLoaded">
            <div class="child" v-for="(child, i) in user.children.list" :key="i">
              <div class="child-data">
                <v-text-field
                    autocomplete="off"
                    class="input form-input"
                    label="Имя"
                    outlined
                    v-model="child.name"
                    hide-details="auto"
                />
                <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        hide-details="auto"
                        class="input form-input"
                        outlined
                        :value="child.birthdate ? $moment(child.birthdate).format('DD.MM.YYYY') : ''"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        v-mask="'##.##.####'"
                        placeholder="__.__.____"
                        label="День рождения"
                    />
                  </template>
                  <v-date-picker
                      v-model="child.birthdate"
                      no-title
                      scrollable
                      first-day-of-week="1"
                      locale="ru"
                      :reactive="true"
                  />
                </v-menu>
              </div>
              <v-radio-group
                  v-model="child.gender"
                  class="child-gender"
                  row>
                <v-radio
                    label="Девочка"
                    value="girl"
                />
                <v-radio
                    label="Мальчик"
                    value="boy"
                />
              </v-radio-group>
            </div>
          </div>
          <div v-else class="children-wrapper children-wrapper-skeleton">
            <div class="child">
              <div class="child-data">
                <v-skeleton-loader height="56" type="button" class="form-input skeleton-full-height" v-for="i in 2" :key="i"/>
              </div>
            </div>
          </div>
          <v-btn class="d-block add-child-btn" :disabled="!dataLoaded" @click="user.children.list.push({})">
            <v-icon>mdi-plus</v-icon>
            {{ user.children.list && user.children.list.length > 0 ? ' Добавить еще ребенка' : ' Добавить ребенка' }}
          </v-btn>
          <CustomButton :loading="loading" :disabled="!dataLoaded" class="save-btn" variant="disabled" text="Сохранить" @click.native="updateUser"/>
        </v-form>
      </div>
      <div class="notifications account-settings-section">
        <HText variant="h2" class="account-settings__title">Уведомления</HText>
        <v-form class="form">
          <!--          <v-checkbox-->
          <!--              v-model="notifications.news"-->
          <!--              class="form-checkbox"-->
          <!--              label="Получать новости и спецпредложения"-->
          <!--              true-value="0"-->
          <!--              false-value="1"/>-->
          <v-checkbox
              v-model="user.sendEmail"
              class="form-checkbox"
              label="Получать уведомления по Email"
              true-value="1"
              false-value="0"/>
          <CustomButton :disabled="!dataLoaded" :loading="loading" class="save-btn" variant="black" text="Сохранить" @click.native="updateUser"/>
        </v-form>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>

export default {
  name: "Settings",
  components: {},
  data() {
    return {
      user: {children: {}},
      dataLoaded: false,
      loading: false,
    }
  },
  methods: {
    getUser() {
      this.$server.request('user/get', {}, (data) => {
        data.response.children = JSON.parse(data.response.children)
        if (!data.response.children || !data.response.children.list) data.response.children = {list: []}
        this.user = data.response
        this.dataLoaded = true

      })
    },
    updateUser() {
      this.dataLoaded = false
      this.loading = true
      this.user.children = JSON.stringify(this.user.children)
      this.$server.request('user/update', this.user, () => {
        this.getUser()
        this.loading = false
      }, ()=>this.loading = false)
    }
  },
  mounted() {
    this.getUser()
  }
}
</script>

<style lang="scss">
.account-settings-wrapper {
  padding: 0 !important;

  .account-settings-section {
    max-width: 580px;
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 50px;

    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #ADA7A4;
      opacity: 0.6;
    }

    &:last-child:after {
      display: none;
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .account-settings__title {
    margin-bottom: 40px;
  }

  .form-input {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  .my-children {
    .form-checkbox {
      margin-top: 0;
      margin-bottom: 30px;

      .v-input__slot {
        margin-bottom: 0;
      }

      .v-messages {
        display: none;
      }
    }

    .form-input {
      margin-bottom: 0;
    }

    .child-data {
      display: flex;
      margin-bottom: 20px;

      .form-input:first-child {
        margin-right: 8px;
      }
    }

    .child-gender {
      margin-top: 0;
      margin-bottom: 40px;

      .v-input__slot {
        margin-bottom: 0;
      }

      .v-messages {
        display: none;
      }
    }

    .add-child-btn {
      margin-bottom: 40px;
      background-color: transparent;
      box-shadow: none;
      border: none;
      padding: 0;

      .v-icon {
        background-color: #ffffff;
        padding: 8px;
        margin-right: 8px;
        border-radius: 6px;
      }
    }
  }

  .notifications .form {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    .child-data {
      display: flex;
      flex-direction: column;

      .form-input {
        margin-right: 0;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
