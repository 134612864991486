<template>
  <div class="comments">
    <div class="comments-header">
      <div class="comments-header__title">Комментарии <span>{{ comments ? comments.length : '' }}</span></div>
    </div>
    <v-form ref="comment_form">
      <v-textarea
          rows="2"
          v-model="text"
          outlined
          class="comments-textarea"
          placeholder="Написать комментарий..."
          @keydown.enter="sendComment"
          counter
      />
    </v-form>
    <!--    <div class="sort-toggle">-->
    <!--      <div-->
    <!--          class="sort-toggle__item"-->
    <!--          :class="{'sort-toggle__item&#45;&#45;active': key === currentSort}"-->
    <!--          v-for="(sort, key) in sortMethods"-->
    <!--          :key="key"-->
    <!--          @click="currentSort = key"-->
    <!--      >-->
    <!--        {{ sort.text }}-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="comments-content">
      <div class="comment" v-for="(comment, index) in comments" :key="index">
        <div class="comment-divider"></div>
        <div class="comment-header">
          <div class="username">
            <p>{{ comment.user }}</p>
            <!--            <img src="/img/facebook.svg" alt=""/>-->
          </div>
          <div class="voting">
            <p style="margin-right: 20px">{{ $moment.unix(comment.datetime_create).fromNow() }}</p>
            <!--            <div class="action" style="margin-right: 5px;">-->
            <!--              <img src="/img/thumbsup.svg" alt=""/>-->
            <!--              <p>{{ comment.likes }}</p>-->
            <!--            </div>-->
            <!--            <div class="action">-->
            <!--              <img src="/img/thumbsdown.svg" alt=""/>-->
            <!--              <p>{{ comment.dislikes }}</p>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="comment-content">
          <p class="comment-text">{{ comment.text }}</p>
          <!--          <div class="comment-reply" v-if="comment.replies.length > 0">-->
          <!--            <v-icon>mdi-reply</v-icon>-->
          <!--            <div class="comment-reply-wrapper">-->
          <!--              <div class="comment-reply-header">-->
          <!--                <div class="username">-->
          <!--                  <p>{{ comment.replies[0].username }}</p>-->
          <!--                  <img src="/img/facebook.svg" alt=""/>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="comment-reply-text">-->
          <!--                <p>{{ comment.replies[0].text }}</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentSection",
  props: ['comments', 'videoId'],
  data() {
    return {
      sortMethods: {
        popular: {text: 'Популярные'},
        new: {text: 'Новые'},
      },
      text: '',
      currentSort: 'popular',
    }
  },
  methods: {
    sendComment() {
      this.$server.request(`video/comment/${this.videoId}`, {text: this.text}, () => {
        this.text = null;
        this.$eventBus.$emit('get_video')
      }, (data) => {
        if (data.error === 'user is not logged in') {
          this.$eventBus.$emit('open_auth_modal')
        }
      })
    },
  }
}
</script>

<style lang="scss">
.sort-toggle {
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  margin-bottom: 26px;

  &__item {
    font-size: 14px;
    line-height: 140%;
    color: rgba(0, 0, 0, .5);
    border: 1px solid;
    border-right-style: none;
    padding: 3px 15px;
    cursor: pointer;
    white-space: nowrap;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
      border-right-style: solid;
    }

    &--active {
      color: rgba(0, 0, 0, 1);
      border-right-style: solid;

      & + .sort-toggle__item {
        border-left-style: none;
      }
    }
  }
}

.v-application .comments {
  max-width: 580px;
  flex-grow: 1;

  &-header {
    &__title {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 22px;
      line-height: 29px;

      span {
        opacity: 0.5;
      }
    }
  }

  &-textarea {
    background-color: #ffffff;
    border: none;
    border-radius: 6px;
    height: 60px;
    margin-bottom: 50px !important;

    .v-input__control {
      height: auto;
    }

    fieldset {
      border: none !important;
    }
  }

  &-content .comment {
    &-divider {
      height: 1px;
      display: flex;
      width: 100%;
      background: #08050C;
      opacity: 0.3;
      margin-bottom: 30px;
    }

    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &-text {
      margin-bottom: 30px;
    }

    &-reply {
      margin-left: 14px;
      display: flex;

      .v-icon {
        margin-right: 15px;
        align-self: flex-start;
      }

      &-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      &-text {
        margin-bottom: 30px;
      }
    }

    .username {
      display: flex;

      p {
        font-size: 11px;
        line-height: 140%;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        opacity: 0.5;
      }
    }

    .username img {
      width: 15px;
      height: 15px;
      margin-left: 10px;
      border-radius: 2px;
    }

    .voting {
      display: flex;

      p {
        font-size: 11px;
        line-height: 140%;
      }

      .action {
        display: flex;
      }
    }

    .voting img {
      width: 15px;
      height: 15px;
    }
  }

}

</style>
