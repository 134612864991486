<template>
  <div class="footer--wrapper">
    <div class="footer--content">
      <div class="footer--top">
        <div class="footer--map">
          <div class="footer--map-item">
            <div class="map--item-header">Обучение</div>
            <div class="map--item-list">
              <router-link to="/videos" class="map--list-elem">Видео-лекции</router-link>
              <router-link to="/courses" class="map--list-elem">Курсы</router-link>
<!--              <router-link to="/clubs" class="map&#45;&#45;list-elem">Клубы</router-link>-->
            </div>
          </div>

          <div class="footer--map-item">
            <div class="map--item-header">О нас</div>
            <div class="map--item-list">
              <router-link to="/" class="map--list-elem">Об авторе</router-link>
<!--              <router-link to="/blog" class="map&#45;&#45;list-elem">Блог</router-link>-->
              <router-link to="/reviews" class="map--list-elem">Отзывы</router-link>
            </div>
          </div>
        </div>

        <div class="footer--signup">
          <CustomButton v-if="$store.state.user.id" to="/account/continue" text="Мой профиль" variant="white" style="padding: 3px 12px"/>
          <CustomButton v-else @click.native="openAuthModal" text="Войти" variant="white" style="padding: 3px 12px"/>
          <div class="footer--signup-links">
            <a class="footer--signup-link" @click="openCreateReview">Оставить отзыв</a>
            <a class="footer--signup-link" @click="openConsult">Записаться на консультацию</a>
          </div>
        </div>
      </div>
      <div class="footer--top terms--links">
        <div class="footer--map">
          <div class="footer--map-item">
            <div class="map--item-list">
              <a href="/agreement" target="_blank" class="map--list-elem">Пользовательское соглашение</a>
              <a href="/privacy" target="_blank" class="map--list-elem">Политика конфиденциальности</a>
            </div>
          </div>
        </div>
      </div>

      <div class="footer--divider"></div>

      <div class="footer--underfooter">
        <span class="footer--rights"><span>© Дарья Хромова. </span><span>Все права защищены</span></span>
        <div class="footer--socials">
<!--          <a href="https://www.instagram.com/rozhai_prosto/" target="_blank" class="footer&#45;&#45;link">Instagram</a>-->
<!--          <a href="https://www.facebook.com/dhromova" target="_blank" class="footer&#45;&#45;link">Facebook</a>-->
          <a href="https://t.me/mkh_07" target="_blank" class="footer--link">Поддержка</a>
          <a href="https://t.me/+hBebIzpEpOtjMWUy" target="_blank" class="footer--link">Telegram</a>
          <a href="https://vk.com/id4560485" target="_blank" class="footer--link">VK</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: ['variant'],
  methods: {
    openAuthModal() {
      this.$eventBus.$emit('open_auth_modal')
    },
    openCreateReview() {
      this.$eventBus.$emit('open_create_review_modal')
    },
    openConsult() {
      this.$eventBus.$emit('open-consult-modal');
    }
  },
  computed: {
    getVariant: function () {
      if (this.$props.variant === 'blue') {
        return 'footer--blue'
      }
      return 'footer--white'
    },
    getDisplayedStyles: function () {
      if (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs') {
        return 'mobile'
      }
      return ''
    }

  }
}
</script>

<style scoped lang="scss">
.footer--wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 60px;
  background: var(--bg-color-blue);
}

.footer--content {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.footer--top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &.terms--links {
    margin-bottom: 80px;
  }
}

.footer--top.mobile {
  flex-direction: column;
  align-items: center;
}

.footer--signup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 310px;
  height: 100%;
}

.footer--signup.mobile {
  height: 140px;
  justify-content: flex-start;
}

.footer--signup.mobile .footer--signup-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;
}

.footer--signup.mobile .footer--signup-button {
  margin-bottom: 25px;
  margin-top: 40px;
}

.footer--signup-button {
  padding: 13px;
  border-radius: 6px;
}

.footer--blue .footer--signup-button {
  background: #FFFFFF;
}

.footer--white .footer--signup-button {
  background: #EAF4FC;;
}


.footer--signup-button:hover {
  background: #deeefb;
}

.footer--rights {
  color: rgba(0, 0, 0, 0.5)
}

.mobile .footer--rights {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.footer--underfooter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  span {
    font-size: 14px;
    line-height: 20px;
  }
}

.footer--underfooter.mobile {
  flex-direction: column-reverse;

}

.footer--link {
  color: #222222;
  text-decoration: none;
}

.footer--link:hover {
  color: rgba(0, 0, 0, 0.5);
}

.footer--map {
  display: flex;
  width: 215px;
  justify-content: space-between;
}

.footer--map-item {
  display: flex;
  flex-direction: column;
}

.map--item-list {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.map--item-header {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 29px;
  font-size: 11px;
  text-transform: uppercase;
}

.map--list-elem {
  text-decoration: none;
  color: #222222;
  margin-bottom: 8px;
}

.footer--socials {
  width: 310px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.mobile .footer--socials {
  width: 100%;
}

.footer--signup-links {
  display: flex;
  justify-content: space-between;
}

.footer--signup-link {
  font-size: 14px;
  color: #222222;
  text-decoration: underline;
}

.footer--divider {
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 14px;
}
@media screen and (max-width: 1024px) {
  .footer--content {
    max-width: 768px;
  }
}
@media screen and (max-width: 768px) {
  .footer--content {
    padding: 0 30px;
  }
}
@media screen and (max-width: 600px) {
  .footer--top {
    flex-direction: column;
    .footer--map:first-child {
      margin-bottom: 40px;
    }
    .footer--signup {
      width: 100%;
      .v-btn {
        margin-bottom: 20px;
      }
    }
    .footer--signup-links {
      flex-direction: column;
      .footer--signup-link:first-child {
        margin-bottom: 20px;
      }
    }
  }
  .footer--underfooter {
    flex-direction: column;
    .footer--rights {
      order: 2;
      width: 100%;
    }
    .footer--socials {
      order: 1;
      margin-bottom: 40px;
    }
  }
}
</style>
