<template>
  <Modal width="750" classes="modal-wrapper create-review-wrapper"
         :showed-close="true"
         ref="modal" @on-outside-click="close" @on-icon-close-click="close"
  >
    <HText :variant="$vuetify.breakpoint.width <= 768 ? 'h2' : 'h1'" class="modal__title">
      Оставьте свой отзыв
    </HText>
    <v-form ref="review_form">
      <div class="modal-form-wrapper review-form-wrapper">
        <v-text-field
            v-model="review.title"
            outlined
            class="input"
            hide-details="auto"
            label="Ваше имя"
            :rules="[v=>!!v || 'Введите имя']"
            validate-on-blur
        />
        <v-textarea
            label="Отзыв"
            placeholder="Напишите комментарий..."
            v-model="review.text"
            outlined
            class="input"
            hide-details="auto"
            rows="3"
            auto-grow
            counter
            :rules="[v=>!!v && v.length > text_length || `Комментарий должен быть не менее ${text_length+1} символов`]"
            validate-on-blur
        />
        <CustomButton
            text="Отправить отзыв"
            variant="black"
            :loading="review_loading"
            class="review_btn"
            @click.native="createReview"
        />
      </div>
    </v-form>
  </Modal>
</template>

<script>
import Modal from "@/components/reusable/Modal";
export default {
  name: "CreateReview",
  components: {
    Modal,
  },
  data() {
    return {
      review: {},
      review_loading: false,
      text_length: 19,
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.review = {}
      this.$refs.modal.close();
    },
    createReview() {
      if (this.$refs.review_form.validate()) {
        this.review_loading = true;
        this.$server.request('feedback/create', {title: this.review.title, text: this.review.text}, ()=>{
          this.review_loading = false;
          this.close();
          this.$eventBus.$emit('open_prompt_modal', {title: 'Спасибо за отзыв!', subtitle: 'Скоро он появится в общем списке.', type: 'success'})
        }, (error)=>{
          if (error.error === 'user is not logged in')
            this.$eventBus.$emit('open_auth_modal', {type: 'review'})
          this.review_loading = false;
          this.close();
        })
      }
    }
  }
}
</script>

<style lang="scss">
.v-application .dialog-wrapper {
  .review-form-wrapper {
    flex-direction: column;
    align-items: center;

    .input {
      margin-bottom: 24px;
    }
    .review_btn {
      max-width: 190px;
    }
  }
  @media screen and (max-width: 600px) {
    .modal-wrapper.create-review-wrapper {
      padding-top: 55px;
    }
  }
}
</style>
