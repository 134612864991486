<template>
  <div class="page-content course-page-content">
    <ContentWrapper background="var(--main-bg-color)">
      <div class="course-section">
        <Back text="Вернуться" to="/courses"/>
        <HText v-if="courseLoaded" variant="h1" class="course-section__title">{{ course.title }}</HText>
        <v-skeleton-loader v-else type="text" height="37" class="course-section__title" width="270"/>
        <div class="trailer-container">
          <div class="sticker" v-if="!+course.online">
            <p v-if="courseLoaded"><strong>{{ course.videos ? course.videos.length : '' }}</strong> лекции</p>
<!--            <p><strong>Чат</strong></p>-->
          </div>
          <CText class="trailer-container__pretitle"
                 :text="course.date?'Начало курса '+$moment(course.date).format('D MMMM YYYY'):'О чем этот курс'"></CText>
          <HText v-if="courseLoaded" variant="h2" class="trailer-container__subtitle">{{ course.title }}</HText>
          <v-skeleton-loader v-else type="text" height="22" width="200" class="trailer-container__subtitle"/>
          <div class="trailer-container--wrapper">
            <div class="trailer-container--wrapper__video" style="align-items: flex-start;">
              <img v-if="courseLoaded" :src="course.preview" alt="">
              <v-skeleton-loader v-else type="image" class="skeleton-image"/>
            </div>
            <div class="trailer-container--wrapper__video-desc">
              <CText v-if="!+course.online" class="trailer-container--wrapper__video-text"
                     :text="course.description"></CText>
              <NewYearIcon style="position: relative;margin-left: 110px;margin-top: 220px;" v-if="+course.price_full"/>
              <CustomButton
                  v-if="course.hasAccess === 0 && course.price*1 > 0"
                  :text="!(+course.price_full) ?  `${+course.online ? 'Присоединиться':'Купить'} за ${$root.printCost(course.price)}` :
                   `${+course.online ? 'Присоединиться':'Купить'} за
                   <span style='color: white; margin-left: 5px'>${$root.printCost(course.price)}</span>
                   <span style='text-decoration: line-through;font-size: 12px;margin-left: 10px; color: gray'>${$root.printCost(course.price_full)}</span>
                   `
"
                  variant="black"
                  style="width: fit-content"
                  class="buy-btn"
                  @click.native="openBuyModal(course)"
              />
              <CustomButton
                  v-else-if="course.hasAccess === 0 && course.price*1 === 0 && !$store.state.user.id"
                  text="Войти" variant="black" style="width: 165px"
                  class="buy-btn"
                  @click.native="$eventBus.$emit('open_auth_modal')"
              />
              <div v-if="+course.online && course.hasAccess === 1"><b>Вы записаны на курс!</b>
                <div v-if="!$store.state.user.phone && !$store.state.user.telegram">Пожалуйста, укажите в
                  <router-link to="/account/settings"><b>Настройках профиля</b></router-link>
                  свой номер телефона или ник в телеграме. Мы вас добавим в телеграм-канал нашего курса.
                </div>
                <div v-else>Вы будете добавлены в наш телеграм-канал онлайн-курса по <span
                    v-if="$store.state.user.telegram"> нику @{{ $store.state.user.telegram }}</span><span v-else> номеру телефона {{ $store.state.user.phone }}</span>
                </div>
              </div>
            </div>
          </div>
          <CText v-if="+course.online" class="trailer-container--wrapper__video-text mt-4"
                 :text="course.description"></CText>

        </div>
        <CourseProgram v-if="!+course.online" :course="course" :course-loaded="courseLoaded"/>
      </div>
    </ContentWrapper>
    <ContentWrapper background="white" v-if="['4', '6', '12'].includes(course.id)">
      <div class="plus-and-minus-section">
        <div class="plus-container">
          <HText variant="h1" class="plus-and-minus-section__title">
            {{ first_block.courses.find(_course => _course.id === course.id).pluses.title }}
          </HText>
          <ul class="plus-container-list">
            <li v-for="(plus, index) in first_block.courses.find(_course=>_course.id === course.id).pluses.rows"
                :key="index">
              <img src="/img/check.png" alt="" width="16" height="12">
              {{ plus }}
            </li>
          </ul>
        </div>
        <div class="plus-and-minus-section-divider"></div>
        <div class="minus-container">
          <HText variant="h1" class="plus-and-minus-section__title">
            {{ first_block.courses.find(_course => _course.id === course.id).minuses.title }}
          </HText>
          <ul class="minus-container-list">
            <li v-for="(minus, index) in first_block.courses.find(_course=>_course.id === course.id).minuses.rows"
                :key="index">
              <img v-if="first_block.courses.find(_course=>_course.id === course.id).minusIsPlus" src="/img/check.png"
                   alt="" width="16" height="12">
              <img v-else src="/img/cross.svg" alt="" width="23" height="18">
              {{ minus }}
            </li>
          </ul>
        </div>
      </div>
    </ContentWrapper>
    <ContentWrapper background="var(--bg-color-blue)" v-if="['6'].includes(course.id)">
      <div class="course-description-section">
        <HText variant="h1" class="course-description-section__title">
          {{ second_block.courses.find(_course => _course.id === course.id).title }}
        </HText>
        <div class="course-description-wrapper">
          <div class="description"
               v-for="(row, index) in second_block.courses.find(_course=>_course.id === course.id).rows" :key="index">
            <div class="description__img">
              <img :src="row.img" alt="" width="310">
            </div>
            <div class="description__text">
              <HText variant="h2" class="description__text__title">{{ row.title }}</HText>
              <ul>
                <li v-for="(text, i) in row.text" :key="i">{{ text }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
    <ContentWrapper background="var(--main-bg-color)" v-if="videoReviews.find(_reviews=>_reviews.id === course.id)">
      <div class="reviews-section">
        <HText variant="h1" class="reviews-section__title mb-6">Отзывы</HText>
        <!--        <CText class="reviews-section__subtitle" :text="`${ reviews.length } отзывов`"></CText>-->
        <ReviewsSection :video-reviews="videoReviews.find(_reviews=>_reviews.id === course.id).videos"
                        :reviews="reviews" v-if="inView"/>
      </div>
    </ContentWrapper>
    <ContentWrapper background="var(--bg-color-blue)">
      <div class="trust-reasons">
        <HText variant="h1" class="trust-reasons__title">Обо мне</HText>
        <div class="reasons-container">
          <div class="reason" v-for="(reason, index) in trust_reasons" :key="index">
            <div class="h3 reason__title">{{ reason.title }} <span>{{ reason.title_span }}</span></div>
            <CText class="reason__text" :text="reason.text"></CText>
          </div>
        </div>
      </div>
    </ContentWrapper>
    <ContentWrapper background="white">
      <div class="quote-wrapper">
        <div class="quote">
          В университетах не учат воспитывать детей. <span>Вы сразу тренируетесь на своих детях.
      И цена ошибки очень высока. Но если рядом есть семья с положительным примером воспитания, то вам остается только перенять опыт, внедрить и пользоваться на благо.</span>
        </div>
        <NewYearIcon style="position: relative;margin-left: 00px;margin-top: 0" v-if="+course.price_full"/>
        <CustomButton
            v-if="course.hasAccess === 0 && course.price*1 > 0"
            variant="black"
            :text="!(+course.price_full) ?  `${+course.online ? 'Присоединиться':'Купить'} за ${$root.printCost(course.price)}` :
                   `${+course.online ? 'Присоединиться':'Купить'} за
                   <span style='color: white; margin-left: 5px'>${$root.printCost(course.price)}</span>
                   <span style='text-decoration: line-through;font-size: 12px;margin-left: 10px; color: gray'>${$root.printCost(course.price_full)}</span>
                   `
"
            @click.native="openBuyModal(course)"
        />
      </div>
    </ContentWrapper>
    <Buy ref="buy_modal"/>
  </div>
</template>

<script>
import ReviewsSection from "@/components/reusable/ReviewsSection";
import Buy from "@/modals/Buy";
import CourseProgram from "../../components/reusable/CourseProgram";
import NewYearIcon from "../../components/reusable/NewYearIcon";

export default {
  name: "Course",
  components: {
    NewYearIcon,
    CourseProgram,
    ReviewsSection,
    Buy,
  },
  data() {
    return {
      course: {},
      reviews: [],
      first_block: {
        courses: [
          {
            id: '12',
            pluses: {
              title: 'Вы чувствуете что...',
              rows: ['На вас одну все навалилось', 'Вы перестали прихорашиваться', 'Вы себе не пренадлежите', 'Вы как выжатый лимон', 'Вы погрузились в быт с головой', 'Одно и то же, каждый день', 'Вы завидуете мужу и его работе', 'Тарарам у вас в голове'],
            },
            minuses: {
              title: 'В результате прохождения курса вы получите',
              rows: ['Станете ценить себя и свои интересы', 'Восполните запасы своей энергии', 'Гармоничные отношения с мужем и детьми', 'Азы счастливого материнства и замужества', 'Обретете гармонию и тихую радость', 'Примените техники расслабления', 'Узнаете способы самореализации', 'Повысите качество жизни'],
            },
            minusIsPlus: true,

          },
          {
            id: '6',
            pluses: {
              title: 'Этот курс для Вас, если вы хотите',
              rows: ['чтобы беременность прошла легко, без осложнений',
                'прийти к родам с хорошим настроем и здоровье',
                'сформировать надежную команду в родах',
                'быть спокойной и уверенной в схватках',
                'владеть техниками обезболивания и дыхания',
                'Получить роды мечты',
                'понимать малыша с первой минуты',
                'Быстро наладить грудное вскармливание',
                'Плавно войти в ритм новой жизни с ребенком и ничего не бояться',
                'Легко восстановить себя после родов!'],
            },
            minuses: {
              title: 'Что будет, если не пройти курс',
              rows: ['Много необоснованных страхов о беременности и родах',
                'Беспокойства из-за незначительных отклонений в анализах',
                'Пренебрежение серьезными нарушениями в здоровье будущей мамы.',
                'Негативные результаты мысли “все рожают и я как-то рожу”',
                'Заблуждения в процессе организации своих родов',
                'Неосведомленность о серьезных последствиях вмешательств в физиологию родов для матери и ребенка',
                'Незнание, что делать с болью в родах',
                'Послеродовая депрессия. Роды закончились, а что делать дальше? Отсутствие понимания как кормить, ухаживать и жить (еще и счастливо) после родов.'],
            },
          },
          {
            id: '4',
            pluses: {
              title: 'Этот курс для Вас, если вы хотите узнать от эксперта',
              rows: ['Как рассчитать дату родов',
                'Какие приметы работают, а какие нет',
                'Какие исследования в беременность необходимы, а без каких можно обойтись',
                'Сколько раз делать УЗИ',
                'Можно ли ходить в фитнес, бассейн и сауну',
                'Куда поехать в отпуск',
                'Почему беременность не болезнь, а лучшее время вашей жизни'],
            },
            minuses: {
              title: 'И хотите избежать',
              rows: ['Многих необоснованных страхов о беременности и родах',
                'Вредных советов и примет',
                'Беспокойств из-за образа жизни, спорта и секса в беременность',
                'Многих лишних походов на УЗИ, тесты и другие исследования',
                'Неуместных вопросов от родственников',
                'Лишнего набора веса',
                'Употребления вредных продуктов'],
            },
          }
        ]
      },
      second_block: {
        courses: [
          {
            id: '6',
            title: 'Курс включает в себя следующие темы',
            rows: [
              {
                img: '/img/pregnant-woman-touch.jpg',
                title: 'Счастливая беременность',
                text: ['Питание во время беременности;',
                  'Как правильно подойти к родам;',
                  'Гимнастика и образ жизни беременных;',
                  '10 ошибок в беременность, которые совершают 80% будущих мам;',
                  'Секс во время беременности;',
                  'Недомогания в беременность и их легкое преодоление: бессонница, растяжки, инфекции почек, простуда, запоры, тонус матки, изжога, варикоз, геморрой, кальций в беременность, эмоциональные проблемы, молочница, очищение маслом (изжога, гастрит, проблемы ЖКТ), отеки, анемия, прием гормонов.']
              },
              {
                img: '/img/uzi.png',
                title: 'Все о родах: Физиология и психология',
                text: ['Предвестники;',
                  'Родовой поток и роды по этапам;',
                  'Техники дыхания и обезболивания;',
                  'Методы расслабления;',
                  'Комфортные позы в родах;',
                  'Медицинские вмешательства в родах — есть ли необходимость;',
                  'Папа в родах (урок для мужей);',
                  'Роды глазами ребенка;',
                  'Пренатальные матрицы;',
                  'В родах рождается и малыш и мама;',
                  'Гипнотехники.']
              },
              {
                img: '/img/3rd_photo.png',
                title: 'Здоровье мамы и малыша',
                text: ['Много/мало молока;',
                  'Нагрубание груди;',
                  'Питание мамы при ГВ;',
                  'Позы при ГВ;',
                  'Сцеживание. А надо ли и как?',
                  'Мама или малыш болеют при ГВ;',
                  'Лактостаз;',
                  'Как не допустить лактозную недостаточность;',
                  'Проблемы с прикладыванием;',
                  'Срыгивания. Норма или нет;',
                  'Ребенок отказывается от груди. Что делать?',
                  'Завершение ГВ.']
              },
              {
                img: '/img/4th_photo.jpg',
                title: 'Все о ГВ от мамы 7х детей. Кормила всех своих детей!',
                text: ['базовый уход за малышом',
                  'приданое — только самое необходимое',
                  'необходимая аптечка для малыша',
                  'режим до годика: сон, кормления',
                  'закаливание, купание-ныряние, укрепление иммунитета',
                  'массаж, гимнастика, которую могут делать родители']
              },
            ]
          }
        ]
      },
      trust_reasons: [
        {
          title: `30 лет`,
          title_span: 'супружества',
          text: 'Романтика и влюбленность даже спустя 30 лет? Легко! Я знаю секреты счастья.'
        },
        {
          title: `1000+`,
          title_span: 'принятых родов',
          text: 'Веду беременность, помогаю в родах, 5 000 часов патронажа детей + семейных пар.'
        },
        {
          title: `120 лет`,
          title_span: 'материнства',
          text: 'У меня 7 детей и 3 внука. Вижу подрастающие плоды нашего воспитания.'
        },
        {
          title: `3`,
          title_span: 'образования',
          text: 'Включая медицинское + постоянные курсы повышения квалификации, саморазвитие.'
        },
      ],
      videoReviews: [
        {
          id: '12',
          videos: [
            {
              src: 'https://www.youtube.com/embed/eOWDzZQCavE',
              quote: 'Этот продукт стоит своих денег. Потому что сама ты не знаешь, где взять информацию, тебе хочется быстрее получить ответы'
            },
            {src: 'https://www.youtube.com/embed/DVlpGE3B-8w'},
            {src: 'https://www.youtube.com/embed/h8ihqoufoJ0'},
            {src: 'https://www.youtube.com/embed/3VhU7ZemfO4'},
            {src: 'https://www.youtube.com/embed/ynQUhGWs7mg'},
            {src: 'https://www.youtube.com/embed/xMFfFjXX6fo'},
            {src: 'https://www.youtube.com/embed/Lo6_E4Sz0tg'},
            {src: 'https://www.youtube.com/embed/Csexp9W6K-8'},
            {src: 'https://www.youtube.com/embed/Vlx6FVg_P8A'},
            {src: 'https://www.youtube.com/embed/hN55VavxuE4'},
            {src: 'https://www.youtube.com/embed/CcnV2xMM-jU'},
          ]
        },
        {
          id: '9',
          videos: [
            {
              src: 'https://www.youtube.com/embed/H67nQ5AiN38',
              quote: 'ВОСходящее ПИТАНИЕ – курс о внутреннем настрое, который мы можем передать детям и быть уверенными, что по жизни они пойдут хорошей дорогой и будут счастливыми людьми'
            },
            {
              src: 'https://www.youtube.com/embed/4ouHymKaV3U',
              quote: 'Даша рассказывает все секреты, как делегировать детей мужу. Мы прорабатываем на курсе, как передать всю ответственность'
            },
            {
              src: 'https://www.youtube.com/embed/KuOVVCfwBNE',
              quote: 'Воспитывать нужно по возрастам. Прошла только половина марафона, а дети уже начали меня слышать!'
            },
            {
              src: 'https://www.youtube.com/embed/6gkNYj90btk',
              quote: 'Каждый урок марафона - открытие. Чтобы воспитать своего ребёнка - сначала нужно воспитать себя'
            },
            {
              src: 'https://www.youtube.com/embed/VKibzcPI6nw',
              quote: 'Вы нигде не найдёте тех знаний, которые даёт Даша. Вижу изменения в себе, в ребёнке, в семье стала приятная атмосфера'
            },
            {
              src: 'https://www.youtube.com/embed/RyoGWldlvOE',
              quote: 'За ежедневными заботами упускаются важные моменты воспитания. Благодаря Даше в моей голове сложилась картинка - на что обратить внимание в каждом возрасте'
            },
            {
              src: 'https://www.youtube.com/embed/TaXHorWEIvU',
              quote: 'Даша показала мне в моих детях то, чего я раньше не видела, на что не обращала внимания!'
            },
            {
              src: 'https://www.youtube.com/embed/qUTD3RlHoZw',
              quote: 'Воспитание – это ежечасный труд… тяжелый, но самый творческий и вдохновляющий'
            },
          ]
        }
      ],
      inView: false,
      courseLoaded: false,
    }
  },
  methods: {
    getCourse() {
      this.$server.request(`course/get/${this.$route.params.id}`, {}, (data) => {
        this.course = data.response;
        document.title = `Курс - ${this.course.title}`;
        this.courseLoaded = true;
      })
    },
    getReviews() {
      // this.$server.request('feedback/get', {}, (data) => {
      //   this.reviews = data.response
      // })
    },
    openBuyModal(course) {
      this.$router.push({path: '/checkout', query: {type: 'course', item_id: course.id, title: course.title }})
    },
    handleScroll() {
      if (this.videoReviews.find(_reviews => _reviews.id === this.course.id)) {
        if (this.isInViewPort(document.querySelector('.reviews-section .reviews-section__title'))) this.inView = true
      }
    },
    isInViewPort(element) {
      let bounding = element.getBoundingClientRect();
      return bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    }
  },
  mounted() {
    this.getCourse()
    this.getReviews()
  },
  created() {
    this.$eventBus.$on('buy_course', (data) => {
      this.$eventBus.$emit('open_prompt_modal', data)
      if (data.type === 'success') this.getCourse()
    })
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss">
.page-content.course-page-content {
  padding-bottom: 0;

  .course-section {
    padding: 94px 0 111px;
    position: relative;

    .sticker {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      right: 0;
      top: -85px;
      background-color: #F5E5AE;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      transform: rotate(10deg);

      p {
        font-size: 18px;
        line-height: 160%;
      }
    }

    &__title {
      margin-bottom: 32px;
    }

    &__tags {
      margin-bottom: 62px;
    }

    .trailer-container {
      background-color: #EAF4FC;
      border-radius: 34px;
      padding: 40px 57px 55px 50px;
      margin-bottom: 60px;
      position: relative;

      &__pretitle {
        margin-bottom: 20px;
      }

      &__subtitle {
        margin-bottom: 50px;
        max-width: 805px;
      }

      &--wrapper {
        display: flex;

        &__video {
          margin-right: 42px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 16px;

          img, .skeleton-image {
            border-radius: 16px;
            width: 496px;
            //object-fit: cover;
            height: 320px;
          }

          .skeleton-image {
            .v-skeleton-loader__image {
              height: inherit;
            }
          }

          .play-btn {
            position: absolute;
            color: #ffffff;
          }

          &-text {
            margin-bottom: 20px;
          }
        }

        &__video-desc {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

    }
  }

  .plus-and-minus-section {
    padding: 110px 0;
    background: #ffffff;
    display: flex;

    &__title {
      margin-bottom: 44px;
    }

    &-divider {
      width: 1px;
      height: auto;
      background: #C4C4C4;
    }

    .plus-container {
      padding-right: 173px;
      flex-basis: 50%;
    }

    .minus-container {
      padding-left: 130px;
      flex-basis: 50%;
    }

    ul {
      list-style-type: none;

      li {
        margin-bottom: 32px;
        display: flex;
        font-size: 14px;
        line-height: 20px;
        align-items: center;

        img {
          margin-right: 20px;
        }
      }
    }
  }

  .course-description-section {
    padding: 100px 0;

    &__title {
      margin-bottom: 75px;
    }

    .course-description-wrapper {
      .description {
        display: flex;
        margin-bottom: 30px;
        justify-content: space-between;

        &:nth-child(2n) {
          .description__img {
            order: 2;
          }

          .description__text {
            margin-right: 24px;
          }
        }

        &__img {
          width: 310px;
          margin-right: 50px;
          overflow: hidden;

          img {
            border-radius: 80px;
          }

        }

        &__text {
          align-self: center;
          max-width: 445px;

          &__title {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .result-section {
    padding: 100px 0;

    &__title {
      margin-bottom: 20px;
    }

    &__subtitle {
      margin-bottom: 70px;
    }

    .results-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .result {
        display: flex;
        width: 323px;
        margin-right: 10px;
        margin-bottom: 90px;

        &__number {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          min-width: 54px;
          width: 54px;
          height: 54px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 23px;
          border-radius: 50%;
          background-color: #F5E5AE;
        }

        &-wrapper {
          margin-top: 10px;
        }

        &__title {
          margin-bottom: 15px;
        }

        &__text {
          font-size: 14px;
          line-height: 20px;
          opacity: 0.9;
        }
      }
    }
  }

  .reviews-section {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: 7px;
    }

    &__subtitle {
      text-align: left;
      margin-bottom: 40px;
    }

    .reviews-section-wrapper {
      display: flex;

      .reviews {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .percents {
        min-width: 285px;
        width: 285px;

        &__title {
          margin-bottom: 40px;
        }

        &-wrapper {
          &__percent {
            display: flex;
            margin-bottom: 30px;

            .v-progress-circular {
              min-width: 86px;
              margin-right: 30px;
            }
          }
        }
      }
    }
  }

  .trust-reasons {
    padding: 120px 0 150px;

    .reasons-container {
      margin-bottom: 0;
    }
  }

  .quote-wrapper {
    background-color: #ffffff;
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 136px 0 90px;
  }

  .quote {
    position: absolute;
    top: -100px;
    z-index: 1;
    width: 1000px;
    padding: 50px;
    padding-left: 60px;
    font-size: 22px;
    line-height: 29px;
    font-weight: bold;
    border-radius: 20px;
    background: #D8E1E9;

    span {
      color: #6F8DA8;
    }
  }
}

@media screen and (max-width: 768px) {
  .page-content {
    .course-section {
      padding-top: 104px;

      &__title {
        margin-bottom: 22px;
      }

      &__tags {
        margin-bottom: 70px;
      }

      .trailer-container {
        padding-left: 40px;
        padding-right: 40px;
      }

      &__pretitle {
        margin-bottom: 30px;
      }

      &__subtitle {
        margin-bottom: 40px;
      }

      .trailer-container--wrapper {
        flex-direction: column;

        &__video {
          width: 100%;
          margin-bottom: 40px;

          &-text {
            margin-bottom: 35px;
          }

          img, .skeleton-image {
            width: inherit;
          }

          &-desc {
            .buy-btn {
              align-self: center;
            }
          }
        }
      }

      .course-program {
        .course-program {
          &__title {
          }

          &-container {
            display: flex;
            justify-content: center;
            overflow-x: hidden;

            .course {
              min-width: 220px;
              width: 220px;
              margin-bottom: 8px;
              margin-right: 8px;
            }
          }

        }

      }
    }

    .plus-and-minus-section {
      padding: 60px 180px;
      flex-direction: column;
      align-items: center;

      &__title {
        text-align: center;
      }

      &-divider {
        height: 1px;
        width: 100%;
      }

      .plus-container {
        padding-right: 0;
        padding-bottom: 60px;

        li {
          margin-bottom: 20px;
        }
      }

      .minus-container {
        padding-top: 60px;
        padding-left: 0;

        li {
          margin-bottom: 20px;
        }
      }
    }

    .course-description-section {
      padding-top: 60px;
      padding-bottom: 65px;

      .course-description-wrapper {
        .description {
          &__img {
            margin-right: 35px;
          }
        }
      }
    }

    .result-section {
      padding-top: 70px;
      padding-bottom: 25px;

      .results-wrapper {
        .result {
          margin-bottom: 60px;
        }
      }
    }

    .reviews-section {
      padding: 80px 0 125px;
      max-width: 580px;
      margin: 0 auto;

      &__title, &__subtitle {
        text-align: center;
      }

      &-wrapper {
        flex-direction: column;

        .reviews-wrapper {
          margin-right: 0;
          order: 2;
        }

        .percents {
          width: 100% !important;
          order: 1;

          &-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &__percent {
              min-width: 280px;
              width: 280px;
            }
          }
        }
      }
    }

    .trust-reasons {
      display: flex;
      flex-direction: column;
      padding-top: 65px;
      padding-bottom: 210px;
      align-items: center;

      &__title {
        margin-bottom: 45px;
        text-align: center;
      }

      .reasons-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 510px;

        .reason {
          max-width: 250px;
          margin-bottom: 8px;
        }
      }
    }

    .quote-wrapper {
      padding-top: 145px;
      padding-bottom: 110px;

      .quote {
        top: -110px;
        width: 505px;
        padding: 45px 60px;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .page-content.course-page-content {
    .course-section {
      padding-top: 85px;

      &__title {
        margin-bottom: 80px;
      }

      &__tags {
        margin-bottom: 85px;
      }

      .sticker {
        right: 20px;
        width: 154px;
        height: 154px;
      }

      .trailer-container {
        border-radius: 0;
        width: calc(100% + 40px);
        margin-left: -20px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;

        &__subtitle {
          margin-bottom: 30px;
        }

        &--wrapper__video img {
          height: auto;
        }
      }

      .course-program {
        &-container {
          .course {
            width: 100% !important;
            margin-right: 0 !important;
          }
        }

        .show-all-btn {
          width: 100% !important;
        }
      }
    }

    .plus-and-minus-section {
      padding: 50px 0 20px;

      &__title {
        text-align: left;
        margin-bottom: 30px;
      }

      .plus-container-list, .minus-container-list {
        padding-left: 0;
      }

      .plus-container, .minus-container {
        padding-bottom: 40px;
      }
    }

    .course-description-section {
      padding-top: 55px;

      &__title {
        margin-bottom: 30px;
      }

      .course-description-wrapper {
        .description {
          flex-direction: column;
          margin-bottom: 30px;

          &:nth-child(2n) {
            .description__img {
              order: 0;
            }

            .description__text {
              margin-right: 0;
            }
          }

          &__img {
            margin-bottom: 40px;
            margin-right: 0;
            align-self: center;
          }
        }
      }
    }

    .result-section {
      padding-top: 60px;

      &__title {
        text-align: center;
      }

      &__subtitle {
        margin-bottom: 45px;
        text-align: center;
      }

      .results-wrapper {
        .result {
          margin-bottom: 45px;

          &-wrapper {
            margin-top: 0;
          }
        }
      }
    }

    .percents {
      &__title {
        text-align: center;
      }

      .percents-wrapper {
        &__percent {
          align-items: center;
          width: 100% !important;
        }
      }
    }

    .trust-reasons {
      padding-top: 55px;
      padding-bottom: 250px;

      &__title {
        margin-bottom: 60px;
      }

      .reasons-container {
        max-width: 100%;

        .reason {
          width: 100% !important;
          max-width: 100%;
          margin-right: 0;
        }
      }
    }

    .quote-wrapper {
      padding-top: 190px;

      .quote {
        width: 300px;
        padding: 40px 27px;
        top: -160px;
      }
    }
  }
}

</style>
