<template>
  <div class="page-content checkout-page">
    <ContentWrapper>
      <v-row class="checkout-header">
        <v-col :cols="$vuetify.breakpoint.width < 768 ? 12 : 5" class="checkout-body">
          <div v-if="orderLoading" class="skeleton-header mb-10">
            <v-skeleton-loader type="text" height="40" width="270" class="mb-10"/>
            <v-skeleton-loader type="text" height="22" width="200" class="mb-5"/>
            <v-skeleton-loader type="text" height="30" width="180"/>
          </div>
          <div v-else class='mb-10'>
            <HText :variant="$vuetify.breakpoint.width <= 768 ? 'h2' : 'h1'" class="mb-10">Заказ №
              {{ $route.params.id ? $route.params.id : order.id }}
            </HText>
            <HText v-if="order.type" variant="h3" class="mb-5">{{ order.count }}x
              {{ itemTypes.find(item => item.type === order.type).title }}: {{ order.title }}
            </HText>
            <HText v-else variant="h3" class="mb-5">{{ order.title }}</HText>
            <div class="total" v-if="order.price">
              {{ order.status === '1' ? 'Оплачено' : 'К оплате' }}: <span>{{ $root.printCost(order.price) }}</span>
            </div>
          </div>
          <div class="payment-container" v-if="order.status === '0'">
            <HText variant="h3" class="mb-5">Способ оплаты</HText>
            <v-radio-group v-model="paymentType"
                           :error-messages="paymentError"
            >
              <v-radio v-for="type in order.paymentTypes" :key="type.type"
                       :value="type.type"
                       class="custom-radio"
              >
                <template v-slot:label>
                  <div>
                    <div class="radio-label">{{ type.title }}</div>
                    <span class="tax-value">{{type.description}}</span>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <!--        <v-text-field
                      autocomplete="off"
                      class="input form-input mb-4"
                      label="Промокод"
                      v-model="checkout.promo"
                      outlined
                      hide-details
                  />-->
          <div class="pay-actions">
            <div class="not-authorized mb-4" v-if="order.status*1===0 && !$store.state.user.id">
              <v-text-field
                  autocomplete="off"
                  class="input email-input"
                  label="E-mail"
                  outlined
                  v-model="email"
                  hide-details="auto"
                  validate-on-blur
                  :error-messages="errorMsg_email"
                  @input="errorMsg_email = ''"
              />
            </div>
            <div class="authorized">
              <CustomButton v-if="order.status === '0'" @click.native="payByLink" text="Оплатить" variant="black"
                            :loading="loading_pay"/>
              <div v-else class="is-payed-actions">
                <div class="consultation-is-payed mb-4">
                  <v-icon color="green" size="18" class="mb-1">mdi-check</v-icon>
                  Оплачено
                </div>
                <CustomButton @click.native="$router.replace('/')" text="Вернуться на главную" variant="black"/>
              </div>
            </div>
          </div>

        </v-col>
        <v-spacer v-if="$vuetify.breakpoint.width > 768"/>
        <v-col cols="4" class="preview-container">
          <v-skeleton-loader v-if="orderLoading" width="300" height="200" type="image"/>
          <div class="order-img mb-1" v-else-if="order.preview && order.preview !== ''">
            <img :src="order.preview" alt="">
          </div>
        </v-col>
      </v-row>

    </ContentWrapper>
  </div>
</template>

<script>
import LoginFields from "@/components/reusable/LoginFields";
export default {
  name: "Checkout",
  components: {
    LoginFields
  },

  data() {
    return {
      order: {
        status: 0
      },
      itemTypes: [
        {
          type: 'consultation',
          title: 'Консультация по теме',
        },
        {
          type: 'video',
          title: 'Видео-лекция',
        },
        {
          type: 'course',
          title: 'Курс'
        }
      ],
      paymentType: 0,
      paymentError: '',
      email: '',
      errorMsg_email: '',
      loading_pay: false,
      type: '',
      orderLoading: false,
    }
  },
  methods: {
    getOrder(id) {
      this.orderLoading = true;
      this.$server.request(`order/get/${id}`, {}, (data) => {
        this.order = data.response;
        this.orderLoading = false;
      }, () => {
        this.orderLoading = false;
      });
    },
    createOrder() {
      this.orderLoading = true;
      const item = this.$route.query;
      this.type = item.type;
      this.$server.request('order/create', {type: item.type, item_id: item.item_id, title: item.title}, (data) => {
        this.order = data.response;
        this.$router.replace({path: `/checkout/${data.response.id}`});
        this.orderLoading = false;
      }, () => {
        this.orderLoading = false;
      });
    },
    pay() {
      if (!this.email.length && !this.$store.state.user.id) {
        this.errorMsg_email = 'Укажите email';
        return;
      }
      this.loading_pay = true;
      this.$server.request(`order/iframe/${this.order.id}`, {paymentType: this.paymentType, email: this.email}, (data) => {
        this.loading_pay = false;
        this.$eventBus.$emit('open_buy_modal', {paytureLink: data.response, type: this.type, order_id: this.order.id});
      }, () => {
        this.loading_pay = false;
      })
    },
    payByLink() {
      if (!this.email.length && !this.$store.state.user.id) {
        this.errorMsg_email = 'Укажите email';
        return;
      }
      this.loading_pay = true;
      this.$server.request(`order/getLink/${this.order.id}`, {paymentType: this.paymentType, email: this.email}, (data) => {
        this.loading_pay = false;
        window.location.href = data.response;
      }, () => {
        this.loading_pay = false;
      })
    },
    onLoggedIn() {

    }
  },
  mounted() {
    if (this.$route.params.id) this.getOrder(this.$route.params.id);
    else this.createOrder();
  },
}
</script>

<style lang="scss">
.checkout-page {
  .checkout-header {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .order-img {
    max-width: 300px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tax-value {
    font-size: 12px;
    color: rgba(0, 0, 0, .5);
  }

  .total {
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .not-authorized {
    .auth-form-wrapper {
      flex-direction: column;
      & > * {
        width: 100% !important;
        margin-right: 0 !important;
      }
      .input {
        margin-bottom: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .checkout-page {
    .checkout-body {
      order: 2;
    }

    .preview-container {
      order: 1;
    }
  }
}
</style>
