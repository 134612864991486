<template>
  <ContentWrapper>
    <div v-if="coursesLoaded" class="account-courses-wrapper">
      <ArticleListItem
          :to="`/courses/${item.id}`"
          v-for="(item, index) in courses"
          :title="item.title"
          :media="item.preview"
          :text="item.description"
          :price="item.price"
          :price_full="item.price_full"
          :has-access="item.hasAccess*1"
          :tags="item.tags"
          :key="index"
          class="courses-block__item swiper-slide"
      />
    </div>
    <div v-else class="account-courses-wrapper">
      <v-skeleton-loader style="box-shadow: none !important; border-radius: 20px" type="card" elevation="2" class="coursecard--wrapper" v-for="i in 2" :key="i"/>
    </div>
    <div
      v-if="coursesLoaded && !courses.length"
      class="account-courses-wrapper empty">
      <CText text="Пока нет ни одного купленного курса" class="mb-2"></CText>
      <CustomButton
          text="Купить курсы"
          variant="black"
          class="buy-btn"
          @click.native="$router.push('/courses')"
      />
    </div>
  </ContentWrapper>
</template>

<script>

import ArticleListItem from "@/components/reusable/ArticleListItem";

export default {

  name: "MyCourses",
  components: {
    ArticleListItem,
  },
  data() {
    return {
      courses: [],
      coursesLoaded: false
    }
  },
  methods: {
    getCourses() {
      this.$server.request('user/boughtItems?type=course', {}, (data) => {
        this.courses = data.response
        this.coursesLoaded = true
      });
    },
  },
  mounted() {
    this.getCourses()
  }
}
</script>

<style lang="scss">
.account-courses-wrapper {
  display: grid;
  background: transparent;
  column-count: 2;
  padding: 0 !important;
  gap: 20px;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, 1fr);
  &.empty {
    display: flex;
    flex-direction: column;
    gap: 0;
    .v-btn {
      max-width: 150px;
    }
  }
  .coursecard--wrapper {
    width: auto;
    min-width: 0;
  }
  .coursecard--wrapper:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  .account-courses-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
