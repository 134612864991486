<template>
  <v-btn
      depressed
      class="custom-btn"
      v-bind:class="[variantClass, disabledClass]"
      v-bind:disabled='disabled'
      v-bind:style='styles'
      :loading="loading"
      :to="to"
      v-html="text"
  ></v-btn>
</template>

<script>
export default {
  props: ['text', 'disabled', 'variant', 'styles', 'loading', 'to'],
  computed: {
    variantClass: function () {
      switch (this.variant) {
        case 'bordered': {
          return "btn--bordered"
        }
        case 'black': {
          return 'btn--black'
        }
        case 'filled': {
          return 'btn--filled'
        }
        case 'white': {
          return 'btn--white'
        }
        default:
          return 'btn--bordered'
      }
    },
    disabledClass: function () {
      if (this.$props.disabled === '') {
        return 'disabled'
      }
      return ''
    }
  }
}
</script>

<style scoped>
.custom-btn {
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  height: 100%;
  text-transform:none;
}

.btn--black {
  background: #222222 !important;
  color: white;
  border: 1px solid rgba(34, 34, 34, 0);
}
.btn--white {
  background: white !important;
  color: #222222;
  border: none;
}

.btn--black:hover, .btn--filled:hover, .btn--bordered {
  border: 1px solid rgba(34, 34, 34, 0.2);
  background: none !important;
  transition: 0.2s;
  box-sizing: border-box;
  color: #222222;
}

.btn--filled {
  background-color: #E0D4C5 !important;
  border: 1px solid rgba(34, 34, 34, 0);
}

.btn--bordered:hover {
  background: #222222 !important;
  color: white;
  transition: 0.2s;
}

.btn--filled.disabled, .btn--black.disabled {
  background: #CAC4BE !important;
  border: 1px solid rgba(34, 34, 34, 0) !important;
  color: white !important;
}

.btn--bordered.disabled {
  border: 1px solid rgba(34, 34, 34, 0.2) !important;
  background: none !important;
  color: rgba(0, 0, 0, 0.35) !important;
}
@media screen and (max-width: 600px) {
  .custom-btn {
    width: 100%;
  }
}
</style>
