<template>
  <div class="courses-block">
    <div v-if="courses.length" class="courses-block__items" :class="{'courses-block__items--mobile-shown-true': mobileShow}">
      <ArticleListItem
          :to="`courses/${item.id}`"
          v-for="(item, index) in courses"
          :title="item.title"
          :media="item.preview"
          :text="item.description"
          :price="item.price"
          :price_full="item.price_full"
          :tags="item.tags"
          :has-access="item.hasAccess*1"
          :key="index"
          class="courses-block__item swiper-slide"
      />
    </div>
    <div v-else class="courses-block__items" :class="{'courses-block__items--mobile-shown-true': mobileShow}">
      <v-skeleton-loader type="card" elevation="2" class="courses-block__item article-list-item" v-for="i in 4" :key="i"/>
    </div>
  </div>
</template>

<script>
import ArticleListItem from "@/components/reusable/ArticleListItem";
export default {
name: "CoursesSection",
  components: {
    ArticleListItem
  },
  props: {
    courses: Array,
    mobileShow: Boolean,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.courses-block {
  overflow: visible;
  padding-bottom: 40px;
  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(2,minmax(495px,1fr));
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }
  & &__item {
    min-height: 325px;
    &::v-deep .article-list-item__media {
      height: 162px;
    }
  }
  @media (max-width: 768px) {
    &__items {
      grid-template-columns: repeat(2,minmax(320px,1fr));
    }
  }
  @media (max-width: 440px) {
    &__items {
      grid-template-columns: repeat(1,minmax(280px,1fr));
    }
  }
}
</style>
