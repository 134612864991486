<template>
  <div class="page-content">
    <ContentWrapper background="var(--bg-color-blue)">
      <div class="page-header">
        <div class="page-title">
          <HText variant="h1 page-title__main">Блог</HText>
        </div>
        <div class="page-header-row">
          <div class="page-header-row__left">

          </div>
          <div class="page-header-row__right">
            <div class="searchbar">
              <img src="/img/search.svg" class="searchbar__icon" alt="search icon">
              <input type="text" placeholder="Поиск" class="searchbar__input">
            </div>
          </div>
        </div>
        <TagList :tags="tags" class="page-header--tag-list" @change="onChangeTabs"/>
      </div>
    </ContentWrapper>
    <ContentWrapper>
      <div class="blog-content-wrap">
        <div class="article-list">
          <div class="article-list__header">
<!--            <div class="sort-toggle">-->
<!--              <div-->
<!--                  class="sort-toggle__item"-->
<!--                  :class="{'sort-toggle__item&#45;&#45;active': key === currentSort}"-->
<!--                  v-for="(sort, key) in sortMethods"-->
<!--                  :key="key"-->
<!--                  @click="currentSort = key"-->
<!--              >-->
<!--                {{ sort.text }}-->
<!--              </div>-->
<!--            </div>-->
            <div class="article-list__count">
              {{ articles.length }} {{ declOfNum(articles.length, ['статья', 'статьи', 'статей']) }}
            </div>
          </div>
          <div class="article-list__items">
            <ArticleListItem
                to="/blog/1"
                v-for="(item, index) in articles"
                :title="item.title"
                :media="item.media"
                :text="item.text"
                :after="item.after"
                :tags="item.tags"
                :key="index"
                :has-access="item.hasAccess*1"
                class="article-list__item"
            />
          </div>
          <div class="show-more-button">
            <span class="show-more-button__text">Показать еще</span>
            <div class="show-more-button__icon">+</div>
          </div>
        </div>
        <div class="subscribe-block">
          <div class="subscribe-block__header">
            <img class="subscribe-block__icon" src="/img/mail.png" alt="mail icon">
            <span class="subscribe-block__text">Не пропустите скидки и новые статьи</span>
          </div>
          <form class="subscribe-block__form" @submit.prevent>
            <Input
                placeholder="Email"
                class="subscribe-block__input"
            />
            <CustomButton
                :text="'Получать статьи'"
                variant="black"
                class="subscribe-block__button"
            />
          </form>
        </div>
      </div>
    </ContentWrapper>
  </div>
</template>

<script>
import HText from "@/components/reusable/HText";
import ContentWrapper from "@/components/reusable/ContentWrapper";
import TagList from "@/components/reusable/TagList";
import ArticleListItem from "@/components/reusable/ArticleListItem";
import CustomButton from "@/components/reusable/CustomButton";
import Input from "@/components/reusable/Input";

export default {
  name: "Blogs",
  components: {Input, CustomButton, ArticleListItem, TagList, ContentWrapper, HText},
  data() {
    return {
      tags: ['Бесплатные', '#Беременность', '#Беременность', '#Беременность', '#Беременность', '# 1-3 года', '# 1-4 года', '# 1-5 года', '# 1-6 года', '# 1-7 года', '# 1-8 года', '# 1-9 года', '# 1-10 года', '# 1-11 года', '# 1-12 года', '# 1-13 года', '# 1-14 года', '# 1-15 года', '# 1-16 года', '# 1-17 года', '# 1-18 года', '# 1-19 года'],
      articles: [
        {
          title: 'Подготовка к родам',
          media: require('@/assets/demo/picture-1.png'),
          tags: ['# 3-5 лет', '# 1-3 года'],
          text: 'Вы верите в чудеса? Сегодня хочу написать, каким чудесным образом нашему старшему сыну удалось появиться в нашей Жизни и «пробить» путь всем остальным',
          after: '17 ноября 2018',
        },
        {
          title: 'Воспитание ребенка и основы счастливой семьи',
          media: require('@/assets/demo/picture-2.png'),
          tags: ['# 3-5 лет', '# 1-3 года', '# Беременность'],
          text: 'Вы верите в чудеса? Сегодня хочу написать, каким чудесным образом нашему старшему сыну удалось появиться в нашей Жизни и «пробить» путь всем остальным',
          after: '17 ноября 2018',
        },
        {
          title: 'Семейное образование',
          media: require('@/assets/demo/picture-3.png'),
          tags: ['# 3-5 лет', '# 1-3 года', '# Беременность'],
          text: 'Вы верите в чудеса? Сегодня хочу написать, каким чудесным образом нашему старшему сыну удалось появиться в нашей Жизни и «пробить» путь всем остальным',
          after: '17 ноября 2018',
        },
        {
          title: 'Вопросы воспитания',
          media: require('@/assets/demo/picture-4.png'),
          tags: ['# 3-5 лет', '# 1-3 года', '# Беременность'],
          text: 'Вы верите в чудеса? Сегодня хочу написать, каким чудесным образом нашему старшему сыну удалось появиться в нашей Жизни и «пробить» путь всем остальным',
          after: '17 ноября 2018',
        },
        {
          title: 'Личные границы мамы',
          media: require('@/assets/demo/picture-5.png'),
          tags: ['# 3-5 лет', '# 1-3 года', '# Беременность'],
          text: 'Вы верите в чудеса? Сегодня хочу написать, каким чудесным образом нашему старшему сыну удалось появиться в нашей Жизни и «пробить» путь всем остальным',
          after: '17 ноября 2018',
        },
        {
          title: 'Уход за малышом',
          media: require('@/assets/demo/picture-5.png'),
          tags: ['# 3-5 лет', '# 1-3 года', '# Беременность'],
          text: 'Вы верите в чудеса? Сегодня хочу написать, каким чудесным образом нашему старшему сыну удалось появиться в нашей Жизни и «пробить» путь всем остальным',
          after: '17 ноября 2018',
        },
        {
          title: 'Название курса',
          media: require('@/assets/demo/picture-5.png'),
          tags: ['# 3-5 лет', '# 1-3 года', '# Беременность'],
          text: 'Вы верите в чудеса? Сегодня хочу написать, каким чудесным образом нашему старшему сыну удалось появиться в нашей Жизни и «пробить» путь всем остальным',
          after: '17 ноября 2018',
        },
      ],
      sortMethods: {
        popular: {text: 'Популярные'},
        new: {text: 'Новые'},
      },
      currentSort: 'popular',
    };
  },
  methods: {
    onChangeTabs(selectedTags) {
    },
  },
}
</script>

<style scoped lang="scss">
.page-header {
  padding-top: 95px;
  padding-bottom: 75px;
  .searchbar__input {
    width: 47px;
  }
}
.blog-content-wrap {
  padding-top: 35px;
  display: flex;
  align-items: flex-start;

  .subscribe-block {
    margin-left: 92px;
  }

  @media (max-width: 1080px) {
    flex-direction: column;
  }
  @media (min-width: 641px) and (max-width: 1080px) {
    .subscribe-block {
      max-width: 664px;
      width: 100%;
      margin: 90px 0 0 0;
      padding: 0 92px;

      &__header {
        align-items: center;
      }

      &__form {
        flex-direction: row;
        align-self: flex-start;
        width: 100%;
      }

      &__input {
        margin-bottom: 0;
        margin-right: 20px;
        flex: 1;
      }

      &__button {
        flex: 1;
      }
    }
  }
  @media (max-width: 640px) {
    .subscribe-block {
      width: 100%;
      margin: 80px 0 0 0;

      &__header {
        align-items: center;
      }

      &__input {
        width: 100%;
      }
    }
  }
}

.article-list {

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .sort-toggle {
      margin-bottom: 0;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;

    & > .article-list__item {
      margin-right: 8px;
      margin-bottom: 8px;


      &::v-deep {
        .article-list-item__inner {
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
        }

        .article-list-item__after {
          padding: 0;
          margin-bottom: 10px;
          border: none;
          color: rgba(0, 0, 0, .5);
        }

        .article-list-item__text {
          display: none;
        }
      }
    }
  }

  @media (min-width: 768px) {
    width: 664px;

    &__items {
      & > .article-list__item {

        &:not(:first-child):nth-child(2n + 1), &:nth-child(2) {
          margin-right: 0;
        }

        &:nth-child(3n) {
          margin-right: 0;
          width: 100%;
          min-height: 376px;

          &::v-deep {
            .article-list-item__text {
              margin-top: 35px;
              display: block;
            }

            .article-list-item__media {
              max-height: 190px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    &__item {
      width: 280px;
    }
  }
  @media (max-width: 640px) {
    width: 100%;
    &__items {
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
    }
  }


  .show-more-button {
    margin-top: 30px;
    display: inline-flex;
    margin-left: 50%;
    transform: translate(-50%, 0)
  }
}

.sort-toggle {
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;

  &__item {
    font-size: 14px;
    line-height: 140%;
    color: rgba(0, 0, 0, .5);
    border: 1px solid;
    border-right-style: none;
    padding: 3px 15px;
    cursor: pointer;
    white-space: nowrap;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
      border-right-style: solid;
    }

    &--active {
      color: rgba(0, 0, 0, 1);
      border-right-style: solid;

      & + .sort-toggle__item {
        border-left-style: none;
      }
    }
  }
}

.show-more-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__text {
    margin-right: 10px;
    font-size: 16px;
  }

  &__icon {
    width: 29px;
    height: 29px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

.subscribe-block {
  display: flex;
  flex-direction: column;
  width: 244px;

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  &__icon {
    width: 34px;
    margin-bottom: 25px;
  }

  &__text {
    max-width: 150px;
    font-size: 14px;
    line-height: 20px;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__input {
    margin-bottom: 20px;

    &::v-deep .input--wrapper {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
}

@media screen and (max-width: 768px) {
  .page-header {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  .blog-content-wrap {
    padding-top: 60px;
  }
}

@media screen and (max-width: 600px) {
  .page-header {
    padding-top: 42px;
    padding-bottom: 48px;
  }
  .blog-content-wrap {
    padding-top: 50px;
  }
}
</style>
