<template>
  <div class="page-content">
    <div class="content-description-wrapper">
      <ContentWrapper background="var(--bg-color-blue)">
        <div class="club-description">
          <Back text="Назад" to="/clubs"/>
          <HText variant="h1" class="club-description__title">Клуб "{{ club.title }}"</HText>
          <HText variant="h2" class="club-description__text">{{ club.text }}</HText>
          <CustomButton
              variant="black"
              text="Выбрать модуль*"
              @click.native="goToModules"/>
          <CText :text="'* Присоединиться можно к любому модулю'"></CText>
        </div>
      </ContentWrapper>
      <ContentWrapper>
        <div class="chat-description">
          <HText variant="h2" class="chat-description__title">Кто состоит в чате?</HText>
          <v-img class="overflow-visible" content-class="content-circle" src="/img/circle.svg" width="327"
                 height="327">
            <div style="top: -20px; right: -50px" class="v-image_wrapper">
              <img src="../../assets/demo/child-psychologist.png" alt="psycho" width="90">
              <span class="name">Марина Сергеева, детский психолог</span>
            </div>
            <div style="bottom: -20px; right: -50px" class="v-image_wrapper">
              <img src="../../assets/demo/child-psychologist.png" alt="psycho" width="90">
              <span class="name">Марина Сергеева, детский психолог</span>
            </div>
            <div style="bottom: 40px; left: -50px" class="v-image_wrapper">
              <img src="../../assets/demo/child-psychologist.png" alt="psycho" width="90">
              <span class="name">Марина Сергеева, детский психолог</span>
            </div>
            <div class="plus-parent"><span>+ {{ club.parentsCount }}</span> родителя</div>
          </v-img>
        </div>
      </ContentWrapper>
      <div class="club-description__label">
        <HText :variant="$vuetify.breakpoint.width <= 768 ? 'h3' : 'h2'" style="color: #ffffff">Этот клуб – путеводитель к лучшей жизни.</HText>
        <HText :variant="$vuetify.breakpoint.width <= 768 ? 'h3' : 'h2'">Отсутствие понятной карты движения, нехватка времени на свои маленькие желания, раздражение
          на себя и домашних, финансовые трудности, рутина – это все мне было знакомо, и я смогла это решить
        </HText>
      </div>
    </div>
    <div class="modules-section"
         id="clubModules"
    >
      <div class="modules-section-header">
        <HText variant="h1" class="modules-section-header__title">Годовая программа</HText>
        <CText class="modules-section-header__text" :text="'Если Вы хотите быть уверенной, мудрой и успешной Женщиной, Женой и'+
'          Мамой, вот'+
'          как я могу Вам помочь:'">
        </CText>
      </div>
      <v-tabs background-color="#E0D4C5"
              class="modules-tabs"
              v-model="clubModule">
        <v-tab active-class="module-tab--active" class="module-tab">Модуль 1</v-tab>
        <v-tab active-class="module-tab--active" class="module-tab">Модуль 2</v-tab>
        <v-tab active-class="module-tab--active" class="module-tab">Модуль 3</v-tab>
        <v-tab active-class="module-tab--active" class="module-tab">Модуль 4</v-tab>
      </v-tabs>
      <v-tabs-items v-model="clubModule">
        <v-tab-item v-for="(module, index) in club.modules" :key="index">
          <ContentWrapper :background="index % 2 === 0 ? 'var(--main-bg-color)' : 'var(--bg-color-blue)'">
            <div class="module-wrapper">
              <div class="module-header">
                <v-img alt="" :src="`/img/${module.sticker}.svg`"
                       class="module-header__sticker"
                       width="220">
                  <CText :text="`Модуль ${ index + 1 }`"></CText>
                  <span>{{ module.name }}</span>
                </v-img>
                <div class="module-header__image">
                  <img :src="`/img/${module.image}.png`" alt="">
                </div>
                <div class="module-header-description">
                  <CText class="module-header-description__text" :text="module.text"></CText>
                  <v-list class="module-header-description__result">
                    <v-list-item-group>
                      <v-list-item
                          class="result"
                          v-for="(result, index) in module.results"
                          :key="index"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ result }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </div>
              <div class="module-subjects">
                <div class="subject" v-for="(subject, index) in module.subjects" :key="index">
                  <div class="subject__title">Тема "{{ subject.title }}"</div>
                  <CText class="subject__text" :text="subject.text"></CText>
                </div>
              </div>
            </div>
          </ContentWrapper>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <ContentWrapper>
      <div class="live-section">
        <HText variant="h1" class="live-section__title">Темы весенних эфиров в Клубе</HText>
        <div class="live-subjects-wrapper">
          <div class="live-subject" v-for="(live, index) in club.lives" :key="index"
               :style="index === 1 ? 'margin-top: 40px' : ''">
            <HText variant="h3" class="live-subject__title">{{ live.title }}</HText>
            <CText class="live-subject__text" :text="live.text"></CText>
          </div>
        </div>
        <CustomButton
            variant="black"
            text="Посмотреть эфиры"/>
      </div>
    </ContentWrapper>
    <ContentWrapper background="var(--bg-color-blue)">
      <div class="reviews-section">
        <HText variant="h1" class="reviews-section__title">Отзывы</HText>
        <CText class="reviews-section__subtitle" :text="`${ 18 } отзывов`"></CText>
        <ReviewsSection/>
        <CustomButton variant="black" text="Показать больше"/>
      </div>
    </ContentWrapper>
    <ContentWrapper>
      <div class="subscription-section">
        <HText variant="h1" class="subscription-section__title">Чтобы вступить, выберите подписку</HText>
        <CText class="subscription-section__subtitle" :text="'После оплаты вы сразу станете членом клуба'"></CText>
        <div class="subscription-wrapper">
          <div class="subscription"
               v-for="(subscription, index) in club.subscriptions"
               :key="index">
            <HText variant="h1" class="subscription__title">{{ subscription.duration }} мес.</HText>
            <p class="subscription__gift-count" v-if="subscription.giftCount > 0">+ подарок
              <v-icon v-for="n in subscription.giftCount" :key="n">mdi-gift-outline</v-icon>
            </p>
            <CText class="subscription__gift" :text="subscription.gift"></CText>
            <div class="subscription__price"
                 :class="subscription.giftCount === 0 ? 'mb-0' : ''">{{ $root.printCost(subscription.price )}}/месяц
            </div>
            <CustomButton class="subscription__btn" text="Выбрать и оплатить"/>
          </div>
        </div>
        <div class="guarantee-wrapper">
          <img src="../../../public/img/shield-check.svg" alt="">
          <CText class="guarantee__text" :text="'100% гарантия возврата в течении 20 дней\n'+
'            по любой причине'"></CText>
        </div>
      </div>
    </ContentWrapper>
  </div>
</template>

<script>
import Back from "@/components/reusable/Back";
import ContentWrapper from "@/components/reusable/ContentWrapper";
import HText from "@/components/reusable/HText";
import CustomButton from "@/components/reusable/CustomButton";
import ReviewsSection from "@/components/reusable/ReviewsSection";

export default {
  name: "Club",
  components: {
    Back,
    ContentWrapper,
    HText,
    CustomButton,
    ReviewsSection
  },
  data() {
    return {
      club: {
        title: 'Искусство жить',
        text: 'Вы хотите стать уверенной мамой, вдохновленной женой и наслаждаться своей Жизнью? Со мной вы сможете генерировать счастье и Жить в достатке.',
        parentsCount: '23',
        modules: [
          {
            subjects: [
              {
                title: 'Тайм-менеджмент',
                text: 'Вы научитесь выстраивать день так, чтобы всегда оставалось время на себя: выпить чай горячим, понежиться в ванной, сходить в салон красоты, на шопинг и другие женские радости! Плюс на качественное общение с мужем и детьми.'
              },
              {
                title: 'Полезные привычки',
                text: 'Вы узнаете и внедрите в свою жизнь привычки успешных и счастливых людей. Сможете быть энергичной, нравиться себе, спокойно реагировать на стрессовые ситуации, вовремя заботиться о своем теле и разуме.'
              },
              {
                title: 'Здоровье',
                text: 'Узнаете, как слышать свое тело, не доводить себя до состояния опустошенности, как помочь себе и близким во время болезни. Как ее предотвратить. Будучи медиком, я щедро делюсь рекомендациями.'
              }
            ],
            sticker: 'woman',
            name: 'Гармоничная женщина',
            image: 'harmonic_woman',
            text: 'Вы сможете успевать то, что для вас ВАЖНО, поставите себя в приоритет. Ваши ресурсы восполнимы, и я знаю как это сделать, Вы можете:',
            results: ['иметь красивое здоровое тело',
              'быть в тонусе, а не валиться от усталости',
              'успевать жить ярко, проживая СВОЮ жизнь',
              'вдохновлять близких своим примером.']
          },
          {
            subjects: [
              {
                title: 'Тайм-менеджмент',
                text: 'Вы научитесь выстраивать день так, чтобы всегда оставалось время на себя: выпить чай горячим, понежиться в ванной, сходить в салон красоты, на шопинг и другие женские радости! Плюс на качественное общение с мужем и детьми.'
              },
              {
                title: 'Полезные привычки',
                text: 'Вы узнаете и внедрите в свою жизнь привычки успешных и счастливых людей. Сможете быть энергичной, нравиться себе, спокойно реагировать на стрессовые ситуации, вовремя заботиться о своем теле и разуме.'
              },
              {
                title: 'Здоровье',
                text: 'Узнаете, как слышать свое тело, не доводить себя до состояния опустошенности, как помочь себе и близким во время болезни. Как ее предотвратить. Будучи медиком, я щедро делюсь рекомендациями.'
              }
            ],
            sticker: 'woman',
            name: 'Гармоничная женщина',
            image: 'harmonic_woman',
            text: 'Вы сможете успевать то, что для вас ВАЖНО, поставите себя в приоритет. Ваши ресурсы восполнимы, и я знаю как это сделать, Вы можете:',
            results: ['иметь красивое здоровое тело',
              'быть в тонусе, а не валиться от усталости',
              'успевать жить ярко, проживая СВОЮ жизнь',
              'вдохновлять близких своим примером.']
          },
        ],
        lives: [
          {
            title: 'Здоровье',
            text: 'Как подготовить иммунитет к осенним холодам и вирусам. Как уберечь ребенка, если папа заболел, если в саду/школе все болеют?'
          },
          {
            title: 'Отношения',
            text: 'Муж мало играет с детьми. "Пилить" или хитрить? Алгоритм действий мудрой женщины. Как вдохновить мужа на свершения дома и на работе.'
          },
          {title: 'Дети', text: 'Как помочь ребенку влиться в обучение в новом учебном году и самой не сойти с ума.'},
          {
            title: 'О женском',
            text: 'Где найти бездонные источники энергии и научиться самостоятельно генерировать хорошее настроение, даже когда на улице сыро и серо.'
          },
        ],
        subscriptions: [
          {duration: '1', giftCount: 0, gift: '', price: '220'},
          {duration: '3', giftCount: 1, gift: '1 любой курс на выбор', price: '200'},
          {duration: '6', giftCount: 2, gift: '2 любых курса на выбор', price: '150'},
          {duration: '12', giftCount: 3, gift: '3 любых курса на выбор', price: '120'},
        ]
      },
      clubModule: null
    }
  },
  methods: {
    goToModules() {
      this.$vuetify.goTo('#clubModules', this.scrollingOptions)
    },

  },
  created() {
    this.$eventBus.$on('goToClubModules', ()=>{
      this.goToModules()
    })
  },
  computed: {
    scrollingOptions() {
      return {
        duration: 1000,
        easing: 'easeInOutCubic',
        offset: 110,
      }
    }
  }

}
</script>

<style scoped lang="scss">
.page-content {
  .content-description-wrapper {
    display: flex;
    justify-content: center;
    position: relative;

    .club-description, .chat-description {
      height: 720px;
    }

    .club-description {
      position: relative;
      padding: 144px 46px 0 220px;

      &__title {
        margin-bottom: 40px;
      }

      &__text {
        margin-bottom: 95px;
        color: #6F8DA8;
      }

      .custom-btn {
        margin-bottom: 30px;
      }
    }

    .chat-description {
      padding: 125px 0 0 100px;

      &__title {
        margin-bottom: 35px;
        text-align: center;
      }

      .content-circle {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .plus-parent {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100px;
          width: 100px;
          background-color: #ffffff;
          border-radius: 50%;

          span {
            font-weight: bold;
            font-size: 22px;
            line-height: 130%;
          }
        }
      }

      .v-image_wrapper {
        position: absolute;
        z-index: 1;
        padding: 10px;
        display: flex;
        flex-direction: column;
        background-color: #f4ede5;

        img {
          border-radius: 50%;
          margin-bottom: 5px;
        }

        span.name {
          max-width: 140px;
        }

      }
    }

    .club-description__label {
      position: absolute;
      background: #E0D4C5;
      border-radius: 40px;
      width: 1000px;
      padding: 50px;
      top: 650px;
    }
  }

  .reviews-section {
    padding: 100px 190px;
    justify-content: center;

    &__title {
      margin-bottom: 7px;
      text-align: center;
    }

    &__subtitle {
      margin-bottom: 40px;
      text-align: center;
    }
  }

  .modules-section {
    &-header {
      background: #ffffff;
      padding: 230px 220px 70px;

      &__title {
        margin-bottom: 30px;
      }

      &__text {
        max-width: 405px;
      }
    }

    .modules-tabs {
      background: #E0D4C5;
      padding: 22px 220px 0;

      .module-tab {
        margin-right: 40px;
        text-transform: capitalize;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 22px;

        &:last-child {
          margin-right: 0;
        }

        &--active {
          color: #333;
        }

        &:before {
          display: none;
        }
      }

      .v-tabs-slider {
        background-color: #333333;
      }

    }

    .module-wrapper {
      padding: 100px 0 105px;

      .module-header {
        display: flex;
        margin-bottom: 60px;

        &__sticker {
          position: absolute;
          z-index: 1;
          top: 105px;
          left: 0;

          .v-responsive__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }

          span {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            max-width: 150px;
          }

          p {
            text-align: center;
          }
        }

        &__image {
          margin-left: 172px;
          margin-right: 50px;

          img {
            border-radius: 20px;
            border: 10px solid #fff;
          }
        }

        &-description {
          &__text {
            margin-top: 35px;
            margin-bottom: 30px;
          }

          &__result {
            background-color: transparent;

            .result {
              margin-bottom: 20px;
              padding: 0;
              min-height: 0;

              .v-list-item__content {
                padding: 0;
              }

              .v-list-item__icon {
                margin-top: 0;
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .module-subjects {
        .subject {
          display: flex;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          &__title {
            padding-top: 20px;
            margin-right: 50px;
            border-top: 1px solid #08050C1A;
            min-width: 202px;
            width: 202px;
          }

          &__text {
            padding-top: 20px;
            border-top: 1px solid #08050C1A;
            max-width: 580px;
          }
        }
      }
    }
  }

  .live-section {
    padding: 115px 20px 105px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: 75px;
    }

    .live-subjects-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-bottom: 50px;

      .live-subject {
        padding: 40px 50px 45px;
        background: #ffffff;
        border-radius: 60px;
        width: 474px;

        &:nth-child(2n+1) {
          margin-right: 12px;
        }

        &:first-child, &:nth-child(2) {
          margin-bottom: 12px;
        }

        &:nth-child(3) {
          margin-top: -41px;
        }

        &__title {
          margin-bottom: 15px;
        }
      }
    }
  }

  .subscription-section {
    background: #F4EDE5;
    padding: 135px 0 105px;

    &__title {
      margin-bottom: 20px;
      text-align: center;
    }

    &__subtitle {
      margin-bottom: 50px !important;
      text-align: center;
      display: block;
    }

    .subscription-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 60px;

      .subscription {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 10px;
        margin-right: 8px;
        padding: 30px 30px 0;
        height: 325px;
        width: 244px;

        &__title {
          margin-bottom: 30px;
        }

        &__gift-count {
          color: #B98365;
          margin-bottom: 10px !important;

          .v-icon {
            font-size: inherit;
            color: inherit;
            margin-right: 4px;
          }
        }

        &__gift {
          margin-bottom: 65px !important;
        }

        &__price {
          margin-bottom: 25px;
        }

        &__btn {
          border: none;
          box-shadow: none;
          background: #D7CABB !important;
          border-radius: 0px 0px 10px 10px;
          margin-left: -30px;
          margin-right: -30px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .guarantee-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .shield-icon {
        margin-bottom: 20px;
        font-size: 52px;
        color: #ffffff;
      }

      .guarantee__text {
        max-width: 270px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .page-content {
    .back-link {
      display: none;
    }
    .content-description-wrapper {
      .club-description {
        padding-left: 0;
        padding-top: 50px;
        &__title {
          font-size: 26px;
          line-height: 29px;
        }
        &__text {
          font-size: 16px;
          line-height: 19px;
        }
      }
      .chat-description {
        padding-top: 50px;
        padding-left: 30px;
        &__title {
          font-size: 16px;
          line-height: 19px;
        }
        .v-image {
          width: 270px !important;
          height: 270px !important;
          .v-image_wrapper {
            padding: 0;
            img {
              width: 74px !important;
                height: 74px !important;
              }
            .name {
              font-size: 14px;
              line-height: 140%;
            }
          }
          .plus-parent {
            width: 82px;
            min-width: 82px;
            height: 82px;
            span {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
      }
      .club-description__label {
        padding: 42px 30px;
        width: calc(100% - 60px);
      }
    }
    .modules-section {
      &-header {
        padding: 200px 30px 70px;
      }
      .modules-tabs {
        padding-left: 30px;
        padding-right: 30px;
        .v-slide-group__prev {
          display: none !important;
        }
      }
      .module-wrapper {
        padding-top: 85px;
        padding-bottom: 75px;
        .module-header {
          &__sticker {
            top: 70px;
            left: 30px;
            width: 140px !important;
          }
          &__image {
            width: 300px !important;
            margin-left: 90px;
            margin-right: 35px;
            img {
              width: inherit;
            }
          }
        }
      }
    }
    .live-section {
      padding: 80px 0;
      &__title {
        margin-bottom: 60px;
      }
      .live-subjects-wrapper {
        .live-subject {
          width: 330px;
        }
      }
    }
    .reviews-section {
      &__title {
        text-align: center !important;
      }
    }
    .subscription-section {
      padding: 80px 0 100px;
      .subscription-wrapper {
        justify-content: center;
        .subscription {
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
