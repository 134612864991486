<template>
  <div class="page-content">
    <div class="account-wrapper">
      <ContentWrapper>
        <v-tabs
            v-model="tab"
            background-color="transparent"
            hide-slider
            active-class="tab__item--active"
            height="27"
            class="account-tabs-container"
        >
          <v-tab
              v-for="(tab, i) in tabs"
              :key="i"
              :to="'/account/'+tab.value"
              class="tab__item"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-btn class="btn-exit"
               @click="logout"
        >
          Выйти
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </ContentWrapper>
      <ContentWrapper>
        <HText variant="h1" class="account__title">{{ title(tab) }}</HText>
      </ContentWrapper>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item class="account-tab-content continue-tab" value="/account/continue">
          <Continue/>
        </v-tab-item>
        <v-tab-item class="account-tab-content course-tab" value="/account/courses">
          <MyCourses/>
        </v-tab-item>
        <v-tab-item class="account-tab-content video-tab" value="/account/videos">
          <MyVideos/>
        </v-tab-item>
        <!--          <v-tab-item class="account-tab-content club-tab">-->
        <!--            <MyClubs/>-->
        <!--          </v-tab-item>-->
        <!--          <v-tab-item class="account-tab-content finance-tab">-->
        <!--            <Finance/>-->
        <!--          </v-tab-item>-->
        <v-tab-item class="account-tab-content consultations-tab" value="/account/consultations">
          <MyConsultations/>
        </v-tab-item>
        <v-tab-item class="account-tab-content settings-tab" value="/account/settings">
          <Settings/>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Continue from "@/views/account/Continue";
import MyCourses from "@/views/account/MyCourses";
import MyVideos from "@/views/account/MyVideos";
// import MyClubs from "@/views/account/MyClubs";
import Settings from "@/views/account/Settings";
import MyConsultations from "@/views/account/MyConsultations";
// import Finance from "@/views/account/Finance";

export default {
  name: "AccountContainer",
  components: {
    MyConsultations,
    Continue,
    MyCourses,
    MyVideos,
    // MyClubs,
    Settings,
    // Finance
  },
  data() {
    return {
      tab: '/account/continue',
      tabs: [
        {title: 'Продолжить', wrapper: ``, value: 'continue'},
        {title: 'Мои курсы', wrapper: '', value: 'courses'},
        {title: 'Моя библиотека видео', wrapper: '', value: 'videos'},
        // {title: 'Мои клубы', wrapper: '',},
        // {title: 'Финансы', wrapper: '',},
        {title: 'Мои консультации', wrapper: '', value: 'consultations'},
        {title: 'Настройки', wrapper: '', value: 'settings'},
      ],
    }
  },
  methods: {
    title(tab) {
      let value = tab.split('/')[2]
      return this.tabs.find(tab => tab.value === value).title;
    },
    logout() {
      this.$server.logout();
      this.$store.commit('set', ['user', {}])
    }
  },
  mounted() {
    document.title = 'Mama7d - Мой профиль'
  },
}
</script>

<style lang="scss">
.page-content {
  .account-wrapper {
    position: relative;
    padding: 70px 0 120px;

    .btn-exit {
      position: absolute;
      top: 70px;
      right: 0;
      height: 27px;
      background: transparent;
      box-shadow: none;
      padding: 0;
    }

    .account-tabs-container {
      margin-bottom: 80px;
    }

    .account__title {
      margin-bottom: 50px;
    }

    .v-tabs-items {
      background: transparent;
    }

    .v-slide-group__prev {
      display: none !important;
    }

    .tab__item {
      font-size: 14px;
      line-height: 140%;
      color: rgba(0, 0, 0, .5);
      border: 1px solid;
      border-right-style: none;
      padding: 3px 15px;
      cursor: pointer;
      white-space: nowrap;
      text-transform: capitalize;
      font-weight: 400;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
        border-right-style: solid;
      }

      &:before {
        opacity: 0;
      }

      &--active {
        color: rgba(0, 0, 0, 1);
        border-right-style: solid;
        font-weight: 500;

        & + .sort-toggle__item {
          border-left-style: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .page-content {
    .account-wrapper {
      .v-tabs-items {
        padding-left: 20px;
        padding-right: 20px;
      }
      .account-tab-content {
        .content-wrapper {
          padding: 0;
        }
      }

      .btn-exit {
        display: none;
      }

      .account__title {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .v-slide-group__wrapper {
    margin-right: -30px;
    padding-right: 30px;
    margin-left: -30px;
    overflow-x: scroll;
  }
}
</style>
