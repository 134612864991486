<template>
  <div class="page-content clubs-wrapper">
    <FifthScreen/>
    <div class="profi-in-chat">
      <div class="profi-in-chat-wrapper">
        <div class="chat">
          <div class="content--wrapper">
            <div class="username">Родитель А</div>
            <div class="message">
              <div class="cloud">
                <CText :text="'Подскажите, что делать если возникла проблема, которая возникает у всех, но никто не знает как ее\n'+
'                  решать?'"></CText>
              </div>
            </div>
          </div>
          <div class="content--wrapper chat-answer">
            <div class="username">Детский психолог</div>
            <div class="message">
              <div class="cloud">
                <CText :text="'Это правда частая проблема, хорошо что вы обратились, все делают ошибки. Решается она вот так'"></CText>
              </div>
            </div>
          </div>
          <div class="content--wrapper">
            <div class="username">Родитель Б</div>
            <div class="message">
              <div class="cloud">
                <CText :text="'Кстати, тоже сталкивались с похожим, в нашем случае мы тоже обращались к психологу и правда\n'+
'                  помогло'"></CText>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-desc">
          <div class="h1 chat-desc__title">Профессионалы <span>тоже в чате</span></div>
          <CText class="chat-desc__text" :text="'Детские психологи и акушеры – всегда на связи и готовы ответить на ваши\n'+
'            вопросы'"></CText>
          <CustomButton
              v-bind:class="getDisplayedStyles"
              variant="black"
              text="Посмотреть клубы"
              class="choose-module-btn"
          />
        </div>
      </div>
    </div>

    <ContentWrapper background="white">
      <div class="club-types">
        <HText variant="h1" class="club-types__title">Какие есть клубы</HText>
        <HText variant="h3" class="club-types__text">3 клуба</HText>
        <div class="clubs">
          <router-link to="/clubs/1" class="club" v-for="club in clubs" :key="club.icon" :class="'club__'+club.icon">
            <img :src="`/img/${club.icon}.png`" alt="" width="82" height="82">
            <HText variant="h2" class="club__title">{{ club.title }}</HText>
            <CText class="club__members" :text="`${club.members } участников`"></CText>
            <CText class="club__desc" :text="club.text"></CText>
            <CText class="club__price" :text="`От ${ $root.printCost(club.price) }/мес`"></CText>
          </router-link>
        </div>
      </div>
    </ContentWrapper>
    <ContentWrapper background="var(--main-bg-color)">
      <div class="benefits">
        <HText variant="h1" class="benefits__title">Преимущества онлайн-системы роста</HText>
        <div class="benefits-wrapper">
          <div class="benefit" v-for="(benefit, index) in benefits" :key="index">
            <div class="benefit__number">{{ parseInt(index) + 1 }}</div>
            <div class="benefit-wrapper">
              <HText variant="h3" class="benefit__title">{{ benefit.title }}</HText>
              <p class="benefit__text">
                {{ benefit.text }}
              </p>
            </div>
          </div>
        </div>
        <CustomButton
            variant="black"
            text="Посмотреть клубы"
            v-bind:class="getDisplayedStyles"
            class="see-clubs"
        />
      </div>
    </ContentWrapper>
    <ContentWrapper background="var(--bg-color-blue)">
      <div class="trust-reasons">
        <HText variant="h1" class="trust-reasons__title">Почему вы можете мне доверять?</HText>
        <div class="reasons-container">
          <div class="reason" v-for="(reason, index) in trust_reasons" :key="index">
            <div class="h3 reason__title">{{ reason.title }} <span>{{ reason.title_span }}</span></div>
            <CText class="reason__text" :text="reason.text"></CText>
          </div>
        </div>
        <CustomButton
            variant="black"
            text="Посмотреть клубы"
            v-bind:class="getDisplayedStyles"
            class="see-clubs"
        />
      </div>
    </ContentWrapper>
    <ContentWrapper>
      <div class="faq">
        <HText variant="h1" class="faq__title">Часто задаваемые вопросы</HText>
        <div class="faq-container">
          <v-expansion-panels>
            <v-expansion-panel v-for="(qAndA, index) in qAndAs" :key="index">
              <v-expansion-panel-header expand-icon="mdi-plus">{{ qAndA.question }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex
                ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt
                mollit anim id est laborum.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <p class="mail-link">Остались вопросы? Пишите <a href="mailto:info@mama7d.ru">info@mama7d.ru</a></p>
        <div class="faq-links">
          <a href="https://vk.com/id4560485" target="_blank">VK</a>
<!--          <a href="https://instagram.com">Instagram</a>-->
          <a href="https://t.me/+hBebIzpEpOtjMWUy" target="_blank">Telegram</a>
        </div>
        <CustomButton
            variant="black"
            text="Посмотреть клубы"
            v-bind:class="getDisplayedStyles"
            class="see-clubs"
        />
      </div>
    </ContentWrapper>
    <ContentWrapper background="var(--bg-color-blue)">
      <div class="reviews-section">
        <HText variant="h1" class="reviews-section__title">Отзывы</HText>
        <CText class="reviews-section__subtitle" :text="`${ 18 } отзывов`"></CText>
        <ReviewsSection/>
        <CustomButton variant="black" text="Показать больше" class="show-more-btn"/>
      </div>
    </ContentWrapper>
    <ContentWrapper background="white">
      <div class="club-types club-types-snd">
        <HText variant="h1" class="club-types__title">Какие есть клубы</HText>
        <HText variant="h3" class="club-types__text">3 клуба</HText>
        <div class="clubs">
          <div class="club" v-for="club in clubs" :key="club.icon" :class="'club__'+club.icon">
            <img :src="`/img/${club.icon}.png`" alt="" width="82" height="82">
            <HText variant="h2" class="club__title">{{ club.title }}</HText>
            <p class="club__members">{{ club.members }} участников</p>
            <p class="club__desc">{{ club.text }}</p>
            <p class="club__price">От {{ $root.printCost(club.price) }}/мес</p>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </div>
</template>

<script>
import FifthScreen from "@/components/mainpage/ClubSection";
import ReviewsSection from "@/components/reusable/ReviewsSection";

export default {
  name: "Clubs",
  components: {
    ReviewsSection,
    FifthScreen
  },
  data() {
    return {
      descriptions: [
        'Задайте вопрос и получите ответ от профессиональных психологов и педагогов',
        'Узнайте, как другие родители справляются с похожей проблемой',
        'Поделитесь опытом с другими родителями – возможно, вы сможете помочь'
      ],
      clubs: [
        {
          title: 'Счастливое родительство',
          text: 'Текст описания курса. Важно следить, чтобы он соответствовал контенту. Текст описания курса.',
          price: '120',
          members: '30',
          icon: 'star-blue',
        },
        {
          title: 'Название клуба',
          text: 'Текст описания курса. Важно следить, чтобы он соответствовал контенту. Текст описания курса.',
          price: '120',
          members: '30',
          icon: 'heart-braun'
        },
        {
          title: 'Название клуба',
          text: 'Текст описания курса. Важно следить, чтобы он соответствовал контенту. Текст описания курса.',
          price: '120',
          members: '30',
          icon: 'house-yellow'
        }
      ],
      benefits: [
        {
          title: 'Приглашенные эксперты',
          text: 'Звездный визажист, фитнес тренер-чемпион мира, стилист, диетолог и другие гостевые спикеры'
        },
        {
          title: 'Чат поддержки 24/7',
          text: 'Вы всегда можете задать свой вопрос и тутже получить ответ. Чат работает без выходных.'
        },
        {
          title: 'Занимает 15 минут в день',
          text: 'Гибкое расписание. Вы можете пропустить пару дней, уехать в отпуск и потом легко догнать'
        },
        {
          title: 'Участники со всего мира',
          text: 'Участники из 20+ городов: США, Греции, Англии, ЮАР, Японии, Германии, Индонезии, и тд.'
        },
        {
          title: 'С любого устройства',
          text: 'Компьютер, телефон, ноутбук, планшет. Все материалы будут доступны с любого гаджета.'
        },
        {
          title: 'База знаний',
          text: 'У нас есть накопленная база знаний + свежие материалы на учебной платформе, 162 бонусных урока.'
        },
        {
          title: 'Стоимость',
          text: 'Месяц клубной поддержки единомышленниц + наставника по цене ниже 1 похода в салон красоты.'
        },
      ],
      trust_reasons: [
        {
          title: `30 лет`,
          title_span: 'супружества',
          text: 'Романтика и влюбленность даже спустя 30 лет? Легко! Я знаю секреты счастья.'
        },
        {
          title: `500+`,
          title_span: 'принятых родов',
          text: 'Веду беременность, помогаю в родах, 5 000 часов патронажа детей + семейных пар.'
        },
        {
          title: `120 лет`,
          title_span: 'материнства',
          text: 'У меня 7 детей и 3 внука. Вижу подрастающие плоды нашего воспитания.'
        },
        {
          title: `3`,
          title_span: 'образования',
          text: 'Включая медицинское + постоянные курсы повышения квалификации, саморазвитие.'
        },
      ],
      qAndAs: [
        {
          question: 'У меня ни на что не хватает времени и энергии. "Потяну" ли я клубную программу?',
          answer: '',
        },
        {
          question: 'Сколько времени уходит на работу и общение в чатах?',
          answer: '',
        },
        {
          question: 'Какой результат вы можете мне гарантировать?',
          answer: '',
        },
        {
          question: 'Я боюсь потерять деньги',
          answer: '',
        },
        {
          question: 'Эта сумма слишком большая',
          answer: '',
        },
        {
          question: 'Знаю, что у вас ограниченный набор в клуб. Это правда?',
          answer: '',
        },
        {
          question: 'Я живу заграницей, как мне оплатить клуб?',
          answer: '',
        },
      ]
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.clubs-wrapper .fifth-section-container, .clubs-wrapper .content-container:first-child {
  background: var(--main-bg-color) !important;
}

.clubs-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0 !important;
}

.clubs-wrapper .fifth-section-container {
  padding-bottom: 230px;

  .club-preview {
    order: 2;
    margin-right: 0;
    margin-left: 145px;
    border-radius: 45px;
    background: var(--bg-color-blue);
  }

  .description {
    order: 1;
    flex-basis: 50%;
  }
}

.profi-in-chat {
  max-width: 1000px;
  margin: 0 auto;
  position: absolute;
  top: 635px;
  z-index: 1;
  width: 100%;

  &-wrapper {
    display: flex;
    padding: 50px 60px;
    background: #D8E1E9;
    border-radius: 40px;

    .chat {
      flex-basis: 50%;
      padding-right: 35px;

      .chat-answer .username {
        text-align: right;
      }

      .chat-answer .message {
        transform: scaleX(-1);

        .cloud {
          background-color: #EAF4FC;

          .text {
            transform: scaleX(-1);
          }
        }
      }

      .content--wrapper {
        margin-bottom: 25px;
      }

      .username {
        margin-bottom: 10px;
      }

      .message {
        padding-left: 30px;
        position: relative;
      }

      .chat-answer .message::after {
        border-top: 20px solid #EAF4FC;
      }

      .cloud {
        background: #FFFFFF;
        mix-blend-mode: normal;
        border-radius: 11.6256px;
        padding: 20px;
        width: 313px;
      }

      .message::after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: 10px;
        border: 20px solid transparent;
        border-top: 20px solid white;
      }
    }

    .chat-desc {
      flex-basis: 50%;
      padding-top: 85px;
      padding-left: 46px;

      &__title {
        margin-bottom: 20px;
        font-size: 38px;
        line-height: 40px;
        font-weight: 600;

        span {
          color: #6F8DA8;
        }
      }

      &__text {
        margin-bottom: 50px !important;
      }
    }
  }
}

.club-types {
  padding: 387px 0 124px;

  &__title {
    margin-bottom: 15px;
  }

  &__text {
    margin-bottom: 50px;
  }

  .clubs {
    display: flex;
    flex-wrap: wrap;
    .club {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 496px;
      flex-basis: 49%;
      min-width: 280px;
      padding: 35px 40px;
      margin-bottom: 8px;
      border-radius: 30px;
      position: relative;

      img {
        position: absolute;
      }

      &__title {
        margin-bottom: 10px;
      }

      &__members {
        margin-bottom: 30px;
        opacity: 0.5;
      }

      &__desc {
        margin-bottom: 60px;
      }

      &__price {
        margin-bottom: 0;
      }
    }

    .club__star-blue {
      background-color: #EAF4FC;

      img {
        top: -30px;
        right: 55px;
      }
    }

    .club__heart-braun {
      background-color: #F4EDE5;

      img {
        right: -30px;
        bottom: 30px;
      }
    }

    .club__house-yellow {
      background-color: #FAF3DE;

      img {
        right: -30px;
        bottom: 30px;
      }
    }

    .club:nth-child(2n+1) {
      margin-right: 8px;
    }
  }
}

.benefits {
  padding: 110px 0 30px;
  position: relative;

  &__title {
    margin-bottom: 93px;
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .benefit {
      display: flex;
      width: 323px;
      margin-right: 10px;
      margin-bottom: 75px;

      &__number {
        min-width: 54px;
        width: 54px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 23px;
        border-radius: 50%;
        background-color: #ffffff;
      }

      &-wrapper {
        margin-top: 10px;
      }

      &__title {
        margin-bottom: 16px;
      }

      &__text {
        font-size: 14px;
        line-height: 20px;
        opacity: 0.8;
      }
    }
  }

  .see-clubs {
    position: absolute;
    top: 110px;
    right: 0;
    font-size: 14px;
    line-height: 20px;
  }
}

.trust-reasons {
  padding: 100px 0;

  &__title {
    margin-bottom: 50px;
  }

  .reasons-container {
    display: flex;
    margin-bottom: 60px;

    .reason {
      min-width: 244px;
      width: 244px;
      min-height: 199px;
      height: 199px;
      padding: 25px 30px 0;
      background-color: #ffffff;
      border-radius: 20px;
      margin-right: 8px;

      &__title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 20px;

        span {
          color: #6F8DA8;
        }
      }

      &__text {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .see-clubs {
    display: flex;
    margin: 0 auto;
  }
}
.clubs-wrapper .trust-reasons {
  .reasons-container {
    margin-bottom: 60px !important;
  }
}

.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 101px 190px 98px;

  &__title {
    text-align: center;
    margin-bottom: 59px;
  }

  &-container {
    margin-bottom: 40px;

    .v-expansion-panel, .v-expansion-panel--active {
      margin-bottom: 20px;
      padding: 21px 20px 25px 30px;
      border-radius: 60px !important;
      border-top-left-radius: 0 !important;
      font-size: 14px;
      line-height: 20px;
    }

    .v-expansion-panel::before {
      box-shadow: none;
    }

    .v-expansion-panel::after {
      display: none
    }

    .v-expansion-panel-header--active {
      font-weight: 600;
    }

    .v-expansion-panel-header__icon {
      background: #F4EDE5;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      min-width: 30px;
      display: flex;
      justify-content: center;

      i {
        color: #000000;
      }
    }
  }

  .mail-link {
    color: rgba(0, 0, 0, 0.5);
  }

  .faq-links {
    margin-bottom: 62px;

    a {
      margin-right: 20px;
    }
  }
}

.clubs-wrapper .reviews-section {
  max-width: 580px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  &__title, &__subtitle {
    text-align: center !important;
  }
  .reviews-wrapper {
    margin-right: 0 !important;
  }
}

.club-types-snd {
  padding-top: 100px;
}

@media screen and (max-width: 1024px) {
  .profi-in-chat {
    max-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .clubs-wrapper .fifth-section-container {
    padding-bottom: 360px;

    .club-preview {
      order: 2;
      margin-right: 0;
      margin-left: 0;
      border-radius: 45px;
      background: var(--bg-color-blue);
    }

    .description {
      order: 1;
      flex-basis: 50%;
    }
  }
  .profi-in-chat {
    width: calc(100% - 60px);
    &-wrapper {
      padding: 55px 30px;
      flex-wrap: wrap;
    }
    .chat {
      width: 50%;
      min-width: 50%;
      flex-basis: 0;
      .cloud {
        width: 100%;
      }
    }
    .chat-desc {
      padding-left: 0;
      &__title {
        font-size: 26px;
        line-height: 29px;
      }
    }
  }
  .club-types {
    padding-top: 350px;
    padding-bottom: 85px;
    &__text {
      font-weight: normal !important;
    }
    .clubs {
      .club {
        img {
          width: 60px !important;
          height: 60px !important;
        }
      }
    }
  }
  .benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    .see-clubs {
      position: initial;
    }
  }
  .trust-reasons {
    padding-top: 65px;
    padding-bottom: 55px !important;
    .reasons-container {
      max-width: 100% !important;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .reason {
      max-width: 350px !important;
      margin-bottom: 8px;
    }
  }
  .faq {
    padding: 105px 0 60px;
    .faq-container {
      margin-bottom: 40px;
    }
  }
  .reviews-section {
    padding: 60px 0 130px !important;
  }
  .club-types-snd {
    padding-top: 80px;
  }
  .club-main {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 600px) {
  .clubs-wrapper {
    .profi-in-chat {
      top: 1120px;
      width: calc(100% + 40px);
      padding: 50px 20px;
      &-wrapper {
        flex-direction: column;
        align-items: center;
        .chat {
          order: 2;
          width: 100%;
          padding-right: 0;
        }
        .chat-desc {
          order: 1;
          margin-bottom: 40px;
        }
        .choose-module-btn {
          width: 100% !important;
        }
      }
    }
    .club-types {
      padding-top: 830px;
      .club {
        width: 100%;
        margin-right: 0 !important;
        flex-basis: 100%;
      }
    }
    .benefits {
      padding: 70px 0 70px;
      &__title {
        margin-bottom: 40px;
      }
      .benefit {
        margin-bottom: 20px;
        width: 100%;
        margin-right: 0;
      }
      .see-clubs {
        display: none;
      }
    }
    .trust-reasons {
      padding: 60px 0 80px;
      .see-clubs {
        display: none;
      }
      .faq {
        padding: 80px 0;
        &__title {
          margin-bottom: 40px;
        }
        .see-clubs {
          width: 100% !important;
        }
      }
    }
    .club-types-snd {
      padding-top: 60px;
    }
  }
}
</style>
