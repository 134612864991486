<template>
  <div class="page-content pb-0">
    <ContentWrapper background="white" class="pt-10">
      <h1 size="6" style="font-size: 28pt"><b>Роды
        в экстренной ситуации</b></h1>
    <p style="line-height: 108%; margin-bottom: 0.11in"><br/>
      <br/>

    </p>
    <p style="line-height: 108%; margin-bottom: 0.11in"><i><b>Первый
      период родов</b></i></p>
    <p style="line-height: 108%; margin-bottom: 0.11in">Это период,
      когда шейка матки должна достигнуть
      полного раскрытия, период до начала
      потуг:</p>
    <ol>
      <li><p style="line-height: 108%; margin-bottom: 0.11in">По
        возможности обеспечить тишину, тепло
        и темноту (задёрнуть шторы, например).
        Это нужно для того, чтобы вырабатывался
        гормон ОКСИТОЦИН – гормон, способствующий
        родовой деятельности. Если нам страшно,
        то вырабатывается гормон АДРЕНАЛИН.
        Адреналин блокирует выработку окситоцина
        и может тормозить родовую деятельность.
        НУЖНО УСПОКОИТЬСЯ!</p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in">Заварить
        травы – тысячелистник, крапиву, пастушью
        сумку.  В начале родов пить некрепкий
        отвар. После родов он должен быть более
        крепким и насыщенным. <i>Для чего?</i> Эти
        травы мягко способствую течению схваток
        в родовом периоде, а после родов будут
        предотвращать кровотечение. Можно
        заварить сразу по 3 горсточки каждой
        травы или по 3 фильтр-пакета на 1 литр-1,5.</p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in">Приготовить
        впитывающие одноразовые пелёнки или
        пелёнки обычные или простынь порвать
        на куски размером примерно 70х70. Во время
        родов, если они протекают без осложнений
        могут быть кровяные выделения, так как
        раскрывающаяся шейка матки имеет
        кровеносные сосуды. Нормальное количество
        кровяных выделений может быть сравнимо
        с 3-4 днём менструации. НЕ ДОЛЖНА КРОВЬ
        ТЕЧЬ ПО НОГАМ СТРУЙКОЙ!</p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in">Шейку
        матки при экстренных родах НЕ смотрим,
        дабы не занести инфекцию.</p></li>
    </ol>
    <p style="line-height: 108%; margin-bottom: 0.11in"><br/>
      <br/>

    </p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      В первом периоде родов ничего
      делать, кроме как спокойно «переживать»,
      продыхивать схватки не нужно. Когда
      появится ощущение, что хочется в туалет
      «по-большому» - тужит,  означает начало
      потужного периода родов.
    </p>
    <p style="line-height: 108%; margin-bottom: 0.11in"><br/>
      <br/>

    </p>
    <p style="line-height: 108%; margin-bottom: 0.11in"><i><b>2-й -
      Потужной период родов</b></i></p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Потужной период родов может
      длиться 3-4 часа для первородящих. НЕ
      НУЖНО ФОРСИРОВАТЬ его, торопиться.
      Тогда можно избежать осложнений в виде
      разрывов промежности, слишком быстрого
      движения малыша, в результате которого
      <font color="#000000">он может неправильно «встать»
        в родовых путях, «застрять» плечиками
        (ширина головы и плечиков у ребёнка
        одинаковая). Поэтому ему нужно время,
        чтобы сгруппироваться. </font>В этом периоде
      правильное поведение женщины способно
      облегчить течение процесса.</p>
    <ol>
      <li><p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
        Взять любую трубочку, кулак и на схватке
        выдувать медленно в неё/него воздух.
        Это делается для того, чтобы у ребёнка
        была возможность медленно продвигаться
        по родовым путям, подстраиваясь, под
        строение маминых костей для выхода.
        Выдувая на схватке воздух, мы с одной
        стороны не тужимся, не создаём излишнего
        давления, а с другой стороны, всё-таки
        мягко напрягаем пресс и мышцы брюшной
        полости.</p></li>
      <li><p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
        В этот период можно и нужно менять позы.
        Например, встать сначала одной ногой
        на возвышение, например, ступеньку во
        время потуги, а потом поменять ногу на
        другой схватке. Кому-то удобнее переживать
        потуги лёжа то на одном, то на другом
        боку. Меняя положение, мы можем
        ускорять/замедлять роды. ВАЖНО ПОМНИТЬ:
        даже если мы не тужимся – ребёнок всё
        равно продвигается по родовым путям,
        так как во время схватки в матке создаётся
        колоссальное давление. Вне схватки
        ОБЯЗАТЕЛЬНО отдыхать, не разговаривать,
        «растечься как мороженное»: надо давать
        себе возможность отдыха для максимальной
        дальнейшей работы.</p></li>
      <li><p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
        Если «ОЧЕНЬ сильно тужит» нужно дышать
        собачкой. В родах наступает такой
        момент, когда голова ребёнка уже стоит
        в промежности – «огненное кольцо» -
        самый неприятный момент в родах. Здесь
        обязательно нужно дышать собачкой.
        Возникает ощущение, что всё горит, но
        нужно дать возможность мягким тканям
        раздвинуться без разрывов, мягко. В
        этот момент возникает ощущение, что
        нужно «продавить» малыша, но этого
        делать не надо, так как его голова всё
        равно продвигается по миллиметру. А
        вот когда голова уже родилась, то:</p></li>
    </ol>
    <p style="line-height: 108%; margin-left: 1in; margin-bottom: 0.11in">
      <br/>
      <br/>

    </p>
    <ul>
      <li><p style="line-height: 108%; margin-bottom: 0.11in">если
        чувствуете сильную потугу, то можно
        потужить;</p></li>
    </ul>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">если
      потуги нет, тогда спокойно можно отдохнуть
      и родить малыша в следующую потугу, не
      торопиться. Она должная быть последней!
      Если в следующую потугу не родили, то
      принять позу борца сумо, в которой таз
      максимально раскрыт. Также можно уложить
      женщину на спину и подтянуть колени к
      ушам, раскрывая таз.  Поза на четвереньках
      не подходит, так как таз в ней менее
      раскрыт.</p>
    <ul>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"></p></li>
    </ul>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Когда головка уже «проклюнулась»,
      то маме можно положить пальцы ему на
      голову, и тогда Вы сами будете чувствовать,
      как движется по миллиметру головка, и
      будете действовать более щедяще для
      своих тканей и головки малыша. Поза в
      родах может быть любой комфортной для
      Вас. Чтобы самой у себя принять ребенка,
      проще находиться на четвереньках или
      на коленях, корточках.  Удобной позой
      для этого является поза
    </p>
    <p style="line-height: 108%; margin-bottom: 0.11in"><br/>
      <br/>

    </p>
    <p style="line-height: 108%; margin-bottom: 0.11in"><i><b>Рождение
      ребёнка</b></i></p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Ребёнка ловим в пелёночку,
      желательно одноразовую. Надо учитывать,
      что его тело может быть скользким –
      мокрым, в смазке. Если женщина рожает
      вертикально, нужно подстелить что-то
      мягкое на всякий случай. Как только
      родили ребёнка сразу кладём его на
      грудь, укрываем пелёнкой и одеяльцем,
      обязательно урываем голову, чтобы она
      была в тепле.  Малышу должно быть очень
      тепло!!! У мамы в утробе температура 38
      градусов и выше, так что, родившись,
      малыш быстро остывает.
    </p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      В этот момент НУЖНО СИЛЬНО И
      ИСКРЕННЕ РАДОВАТЬСЯ! Это важно для того,
      чтобы вырабатывались гормоны, возникающие
      в крови матери, и по пуповине, которая
      ещё пульсирует доставались ребёнку.  А
      для женщины важен окситоцин – он
      заставляет матку сокращаться и
      предотвращает кровопотери – самое
      большое осложнение после родов.</p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">1
      минуту ребёнок лежит в тепле на маме.
      Дальше осматриваем малыша – его тело
      должно порозоветь. Если розовеет – все
      хорошо. Если нет кладём ребёнка о
      стараемся услышать его сердце. Нормальный
      пульс – 2 удара в секунду. Слизь отсасывают,
      если есть проблемы с дыханием. Если всё
      нормально, слизь физиологически
      всасывается.  Ребёнок НЕ обязательно
      должен кричать пока пульсирует пуповина!</p>
    <p style="line-height: 108%; margin-bottom: 0.11in">В случае,
      если вы увидели проблемы с дыханием,
      делаем следующее:</p>
    <ul>
      <li><p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
        кладём ребёнка на подогретые пелёнки,
        можно использовать грелку, бутылки с
        тёплой водой. Это нужно, чтобы сосуды
        в тепле расширялись;</p></li>
      <li><p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
        отсасываем слизь ртом (мама и малыш
        одно целое). Мама берёт нос и рот малыша
        в рот и потихоньку вытягивает слизь;</p></li>
      <li><p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
        когда дыхательные пути освободили,
        мягко «вдувают» воздух, чуть-чуть, НЕ
        сильно, так как объём его лёгких
        маленьких. Можно ориентироваться на
        то, сколько помещается в маминых щеках;</p></li>
      <li><p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
        делаем ребёночку лёгкий массаж спинки,
        чтобы лёгкие раскрывались (они как
        матрасик надуваются.</p></li>
      <li><p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
        Накапать ребёночку несколько капель
        молозива в рот, так как в нём есть много
        полезных веществ</p></li>
      <li><p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
        Положить рядом с ребёнком кислород.
        Нельзя в маску и непосредственно в
        малыша. Распылять только смешивая с
        воздухом!</p></li>
    </ul>
    <p align="justify" style="line-height: 108%; margin-left: 0.5in; margin-bottom: 0.11in">
      <br/>
      <br/>

    </p>
    <p align="justify" style="line-height: 108%; margin-left: 0.5in; margin-bottom: 0.11in">
      <br/>
      <br/>

    </p>
    <p align="justify" style="line-height: 108%; margin-left: 0.5in; margin-bottom: 0.11in">
      <i><b>Рождение плаценты </b></i>
    </p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Дальше смотрим пуповину:
      положив на неё пальцы чувствуем пульсацию.
      Если пульсация прекратилась, пуповина
      белеет. Это означает, что плацента
      отделилась от матки и движется во
      влагалище. Плаценту желательно родить
      через 15-20 минут (до получаса). Сделать
      это нужно до сокращения шейки матки –
      пока она мягкая и «выпустила» безболезненно
      плаценту. Чтобы родить плаценту, можно
      сесть на корточки и родить её в какую-нибудь
      ёмкость. Тянуть не надо, чтобы не остались
      кусочки в матке. Плацента может родиться
      через потугу (она уже не будет такой
      болезненной), а, может быть, и так, что
      женщина и не ощутит рождения плаценты.
      Плаценту можно убрать в одноразовую
      пелёнку и в пакет, чтобы не подтекал и
      было чисто.</p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Как только роды закончатся,
      женщине нужно выпить литр приготовленного
      крепкого отвара трав. Можно добавить
      мёд(сахар), лимон. Это нужно:</p>
    <ul>
      <li><p style="line-height: 108%; margin-bottom: 0.11in">для
        остановки кровотечения</p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in">дальнейшего
        сокращения матки</p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in">придания
        сил, если с мёдом – глюкоза</p></li>
    </ul>
    <p style="line-height: 108%; margin-left: 0.5in; margin-bottom: 0.11in">
      <br/>
      <br/>

    </p>
    <p style="line-height: 108%; margin-left: 0.5in; margin-bottom: 0.11in">
      <i><b>Перерезание пуповины</b></i></p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Ограничений по моменту отрезания
      пуповины нет. У некоторых народов ждут,
      когда она высохнет и только потом её
      переламывают/пережигают, чтобы не попала
      инфекция.  Но это неудобно.</p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Чтобы отрезать пуповину нужно
      взять любую прочную нить, смоченную
      спиртом (можно взять зубную нить – она
      стерильна), и перевязать пуповину на
      расстоянии 1,5-2 см от пупка. Далее ножницами
      или ножом, обработанными спиртом отрезать
      остальную часть на расстоянии 0,5 см выше
      завязанной нити.  В момент отрезания
      пуповины ребёночку не больно, так как
      в ней нет нервных окончаний.
    </p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">Далее
      остаток пуповины отпадёт сам в течение
      до 14 дней (может и дольше). Когда одеваете
      ребёнку памперс пупок должен быть
      открытым(должен дышать,  ничто не должно
      натирать).
    </p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Приложить ребёнка к груди можно сразу
      после рождения, но он не обязан сразу
      сосать. Он должен отдохнуть, раздышаться
      и сосательный рефлекс включится сам,
      ребенок начнет чмокать, поворачивать
      голову, ища грудь.</p>
    <p style="line-height: 108%; margin-bottom: 0.11in"><br/>
      <br/>

    </p>
    <p style="line-height: 108%; margin-left: 0.5in; margin-bottom: 0.11in">
      <i><b>Обследование матки и влагалища</b></i></p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      После родов матка находится на
      уровне пупка, далее каждый день она
      опускается на 1 см. Нужно, чтобы матка
      сокращалась и очищалась. Лёд НЕ класть
      на область матки (так сейчас не делают),
      так как нам нужен окситоцин для её
      сокращения, а это тепло. Женщину надо
      согреть!!!</p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      В районе пупка глубоко запускаем пальцы
      обеих рук и пытаемся нащупать что-то
      жёсткое – сокращающуюся матку. Можно
      на неё надавить. В этот момент из влагалища
      может политься кровяная жидкость – это
      хорошо и правильно. Также, когда ребёнка
      прикладываем к груди, вырабатывается
      окситоцин, способствующий сокращению
      матки, выделений при кормлении больше,
      на начальном этапе можно чувствовать
      болезненные сокращения и обильные
      выделения.</p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Перекисью водорода нужно обильно
      промыть влагалище (сначала будет
      прохладно, потом тепло) промокнуть лучше
      чем-то стерильным, если нет, то впитывающими
      пелёнками. Далее посмотреть не течёт
      ли кровь. Приготовить прокладку. Увидеть
      разрывы, конечно, не специалисту сложно,
      тем более, зашить.</p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Для заживления промежности можно
      использовать крепкий настой коры дуба
      – подмываться при каждом походе в туалет
      или делать ванночку и посидеть в ней.
      Это антисептик, останавливает кровь,
      заживляет ранки.</p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Из гомеопатии можно использовать
      Арнику. Принимать 2 недели после родов.
    </p>
    <p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
      Обязательно, в течении 2 часов
      после родов пописать!!!  Так как это
      сделать первый раз может быть сложно –
      включить, например, воду. Это важно
      сделать для того, что полный мочевой
      пузырь не блокировал сокращение матки.
      В первое время она должна активно
      сокращаться. Каждые 3 часа ходить в
      туалет – освобождать мочевой пузырь.
      Не можете встать – писайте на пелёнку.
      Писать на расслаблении, не напрягаться.
    </p>
    <p style="line-height: 108%; margin-bottom: 0.11in">
      Чтобы первый раз сходить по-большому и
      опорожнить кишечник, можно использовать
      свечу с глицерином. Это очень может
      помочь, когда тужиться страшно.</p>
    <p style="line-height: 108%; margin-bottom: 0.11in"><a name="_GoBack"></a>
      В первый день после родов резко
      не вставать головой вверх(голова на
      уровне сердца), быстро не ходить, чтобы
      не упасть в обморок. Если ходите –
      ходите, как старушка. Со слегка опущенной
      головой.
    </p>
    <p style="line-height: 108%; margin-bottom: 0.11in">
      Можно пить насыщенный бульон – для
      восстановления нужен белок.</p>
    <p style="line-height: 108%; margin-bottom: 0.11in"><br/>
      <br/>

    </p>
    <p style="line-height: 108%; margin-bottom: 0.11in"><font size="4" style="font-size: 16pt"><b>Аптечка</b></font></p>
    <ol>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Перекись
        водорода</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Перчатки
        стерильные</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Шприцы
        (для инъекции)</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Окситоцин
        в ампуле. Не использовать в родах! Нужен
        только для послеродового периода:</font></p></li>
    </ol>
    <ul>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Много
        крови теряет женщина</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Не
        отделяется плацента и прочее</font></p></li>
    </ul>
    <p style="line-height: 108%; margin-bottom: 0.11in">
      <font size="3" style="font-size: 12pt">Вколоть одну ампулу
        в лобок (она маленькая), обработав
        стерильными салфетками. (лобок близок
        к матке, там есть жировая прослойка).</font></p>
    <ol start="5">
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Гель
        для обезболивания, если есть, необязательно.
        Можно смазать промежность в момент,
        когда головка стоит в промежности.</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Мирамистин
        (аналог перекиси)</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Одноразовые
        пелёнки </font>
      </p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Стерильные
        марлевые салфетки</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Руки
        обработать водкой, мирамистином, чтобы
        не занести женщине инфекцию.</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Детские
        салфетки влажные, чтобы были в доступе
        во время родов</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Женские
        прокладки послеродовые</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Кружка
        Эсмарха – дополнительный стимулятор
        родов. Нужен для клизмы. Не обязательно.</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Кислород
        (баллончик) для реанимации малыша.</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Солкосерил
        гель – заживляет разрывы, раны. Лучше
        пусть будет, если вообще некому посмотреть
        женщину.</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Масло
        персика, абрикосовой косточки можно
        использовать для смазывания промежности
        для облегчения прохождения головки
        малыша (если не хватает естественной
        смазки)</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Травы
        для заваривания (тысячелистник, крапива,
        пастушья сумка, кора дуба).</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"> <font size="3" style="font-size: 12pt">Переносная
        лампа (для фото специалисту)</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Полотенца,
        одежда малышу</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Свечи
        с глицерином для того, чтобы опорожнить
        кишечник безболезненно.</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Юньнань
        Байяо, капсулы «Yunnan Baiyao». Останавливает
        кровочечение.</font></p></li>
      <li><p style="line-height: 108%; margin-bottom: 0.11in"><font size="3" style="font-size: 12pt">Пульсатилла-30
        (стимулирует родовую деятельность),
        арника-30 (заживляет после родов),
        каулофилиум-30 (стимулирует родовую
        деятельность), фитоляка-30 (уменьшает
        выработку молока в первые дни, когда
        ребёнок сосёт мало).</font></p></li>
    </ol>
    <p style="line-height: 108%; margin-left: 1in; margin-bottom: 0.11in">
      <br/>
      <br/>

    </p>
    <p style="line-height: 108%; margin-left: 1in; margin-bottom: 0.11in">
      <br/>
      <br/>

    </p>
    <p style="line-height: 108%;">
      <br/>
      <br/>

    </p>
    </ContentWrapper>
  </div>
</template>

<script>
export default {
  name: "Article1",
  components: {},
  data() {
    return {
    }
  },
  methods: {
  },

}
</script>