<template>
  <div class="auth-consult">
    <div class="auth-modal-label-wrapper">
      <CText class="auth-modal__label mb-2"
             :text="step === 'email' ? 'Введите ваш e-mail' : 'Код отправлен на почту ' + user.email">
      </CText>
      <span class="change-email mb-2" v-if="step === 'code'"
            @click="step = 'email'">Изменить e-mail</span>
    </div>
    <v-form ref="auth_form" onsubmit="return false;">
      <div class="auth-form-wrapper">
        <v-text-field
            autofocus
            v-if="step === 'email'"
            autocomplete="off"
            class="input email-input mb-6"
            label="E-mail"
            outlined
            v-model="user.email"
            hide-details="auto"
            validate-on-blur
            :error-messages="errorMsg_email"
            @keydown.enter="getCode"
        />
        <v-text-field
            type="tel"
            inputmode="tel"
            v-if="step === 'code'"
            autocomplete="off"
            placeholder="_ _ _ _"
            class="input code-input mb-6"
            label="Проверочный код"
            outlined
            v-model="user.code"
            ref="code_input"
            :error="error_code"
            :error-messages="errorMsg_code"
            @keydown.enter="login"
        />
        <CustomButton class="btn-auth" variant="black" text="Получить код"
                      :loading="get_code_loading"
                      @click.native="getCode"
                      v-if="step === 'email'"
        />
        <CustomButton class="btn-auth" variant="black" text="Войти"
                      :loading="login_loading"
                      @click.native="login"
                      v-if="step === 'code'"
        />
      </div>
    </v-form>
    <CText
        style="margin-top: 20px"
        :text="`Нажимая 'Войти', вы соглашаетесь с условиями <a href='/privacy' target='_blank'>оферты</a>`"></CText>
  </div>
</template>

<script>
export default {
  name: "AuthBeforeConsult",
  data() {
    return {
      params: {},
      opened: false,
      user: {},
      step: 'email',
      get_code_loading: false,
      login_loading: false,
      error_code: false,
      errorMsg_code: [],
      errorMsg_email: [],
    }
  },
  emits:['userAuthorized'],
  methods: {
    getCode() {
      this.errorMsg_email = [];
      if (this.$refs.auth_form.validate()) {
        this.get_code_loading = true
        this.$server.request('auth/login', {email: this.user.email}, (data) => {
          this.user.secret_key = data.response
          this.step = 'code'
          this.get_code_loading = false
          setTimeout(() => {
            this.$refs.code_input.focus()
          })
        }, (data) => {
          this.errorMsg_email = [data.error]
          this.get_code_loading = false
        })
      }
    },
    login() {
      this.errorMsg_code = [];
      this.login_loading = true;

      this.$server.request('auth/checkCode', {secret_key: this.user.secret_key, code: this.user.code}, (data) => {
        this.login_loading = false;
        this.$server.setToken(data.token);
        this.$store.commit('set', ['user', data.response]);
        this.$emit('userAuthorized');
      }, (data) => {
        this.login_loading = false
        this.error_code = true;
        this.errorMsg_code = [data.error]
      })
    }
  },
  created() {
    this.$eventBus.$on('closeModal', () => {
      this.close()
    })
  },
  watch: {
    'user.code': function (newVal) {
      if (newVal) newVal.length === 4 ? this.login() : "";
    }
  }
}
</script>

<style scoped>

</style>