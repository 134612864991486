<template>
    <div v-bind:style='styles' v-bind:class='[getDisplayedStyles, getVariant]' v-html="text"></div>
</template>

<script>
export default {
    props: ['styles', 'variant', 'text'],
    computed: {
        getDisplayedStyles: function() {
            if (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs') {
                return 'mobile'
            }
            return ''
        },
        getVariant: function() {
            switch (this.$props.variant) {
                case 'text': return 'text'
                case 'caps': return 'caps'
                default: return 'text'
            }
        }
    }
}
</script>

<style scoped>
.text {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: #222222;
}

.caps {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 140%;
    color: #222222;
}
</style>
