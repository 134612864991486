<template>
  <div class="input--container" v-bind:style="styles">
    <div class="input--wrapper" v-bind:class="[messageType, checkFocus]" v-on:click='onFocus'>
      <span class="input--label">{{ label }}</span>
      <v-text-field
          v-if="inputType === 'textfield'"
          outlined
          v-on:focus='onFocus'
          v-on:blur='onBlur'
          class="input--input"
          v-bind:placeholder='placeholder'
          :type="type"

          autocomplete="off"
      />
      <v-textarea
          v-if="inputType === 'textarea'"
          outlined
          v-on:focus='onFocus'
          v-on:blur='onBlur'
          class="input--input input-textarea"
          v-bind:placeholder='placeholder'
          v-on:change="$emit('change', $event)"
          autocomplete="off"
          rows="2"
          hide-details="auto"
      />
    </div>
    <span class="input--message" v-bind:class="[messageType]">{{ this.message }}</span>
  </div>
</template>

<script>

export default {
  props: ['value', 'label', 'placeholder', 'error', 'message', 'success', 'styles', 'onChange', 'type', 'inputType'],
  data: function () {
    return {
      active: false,
      content: this.value,
    }
  },
  computed: {
    messageType: function () {
      if (this.$props.error === '') return 'input--error'
      if (this.$props.error === '') return 'input--success'
      return 'input--hidden'
    },
    checkFocus: function () {
      if (this.active) {
        return 'input--active'
      }
      return ''
    }
  },
  methods: {
    onFocus: function () {
      this.active = true
    },
    onBlur: function () {
      this.active = false
    },
  }
}
</script>

<style lang="scss">
.input--container {
  display: flex;
  flex-direction: column;
  width: 240px;
}

.input--wrapper {
  border-radius: 6px;
  padding: 8px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  border: 1px solid rgba(255, 255, 255, 0);
  cursor: pointer;
}

.input--wrapper.input--error {
  transition: 0.2s;
  border: 1px solid #F93A3A;
}

.input--wrapper.input--active {
  transition: 0.2s;
  border: 1px solid #000000 !important;
}

.input--wrapper:hover {
  transition: 0.2s;
  border: 1px solid #DDDDDD;
}

.input--label {
  color: #222222;
}

.input--label.disabled {
  color: #222222;
  opacity: 0.6;
}

.input--input {
  color: rgba(34, 34, 34, 0.4);
  cursor: pointer;
  height: 18px;
  .v-input__control {
    height: inherit;
  }
  .v-input__slot {
    min-height: 0 !important;
    margin-bottom: 0;
    fieldset {
      border: none;
    }
  }
}

.input-textarea {
  height: auto;
}

.input--input:focus {
  outline: none;
  color: #222222;
}

.input--message {
  font-size: 14px;
}

.input--message.input--error {
  color: #F93A3A;
}

</style>
