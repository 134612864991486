<template>
  <div class="page-content pa-4" v-if="$store.state.user && $store.state.user.clientsPageAllowed">
    <div class="club-types club-types-snd">
      <HText variant="h1" class="club-types__title">
        Клиенты
      </HText>
      <div style="height: 30px;font-size: 24px;margin-bottom: 20px" :style="{color:notification.color}">
        <template v-if="notification.message">
          <v-icon :color="notification.color">
            {{{green:'mdi-check', red:'mdi-close'}[notification.color]}}
          </v-icon>
          {{ notification.message }}
        </template>
      </div>
      <v-data-table
          hover
          :items="rows"
          :headers="headers"
          :loading="loading"
          :footer-props="{'items-per-page-options':[-1]}"
          no-data-text="Информации нет"
          loading-text="Загрузка..."
          mobile-breakpoint="0">
        <template v-slot:item.comment="{item}">
          <td>
            <v-textarea @change="save(item)" class="pa-2" style="width: 300px;height: 100%" hide-details rows="2" outlined
                        v-model="item.comment"/>
          </td>
        </template>
        <template v-slot:item.datetime_create="{item}">
          <td>{{ item.datetime }}</td>
        </template>
        <template v-slot:item.email="{item}">
          <td><a :href="'mailto:'+item.email">{{ item.email }}</a></td>
        </template>
        <template v-slot:item.phone="{item}">
          <td style="white-space: nowrap"><a :href="'tel:'+item.phone">{{ item.phone }}</a></td>
        </template>
        <template v-slot:item.watched="{item}">
          <td style="min-width: 800px">
            <span v-if="item.watched" v-for="(title, i) in item.watched" :key="i">{{i+1}}) {{title}}<br></span>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import FifthScreen from "@/components/mainpage/ClubSection";
import ReviewsSection from "@/components/reusable/ReviewsSection";

export default {
  name: "CRMClients",
  components: {},
  data() {
    return {
      rows: [],
      notification: {},
      loading: false,
      headers: [
        {text: '#', value: 'id'},
        {text: 'Имя', value: 'name'},
        {text: 'Email', value: 'email'},
        {text: 'Телефон', value: 'phone'},
        {text: 'Коммент', value: 'comment'},
        {text: 'Дата регистрации', value: 'datetime_create'},
        {text: 'Вопрос 1 из анкеты', value: 'request.type'},
        {text: 'Что больше всего беспокоит? (вопрос из анкеты)', value: 'request.problem'},
        {text: 'Поисковой запрос', value: 'request.utm_term'},
        {text: 'Покупки', value: 'items'},
        {text: 'Видео, просмотренные >50% ', value: 'watched'},
      ],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    notify(message, color = 'green') {
      this.notification = {message, color};
      setTimeout(() => this.notification = {}, 1000);
    },
    load() {
      this.loading = true
      this.$server.request('user/clients', {}, data => {
        this.rows = data.response;
        this.loading = false;
      }, () => this.loading = false);
    },
    save(user) {
      let params = {...user};
      delete params.request;
      this.$server.request('user/clientsUpdate', params, () => this.notify('Сохранено'), () => this.notify('Ошибка соединения', 'red'));
    }
  }
}
</script>