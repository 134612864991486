<template>
  <div class="back-link" @click="back">
      <v-icon size="24">mdi-arrow-left</v-icon>
      {{text}}
  </div>
</template>

<script>
export default {
name: "Back",
  props: ['text', 'to'],
  methods: {
    back() {
      if (this.$store.state.prevPage) this.$router.back();
      else this.$router.push(this.to);
    }
  }
}
</script>

<style scoped lang="scss">
  .back-link {
    background-color: transparent;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    top: 42px;
    cursor: pointer;
    a {
      text-decoration: none;
      border: none;
    }
    .v-icon {
      background-color: #ffffff;
      margin-right: 10px;
      border-radius: 50%;
      padding: 8px;
    }
  }
  @media (max-width: 768px) {
    .back-link {
      display: none;
    }
  }
</style>