import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    user: {},
    rub: '₽',
    prevPage: '',
    allReviews: [
        {
            src: 'https://www.youtube.com/embed/VrBM7DnayVo',
            quote: 'Клуб щедрости. Клуб знаний. Клуб спокойствия и уверенности, что здесь тебе помогут, поймут и не осудят'
        },
        {
            src: 'https://www.youtube.com/embed/eOWDzZQCavE',
            quote: 'Этот продукт стоит своих денег. Потому что сама ты не знаешь, где взять информацию, тебе хочется быстрее получить ответы'
        },
        {
            src: 'https://www.youtube.com/embed/zkZsdKKcBYQ',
            quote: 'Даша - мой надежный тыл. В клубе Вы обретаете уверенность, спокойствие и счастье'
        },
        {src: 'https://www.youtube.com/embed/khdEDnB9k-Q', quote: 'Я с Дашей, потому что ценю свое время'},
        {
            src: 'https://www.youtube.com/embed/pAZyNBK8avk',
            quote: 'Начинаю утро с проверки чата клуба. Даже если от нас нет запроса, у Даши бывают озарения. Озарения у неё, а мы потом переосмысливаем эту информацию'
        },
        {
            src: 'https://www.youtube.com/embed/1RnLUEE6iPw',
            quote: 'Только общаясь с Дашей в клубном чате, и получая ответы на свои бесконечные вопросы, мы экономим на книгах, психологах, педагогах и так далее'
        },
        {
            src: 'https://www.youtube.com/embed/lco-PSOy9Ek',
            quote: 'Я была замученной, растерянной, дети болели, с мужем начались ссоры, была дикая усталость... Я рада что попала к Даше, иначе, все могло закончиться плохо!'
        },
        {
            src: 'https://www.youtube.com/embed/sD1d_IiIESA',
            quote: 'Здорово видеть пример мудрой женщины! Нужно стремиться к окружению себя умными, мудрыми, интересными людьми, которые смогут дать нам положительный пример!'
        },
        {
            src: 'https://www.youtube.com/embed/ZISXc4ac9do',
            quote: 'Это клуб, где мамы и Даша рассказывают о своем опыте, поддерживают, помогают. Клуб - мое место силы и исцеления'
        },
        {
            src: 'https://www.youtube.com/embed/lcin0so7dCw',
            quote: 'После рождения ребёнка просели отношения с супругом. Я очень активна и хотела научиться быть для него "тихой гаванью'
        },
        {
            src: 'https://www.youtube.com/embed/yGWoRvkiV-4',
            quote: 'Взгляды Даши, её жизненная позиция, доброта и открытость располагают. Хочется всегда быть рядом'
        },
        {
            src: 'https://www.youtube.com/embed/H67nQ5AiN38',
            quote: 'ВОСходящее ПИТАНИЕ – курс о внутреннем настрое, который мы можем передать детям и быть уверенными, что по жизни они пойдут хорошей дорогой и будут счастливыми людьми'
        },
        {
            src: 'https://www.youtube.com/embed/4ouHymKaV3U',
            quote: 'Даша рассказывает все секреты, как делегировать детей мужу. Мы прорабатываем на курсе, как передать всю ответственность'
        },
        {
            src: 'https://www.youtube.com/embed/KuOVVCfwBNE',
            quote: 'Воспитывать нужно по возрастам. Прошла только половина марафона, а дети уже начали меня слышать!'
        },
        {
            src: 'https://www.youtube.com/embed/6gkNYj90btk',
            quote: 'Каждый урок марафона - открытие. Чтобы воспитать своего ребёнка - сначала нужно воспитать себя'
        },
        {
            src: 'https://www.youtube.com/embed/VKibzcPI6nw',
            quote: 'Вы нигде не найдёте тех знаний, которые даёт Даша. Вижу изменения в себе, в ребёнке, в семье стала приятная атмосфера'
        },
        {
            src: 'https://www.youtube.com/embed/RyoGWldlvOE',
            quote: 'За ежедневными заботами упускаются важные моменты воспитания. Благодаря Даше в моей голове сложилась картинка - на что обратить внимание в каждом возрасте'
        },
        {
            src: 'https://www.youtube.com/embed/TaXHorWEIvU',
            quote: 'Даша показала мне в моих детях то, чего я раньше не видела, на что не обращала внимания!'
        },
        {
            src: 'https://www.youtube.com/embed/qUTD3RlHoZw',
            quote: 'Воспитание – это ежечасный труд… тяжелый, но самый творческий и вдохновляющий'
        },
        {
            src: 'https://www.youtube.com/embed/jw_ppbvWZzM',
        }
    ],
}

const mutations = {
    set(state, [variable, value]) {
        state[variable] = value
    },

}

export default new Vuex.Store({
    state,
    mutations,
})
